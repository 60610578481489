import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import FormControl from '@material-ui/core/FormControl';

import CSVReader from 'react-csv-reader';

export default function VoucherWhitelistUpload(props) {

    const {classes, voucher, handleWhitelistUpload} = props;

    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Upload Whitelist
                </Typography>
            </Box>
            <Box m={3}>
                <FormControl className={classes.formControl} fullWidth>
                    <CSVReader label="Upload file" onFileLoaded={handleWhitelistUpload} />
                </FormControl>
            </Box>
            <Box m={3}>
                {voucher.whitelist_filename ? 
                    <Box>File uploaded: {voucher.whitelist_filename}</Box> : null
                }
                <Box>
                    Total records: {voucher.whitelist.values.length}
                </Box>
            </Box>
        </div>
    );
}