import React from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const randomTypeMap = [
    {
        id: 'ALPHANUMERIC',
        value: 'Alphanumeric'
    },
    {
        id: 'NUMERIC',
        value: 'Numeric'
    }
]

export default function VoucherCodeWithoutGenerate(props) {

    const {classes, voucher, setVoucher} = props;

    const handlePrefixChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                code_template: {
                    ...prevState.code_template,
                    prefix: value
                }
            }
        });
    }

    const handleRandomTypeChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                code_template: {
                    ...prevState.code_template,
                    random_type: value
                }
            }
        });
    }

    const handleSuffixChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                code_template: {
                    ...prevState.code_template,
                    suffix: value
                }
            }
        });
    }

    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Voucher Code
                </Typography>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <TextField
                            id="voucherCodePrefix"
                            label="Prefix"
                            value={voucher.code_template.prefix}
                            fullWidth
                            className={classes.textField}
                            onChange={handlePrefixChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="voucherCodeRandomType">Random Type</InputLabel>
                            <Select
                                id="voucherCodeRandomType"
                                value={voucher.code_template.random_type}
                                onChange={handleRandomTypeChange}
                            >
                                {randomTypeMap.map(randomType => (
                                    <MenuItem key={randomType.id} value={randomType.id}>{randomType.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id="voucherCodeSuffix"
                            label="Suffix"
                            value={voucher.code_template.suffix}
                            fullWidth
                            className={classes.textField}
                            onChange={handleSuffixChange}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}