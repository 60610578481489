import React from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const voucherTypeMap = [
    {
        id: 'SINGLEUSE',
        value: 'Single use'
    },
    {
        id: 'QUANTITY',
        value: 'Quantity limit'
    },
    {
        id: 'AMOUNT',
        value: 'Amount limit'
    },
    {
        id: 'REFERRAL',
        value: 'Referral'
    },
    {
        id: 'RANDOM',
        value: 'Random'
    }
]

export default function VoucherType(props) {

    const {classes, type, quantityLimit, amountLimit, updateType, updateQuantityLimit, updateAmountLimit, disabled} = props;

    const handleVoucherTypeChange = (e) => {
        const value = e.target.value;
        const prevValue = type;

        if(value !== prevValue) {
            updateType(value);
        }
    }

    const handleAmountLimitChange = (e) => {
        const value = e.target.value;
        updateAmountLimit(value);
    }
    
    const handleQuantityLimitChange = (e) => {
        const value = e.target.value;
        updateQuantityLimit(value);
    }

    const quantityLimitType = () => {
        return (
            <div>
                <Box>
                    <TextField
                        id="quantityLimit"
                        label="Quantity limit"
                        value={quantityLimit}
                        fullWidth
                        required
                        className={classes.textField}
                        onChange={handleQuantityLimitChange}
                        disabled={disabled}
                    />
                </Box>
            </div>
        );
    }

    const amountLimitType = () => {
        return (
            <div>
                <Box>
                    <TextField
                        id="amountLimit"
                        label="Amount limit"
                        value={amountLimit}
                        fullWidth
                        required
                        className={classes.textField}
                        onChange={handleAmountLimitChange}
                        disabled={disabled}
                    />
                </Box>
            </div>
        );
    }

    return (
        <div>
            <Box>
                <Typography variant="h6">
                    Voucher Type
                </Typography>
            </Box>
            <Box>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} fullWidth disabled={disabled}>
                                <InputLabel id="voucherType">Voucher Type</InputLabel>
                                <Select
                                    id="voucherType"
                                    value={type}
                                    onChange={handleVoucherTypeChange}
                                >
                                    {voucherTypeMap.map(voucherType => (
                                        <MenuItem key={voucherType.id} value={voucherType.id}>{voucherType.value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            {type === 'QUANTITY' ? quantityLimitType() : null}
                            {type === 'AMOUNT' ? amountLimitType() : null}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}