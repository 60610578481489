import {CAMPAIGN_GET_ACTION_TYPE as ACTION} from './actionTypes';

export const getCampaignRequest = (data) =>  {
    return {
        type: ACTION.CAMPAIGN_GET_REQUEST,
        data
    }
}

export const getCampaignSuccess = (response) =>  {
    return {
        type: ACTION.CAMPAIGN_GET_SUCCESS,
        response
    }
  }

export const getCampaignFailed = (error) =>  {
    return {
        type: ACTION.CAMPAIGN_GET_FAILED,
        error
    }
}