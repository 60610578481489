import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {getCampaignSuccess, getCampaignFailed} from '../actions/getCampaign';
import { CAMPAIGN_GET_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* getCampaign({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.CAMPAIGN + '/' + data.id, null);
    if(responseData.status === 200){
      yield put(getCampaignSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(getCampaignFailed(responseData));
    }
  } catch (error) {
    yield put(getCampaignFailed(error));
  }
}
  
function* watchGetCampaign(){
  yield takeLatest(ACTION.CAMPAIGN_GET_REQUEST, getCampaign);
}

export default function* root() {
  yield fork(watchGetCampaign);
}
  