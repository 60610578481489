import {GET_VOUCHER_BULK_ACTION_TYPE as ACTION} from './actionTypes';

export const getVoucherBulkRequest = (data) =>  {
    return {
        type: ACTION.GET_VOUCHER_BULK_REQUEST,
        data
    }
}

export const getVoucherBulkSuccess = (response) =>  {
    return {
        type: ACTION.GET_VOUCHER_BULK_SUCCESS,
        response
    }
  }

export const getVoucherBulkFailed = (error) =>  {
    return {
        type: ACTION.GET_VOUCHER_BULK_FAILED,
        error
    }
}