import React, {useState, useEffect} from 'react';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import { DatePicker } from '@material-ui/pickers';

import {useDispatch, useSelector} from 'react-redux';
import {campaignListRequest} from '../../../redux/actions/campaignList';
import {updateCampaignRequest} from '../../../redux/actions/updateCampaign';

import CampaignListTable from './CampaignListTable';


const cleanFilter = {
    name: '',
    status: 'NONE',
    effective_start_date: null,
    effective_end_date: null,
    active: 'NONE'
}

const statusMap = [
    {
        id: 'NONE',
        value: 'None'
    },
    {
        id: 'PENDING',
        value: 'Pending'
    },
    {
        id: 'APPROVED',
        value: 'Approved'
    },
    {
        id: 'REJECTED',
        value: 'Rejected'
    }
]

const activeMap = [
    {
        id: 'NONE',
        value: 'None'
    },
    {
        id: 'true',
        value: 'Active'
    },
    {
        id: 'false',
        value: 'Inactive'
    }
]

const useStyle = makeStyles(theme => ({
    createButton: {
        float: 'right'
    }
}));
export default function CampaignList(props) {
    const classes = useStyle();
    const [initValue, setInitValue] = useState(true);
    const [updateValue, setUpdateValue] = useState(false);
    const [filter, setFilter] = useState(cleanFilter);

    const isLoading = useSelector(state => state.campaignList.isLoading);
    const campaigns = useSelector(state => state.campaignList.campaigns);
    const isUpdating = useSelector(state => state.updateCampaign.isLoading);

    const role = useSelector(state => state.user.role);

    const dispatch = useDispatch();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState({
        title: '',
        message: ''
    });
    const [remark, setRemark] = useState('');
    const [status, setStatus] = useState('PENDING');
    const [selectedCampaign, setSelectedCampaign] = useState(0);

    const handleDialogClose = () => {
        setStatus(1);
        setSelectedCampaign(0);
        setDialogOpen(false);
    }

    const handleApproveButtonClicked = (campaign) => {
        setDialogContent({
            title: 'Approve Confirmation',
            message: 'Are you sure want to appove campaign ' + campaign.name + '?'
        });
        setRemark('');
        setStatus('APPROVED');
        setDialogOpen(true);
    }

    const handleRejectButtonClicked = (campaign) => {
        setDialogContent({
            title: 'Reject Confirmation',
            message: 'Are you sure want to reject campaign ' + campaign.name + '?'
        });
        setRemark('');
        setStatus('REJECTED');
        setDialogOpen(true);
    }

    const handleRemarkChange = (e) => {
        const value = e.target.value;
        setRemark(value);
    }

    const handleCampaignNameChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, name: value}));
    }

    const handleCampaignActiveChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, active: value}));
    }

    const handleCampaignStatusChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, status: value}));
    }

    const handleStartDateChange = (value) => {
        setFilter(prevState => ({...prevState, effective_start_date: value}));
    }

    const handleEndDateChange = (value) => {
        setFilter(prevState => ({...prevState, effective_end_date: value}));
    }

    const canSubmit = () => {
        return remark ? true : false;
    }

    const submitUpdate = () => {
        const updateCampaign = {
            id: selectedCampaign,
            data: {
                remark: remark,
                status: status
            }
        }
        setUpdateValue(true);
        dispatch(updateCampaignRequest(updateCampaign));
        handleDialogClose();
    }

    const handleApplyFilterButtonClicked = (e) => {
        if(initValue) return;
        let req = createFilteredRequest();
        dispatch(campaignListRequest(req));
    }

    const createFilteredRequest = () => {
        let filterRequest = {}

        if(filter.name) {
            filterRequest.name = filter.name;
        }
        if(filter.status !== 'NONE') {
            filterRequest.status = filter.status;
        } 
        if(filter.active !== 'NONE') {
            filterRequest.active = filter.active;
        }
        if(filter.effective_start_date) {
            filterRequest.effective_start_date = filter.effective_start_date.format('YYYY-MM-DD');
        }
        if(filter.effective_end_date) {
            filterRequest.effective_end_date = filter.effective_end_date.format('YYYY-MM-DD');
        }

        return filterRequest;
    }

    const handleClearFilterButtonClicked = (e) => {
        setFilter(cleanFilter);
    }

    useEffect(() => {
        if(initValue) {
            setInitValue(false);
            dispatch(campaignListRequest());
        } else if(updateValue && !isUpdating) {
            setUpdateValue(false);
            dispatch(campaignListRequest());
        }
    }, [campaigns, initValue, dispatch, updateValue, isUpdating]);

    return (
        <div>
            <Fade in={isLoading || isUpdating}>
              <LinearProgress />
            </Fade>
            <Box m={3}>
                <Grid container >
                    <Grid item xs={8}>
                    <Typography variant="h5">
                        Campaign List
                    </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.createButton}>
                        <Button color="primary" variant="contained" href="/campaigns/create">Create Campaign</Button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Paper elevation={2}>
                            <Box m={2}>
                                <Box>
                                    <Typography variant="subtitle1">
                                        Filter
                                    </Typography>
                                </Box>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="campaignName"
                                                label="Campaign Name"
                                                value={filter.name}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleCampaignNameChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box>
                                                <FormControl className={classes.formControl} fullWidth>
                                                    <InputLabel id="campaignStatus">Campaign Status</InputLabel>
                                                    <Select
                                                        id="campaignStatus"
                                                        value={filter.status}
                                                        onChange={handleCampaignStatusChange}
                                                    >
                                                        {statusMap.map(status => (
                                                            <MenuItem key={status.id} value={status.id}>{status.value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                id="effectiveStartDate"
                                                label="Effective Start Date"
                                                value={filter.effective_start_date}
                                                format="YYYY-MM-DD"
                                                ampm={false}
                                                fullWidth
                                                onChange={handleStartDateChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                id="effectiveEndDate"
                                                label="Effective End Date"
                                                value={filter.effective_end_date}
                                                format="YYYY-MM-DD"
                                                ampm={false}
                                                fullWidth
                                                onChange={handleEndDateChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box>
                                                <FormControl className={classes.formControl} fullWidth>
                                                    <InputLabel id="campaignActive">Campaign Active Flag</InputLabel>
                                                    <Select
                                                        id="campaignActive"
                                                        value={filter.active}
                                                        onChange={handleCampaignActiveChange}
                                                    >
                                                        {activeMap.map(active => (
                                                            <MenuItem key={active.id} value={active.id}>{active.value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button color="primary" variant="contained" fullWidth onClick={handleApplyFilterButtonClicked}>Apply Filter</Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" fullWidth onClick={handleClearFilterButtonClicked}>Clear Filter</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                        <CampaignListTable 
                            campaigns={campaigns} 
                            handleApproveButtonClicked={handleApproveButtonClicked} 
                            handleRejectButtonClicked={handleRejectButtonClicked}
                            setSelectedCampaign={setSelectedCampaign}
                            role={role}>
                        </CampaignListTable>
                    </Grid>
                </Grid>
            </Box>

            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{dialogContent.title}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {dialogContent.message}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="remarks"
                    label="Remarks"
                    value={remark}
                    fullWidth
                    onChange={handleRemarkChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={submitUpdate} disabled={!canSubmit()} color="primary">
                    Submit
                  </Button>
                  <Button onClick={handleDialogClose} color="secondary">
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
        </div>
    );
}