import React from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export default function CreateCommonUser(props) {
    const {classes, user, handleNameChange, handleUsernameChange, handleDescriptionChange} = props;

    return (<div>
        <Box m={3}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        id="name"
                        label="Name"
                        value={user.name}
                        fullWidth
                        className={classes.textField}
                        onChange={handleNameChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="username"
                        label="Username"
                        value={user.ad_username}
                        fullWidth
                        className={classes.textField}
                        onChange={handleUsernameChange}
                    />
                </Grid>
            </Grid>
        </Box>
        <Box m={3}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="description"
                        label="Description"
                        value={user.description}
                        fullWidth
                        className={classes.textField}
                        onChange={handleDescriptionChange}
                    />
                </Grid>
            </Grid>
        </Box>
    </div>);

}