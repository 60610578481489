import { USER_GET_BY_ID_ACTION_TYPE as ACTION } from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    meta: {
        success: false,
        errorCode: '',
        errorMsg: '',
    },
    user: {}
};

const userById = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.USER_GET_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ACTION.USER_GET_BY_ID_SUCCESS:
      return {
        isLoading: false,
        meta: {
          success: true,
          errorCode: action.response.data.errorCode,
          errorMsg: action.response.data.errorMsg
        },
        user: action.response.data.user
      }
    case ACTION.USER_GET_BY_ID_FAILED:
      return {
        isLoading: false,
        meta: {
          success: false,
          errorCode: action.error.response.data.meta.errorCode,
          errorMsg: action.error.response.data.meta.errorMsg
        }
      }
    default:
      return initialState;
  }
}

export default userById;