import {DASHBOARD_ACTIVE_VOUCHER_COUNT_ACTION_TYPE as ACTION} from './actionTypes';

export const dashboardActiveVoucherCountRequest = (data) =>  {
    return {
        type: ACTION.DASHBOARD_ACTIVE_VOUCHER_COUNT_REQUEST,
        data
    }
}

export const dashboardActiveVoucherCountSuccess = (response) =>  {
    return {
        type: ACTION.DASHBOARD_ACTIVE_VOUCHER_COUNT_SUCCESS,
        response
    }
  }

export const dashboardActiveVoucherCountFailed = (error) =>  {
    return {
        type: ACTION.DASHBOARD_ACTIVE_VOUCHER_COUNT_FAILED,
        error
    }
}