import React from 'react';

import { getStore } from '../../redux/store/storeConfig';
import { getUser } from '../../redux/selectors/user';

import Box from '@material-ui/core/Box';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    textTransform: 'capitalize'
  },
  mainMenu: {
    flexGrow: 1,
  },
}));

export default function MenuBar(props) {
  const {onLogoutClicked} = props;
  const classes = useStyles();
  const store = getStore();
  const userData = getUser(store);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    onLogoutClicked();
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Box className={classes.mainMenu}>
            <Button color="inherit" className={classes.menuButton} href="/dashboard">Dashboard</Button>
            <Button color="inherit" className={classes.menuButton} href="/campaigns">Campaign</Button>
            <Button color="inherit" className={classes.menuButton} href="/vouchers">Voucher</Button>
            {userData.role !== 'CREATOR' ? <Button color="inherit" className={classes.menuButton} href="/voucherbulk">Bulk Voucher</Button> : null}
            <Button color="inherit" className={classes.menuButton} href="/reports/vouchers">Report</Button>
            {userData.role === 'ADMIN' ? <Button color="inherit" className={classes.menuButton} href="/users">User</Button> : null}
          </Box>
          <div>
            <Button className={classes.menuButton} aria-controls="simple-menu" aria-haspopup="true" color="inherit" onClick={handleClick}>Hi, {userData.name}</Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              color="inherit"
            >
              {userData.role === 'ADMIN' ? <MenuItem component="a" href="/changepassword">Change Password</MenuItem> : null}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};
