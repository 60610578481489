import React, { PureComponent } from 'react';
import {Redirect} from 'react-router-dom';
import MenuBar from './MenuBar';
import LogoutDialog from './LogoutDialog';

import {connect} from 'react-redux';
import {logoutRequest} from '../../redux/actions/auth';


class MenuAppBar extends PureComponent  {

  constructor(props) {
    super(props);
    this.state = {
      logoutConfirmation: false,
    }
  }

  onChangePasswordClicked = () => {
    this.setState(prevState => ({...prevState, changePassword: true}))
  }

  onLogoutClicked = () => {
    this.setState({logoutConfirmation: true})
  }

  hideLogoutConfirmation = () => {
    this.setState({logoutConfirmation: false});
  }

  processLogout = () => {
    this.props.logoutRequest({});
  }

  render() {
    if(!this.props.authenticated) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <MenuBar onLogoutClicked={this.onLogoutClicked}></MenuBar>
        <LogoutDialog 
          open={this.state.logoutConfirmation} 
          hideLogoutConfirmation={this.hideLogoutConfirmation}
          processLogout={this.processLogout}></LogoutDialog>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  user: state.user,
  authenticated: state.user.authenticated
});

export default connect(mapStateToProps, {logoutRequest})(MenuAppBar);