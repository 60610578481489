import {UPDATE_VOUCHER_BULK_ACTION_TYPE as ACTION} from './actionTypes';

export const updateVoucherBulkRequest = (data) =>  {
    return {
        type: ACTION.UPDATE_VOUCHER_BULK_REQUEST,
        data
    }
}

export const updateVoucherBulkSuccess = (response) =>  {
    return {
        type: ACTION.UPDATE_VOUCHER_BULK_SUCCESS,
        response
    }
  }

export const updateVoucherBulkFailed = (error) =>  {
    return {
        type: ACTION.UPDATE_VOUCHER_BULK_FAILED,
        error
    }
}
