import dateFormat from 'dateformat';
import {APP_NAME} from './constants';
import axios from 'axios';
import {BASE_URL} from './constants';

import { getStore } from '../redux/store/storeConfig';
import { getUser } from '../redux/selectors/user';

const axiosInstance = axios.create({
    baseURL: BASE_URL
  });

export const configureHttp = () => {
    axiosInstance.interceptors.request.use(function (config) {
        addAdditionalHeader(config);
        addAuthorization(config);
        return config;
      }, function (error) {
        return Promise.reject(error);
      });
}

function addAdditionalHeader(config) {
    const randomSequenceNumber = Math.floor(Math.random() * 99999999);
    const dateString = dateFormat(new Date(), 'yyyyMMddHHmmssl');
    const requestId = APP_NAME + dateString + randomSequenceNumber;
    config.headers['X-Channel-ID'] = APP_NAME;
    config.headers['X-Request-ID'] = requestId;
}

function addAuthorization(config) {
  const store = getStore();
  const user = getUser(store);
  if(user.authenticated && user.token) {
    const token = user.token;
    config.headers['Authorization'] = 'Bearer ' + token;
  } 
}

export const doGet = (uri, params) => {
  return axiosInstance.get(uri, {
    params: params
  })
  .then(res => res)
  .catch(e => e);
}

export const doPost = (uri, data) => {
    return axiosInstance.post(
        uri,
        data
    )
    .then(res => res)
    .catch(e => e);
}

export const doPut = (uri, data) => {
  return axiosInstance.put(
      uri,
      data
  )
  .then(res => res)
  .catch(e => e);
}

export const doPatch = (uri, data) => {
  return axiosInstance.patch(
      uri,
      data
  )
  .then(res => res)
  .catch(e => e);
}