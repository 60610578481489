import {AVAILABLE_CAMPAIGN_LIST_ACTION_TYPE as ACTION} from './actionTypes';

export const getAvailableCampaignRequest = (data) =>  {
    return {
        type: ACTION.AVAILABLE_CAMPAIGN_LIST_REQUEST,
        data
    }
}

export const getAvailableCampaignSuccess = (response) =>  {
    return {
        type: ACTION.AVAILABLE_CAMPAIGN_LIST_SUCCESS,
        response
    }
  }

export const getAvailableCampaignFailed = (error) =>  {
    return {
        type: ACTION.AVAILABLE_CAMPAIGN_LIST_FAILED,
        error
    }
}