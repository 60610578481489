import {VOUCHER_PATCH_ACTION_TYPE as ACTION} from './actionTypes';

export const voucherPatchRequest = (data) =>  {
    return {
        type: ACTION.VOUCHER_PATCH_REQUEST,
        data
    }
}

export const voucherPatchSuccess = (response) =>  {
    return {
        type: ACTION.VOUCHER_PATCH_SUCCESS,
        response
    }
  }

export const voucherPatchFailed = (error) =>  {
    return {
        type: ACTION.VOUCHER_PATCH_FAILED,
        error
    }
}