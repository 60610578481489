import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';

export default function UserListTable(props) {
    const {user, updateUserStatus} = props;

    const handleEnableClick = (e) => {
        updateUserStatus(user.id, true);
    }

    const handleDisableClick = (e) => {
        updateUserStatus(user.id, false);
    }

    return (
        <TableRow key={user.id}>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.ad_username}</TableCell>
            <TableCell>{user.description}</TableCell>
            <TableCell>{user.role}</TableCell>
            <TableCell>{user.enabled ? "enabled" : "disabled"}</TableCell>
            <TableCell>
                <span>
                    <IconButton size="small" href={'/users/edit/' + user.id}>
                        <EditIcon color="primary"/>
                    </IconButton>
                    {user.enabled ? 
                        <IconButton size="small" onClick={handleDisableClick}>
                            <BlockIcon color="error" />
                        </IconButton>:
                        <IconButton size="small" onClick={handleEnableClick}>
                            <CheckIcon color="primary"/>
                        </IconButton>
                    }
                </span>
            </TableCell>
        </TableRow>
    )
}