import React from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export default function Age(props) {
    const {classes, setVoucher, voucher} = props;

    const handleMinAgeChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                age: {
                    ...prevState.age,
                    min_age: value
                }
            }
        });
    }

    const handleMaxAgeChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                age: {
                    ...prevState.age,
                    max_age: value
                }
            }
        });
    }

    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Age Rule
                </Typography>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            id="minAge"
                            label="Min Age"
                            value={voucher.age.min_age}
                            fullWidth
                            required
                            className={classes.textField}
                            onChange={handleMinAgeChange}
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="maxAge"
                            label="Max Age"
                            value={voucher.age.max_age}
                            fullWidth
                            required
                            className={classes.textField}
                            onChange={handleMaxAgeChange}
                            type="number"
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}