import {USER_GET_BY_ID_ACTION_TYPE as ACTION} from './actionTypes';

export const userByIdRequest = (data) =>  {
    return {
        type: ACTION.USER_GET_BY_ID_REQUEST,
        data
    }
}

export const userByIdSuccess = (response) =>  {
    return {
        type: ACTION.USER_GET_BY_ID_SUCCESS,
        response
    }
  }

export const userByIdFailed = (error) =>  {
    return {
        type: ACTION.USER_GET_BY_ID_FAILED,
        error
    }
}