import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {voucherReportListSuccess, voucherReportListFailed} from '../actions/voucherReportList';
import { VOUCHER_LIST_REPORT_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* voucherReportList({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.REPORT_VOUCHER, data);
    if(responseData.status === 200){
      yield put(voucherReportListSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(voucherReportListFailed(responseData));
    }
  } catch (error) {
    yield put(voucherReportListFailed(error));
  }
}
  
function* watchVoucherReportList() {
  yield takeLatest(ACTION.VOUCHER_LIST_REPORT_REQUEST, voucherReportList);
}

export default function* root() {
  yield fork(watchVoucherReportList);
}
  