import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';


import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';

import ErrorSnackBar from '../../Utilities/ErrorSnackBar';

import {useDispatch, useSelector} from 'react-redux';
import {userUpdatePasswordRequest} from '../../../redux/actions/userUpdatePassword';

const useStyle = makeStyles(themes => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export default function ChangePassword(props) {
    const classes = useStyle();
    const [data, setData] = useState({
        password: '',
        newPassword: '',
        confirmNewPassword: ''
    });

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const currentUser = useSelector(state => state.user);

    const dispatch = useDispatch();

    const isUpdating = useSelector(state => state.userUpdatePassword.isLoading);
    const meta = useSelector(state => state.userUpdatePassword.meta);

    const handlePasswordChange = (e) => {
        const val = e.target.value;
        setData(prevState => ({...prevState, password: val}));
    }

    const handleNewPasswordChange = (e) => {
        const val = e.target.value;
        setData(prevState => ({...prevState, newPassword: val}));
    }

    const handleConfirmNewPasswordChange = (e) => {
        const val = e.target.value;
        setData(prevState => ({...prevState, confirmNewPassword: val}));
    }

    const handleSubmitButtonClicked = (e) => {
        let request = {
            password: data.password,
            new_password: data.newPassword
        }
        dispatch(userUpdatePasswordRequest(request));
    }

    useEffect(() => {
        if(!meta.success && meta.errorMsg) {
            setErrorMessage(meta.errorMsg);
            setOpen(true);
        }
    }, [meta]);

    if(meta.success) {
        return <Redirect to="/dashboard"></Redirect>
    }

    return currentUser.role !=='ADMIN' ? <Redirect to="/dashboard"></Redirect> : (
        <div>
            <Fade in={isUpdating}>
                <LinearProgress />
            </Fade>
            <Box m={3}>
                <Typography variant="h5">
                    Edit Password
                </Typography>
            </Box>
            <Box m={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <TextField
                            id="password"
                            label="Password"
                            value={data.password}
                            fullWidth
                            className={classes.textField}
                            type="password"
                            onChange={handlePasswordChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <TextField
                            id="password"
                            label="New Password"
                            value={data.newPassword}
                            fullWidth
                            className={classes.textField}
                            type="password"
                            onChange={handleNewPasswordChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <TextField
                            id="password"
                            label="Confirm New Password"
                            value={data.confirmNewPassword}
                            fullWidth
                            className={classes.textField}
                            type="password"
                            onChange={handleConfirmNewPasswordChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Button 
                            disabled={!data.password || !data.newPassword || !data.confirmNewPassword || data.newPassword !== data.confirmNewPassword}
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            fullWidth
                            onClick={handleSubmitButtonClicked}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <ErrorSnackBar
                open={open}
                setOpen={setOpen}
                hideDuration={3000}
                errorMessage={errorMessage}
            >
            </ErrorSnackBar>
        </div>);
}