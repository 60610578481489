import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {dashboardCampaignStatusCountSuccess, dashboardCampaignStatusCountFailed} from '../actions/dashboardCampaignStatusCount';
import { DASHBOARD_CAMPAIGN_STATUS_COUNT_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* dashboardCampaignStatusCount({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.DASHBOARD + '/campaign-status/count', null);
    if(responseData.status === 200){
      yield put(dashboardCampaignStatusCountSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(dashboardCampaignStatusCountFailed(responseData));
    }
  } catch (error) {
    yield put(dashboardCampaignStatusCountFailed(error));
  }
}
  
function* watchDashboardCampaignStatusCount() {
  yield takeLatest(ACTION.DASHBOARD_CAMPAIGN_STATUS_COUNT_REQUEST, dashboardCampaignStatusCount);
}

export default function* root() {
  yield fork(watchDashboardCampaignStatusCount);
}