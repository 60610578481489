import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { DatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import {useDispatch, useSelector} from 'react-redux';
import {voucherListRequest} from '../../../redux/actions/voucherList';
import {voucherPatchRequest} from '../../../redux/actions/voucherPatch';

import VoucherListTable from './VoucherListTable';

const cleanFilter = {
    campaign_id: '',
    campaign_name: '',
    voucher_code: '',
    voucher_name: '',
    min_age: '',
    max_age: '',
    activation_start_date: null,
    activation_end_date: null,
    auto_debit_flag: 'NONE',
    contract_period: '',
    region: [],
    voucher_status: 'NONE'
}

const autoDebitMap = [
    {
        id: 'NONE',
        value: 'None'
    },
    {
        id: 'USE',
        value: 'Auto Debit'
    },
    {
        id: 'NOTUSE',
        value: 'Non Auto Debit'
    }
]

const regionMap = [
    {
        id: 'NORTHERN',
        value: 'Northern'
    },
    {
        id: 'SOUTHERN',
        value: 'Southern'
    },
    {
        id: 'CENTRAL',
        value: 'Central'
    },
    {
        id: 'EASTCOAST',
        value: 'East Coast'
    }
]

const statusMap = [
    {
        id: 'NONE',
        value: 'None'
    },
    {
        id: 'PENDING',
        value: 'Pending'
    },
    {
        id: 'APPROVED',
        value: 'Approved'
    },
    {
        id: 'REJECTED',
        value: 'Rejected'
    }
]

const useStyle = makeStyles(theme => ({
    createButton: {
        float: 'right'
    },
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
}));
export default function VoucherList(props) {
    const classes = useStyle();

    const [initValue, setInitValue] = useState(true);
    const [selectedVoucher, setSelectedVoucher] = useState(0);
    const [remark, setRemark] = useState('');
    const [status, setStatus] = useState('');
    const [regionState, setRegionState] = useState({
        'NORTHERN': false,
        'SOUTHERN': false,
        'CENTRAL': false,
        'EASTCOAST': false
    });

    const [filter, setFilter] = useState(cleanFilter);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState({
        title: '',
        message: ''
    });

    const [updateTriggered, setUpdateTriggered] = useState(false);

    const isLoading = useSelector(state => state.voucherList.isLoading);
    const vouchers = useSelector(state => state.voucherList.vouchers);
    const isUpdateLoading = useSelector(state => state.patchVoucher.isLoading);
    const role = useSelector(state => state.user.role);

    const dispatch = useDispatch();

    const handleDialogClose = () => {
        setStatus(1);
        setSelectedVoucher(0);
        setDialogOpen(false);
    }

    const handleApproveButtonClicked = (voucher) => {
        setDialogContent({
            title: 'Approve Confirmation',
            message: 'Are you sure want to appove voucher ' + voucher.name + '?'
        });
        setRemark('');
        setStatus('APPROVED');
        setDialogOpen(true);
    }

    const handleRejectButtonClicked = (voucher) => {
        setDialogContent({
            title: 'Reject Confirmation',
            message: 'Are you sure want to reject voucher ' + voucher.name + '?'
        });
        setRemark('');
        setStatus('REJECTED');
        setDialogOpen(true);
    }

    const handleCampaignIdChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, campaign_id: value}));
    }

    const handleCampaignNameChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, campaign_name: value}));
    }

    const handleVoucherNameChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, voucher_name: value}));
    }
    
    const handleVoucherCodeChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, voucher_code: value}));
    }

    const handleVoucherStatusChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, voucher_status: value}));
    }

    const handleMinAgeChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, min_age: value}));
    }

    const handleMaxAgeChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, max_age: value}));
    }

    const handleServiceActivationStartChange = (value) => {
        setFilter(prevState => ({...prevState, activation_start_date: value}));
    }

    const handleServiceActivationEndChange = (value) => {
        setFilter(prevState => ({...prevState, activation_end_date: value}));
    }

    const handleAutoDebitFlagChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, auto_debit_flag: value}));
    }

    const handleContractPeriodChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, contract_period: value}));
    }

    const handleRegionChange = (e) => {
        setRegionState(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
    }

    const createFilterRequest = () => {
        let params = {}
        if(filter.campaign_id) params.campaign_id = filter.campaign_id;
        if(filter.campaign_name) params.campaign_name = filter.campaign_name;
        if(filter.voucher_name) params.voucher_name = filter.voucher_name;
        if(filter.voucher_code) params.voucher_code = filter.voucher_code;
        if(filter.voucher_status && filter.voucher_status !== 'NONE') params.voucher_status = filter.voucher_status;

        if(filter.min_age) params.min_age = filter.min_age;
        if(filter.max_age) params.max_age = filter.max_age;

        if(filter.activation_start_date) params.activation_start_date = filter.activation_start_date.format('YYYY-MM-DD');
        if(filter.activation_end_date) params.activation_end_date = filter.activation_end_date.format('YYYY-MM-DD');

        if(filter.auto_debit_flag !== 'NONE') params.auto_debit_flag = (filter.auto_debit_flag === 'USE');
        
        if(filter.contract_period) params.contract_period = filter.contract_period;

        let regionArray = [];

        for(var key in regionState) {
            const value = regionState[key];
            if(value) {
                regionArray.push(key);
            }
        }

        if(regionArray.length > 0) {
            params.region = regionArray.join(';');
        }

        return params;
    }

    const handleApplyFilterButtonClicked = (e) => {
        let params = createFilterRequest();
        dispatch(voucherListRequest(params));
    }

    const handleClearFilterButtonClicked = (e) => {
        setFilter(cleanFilter);
    }

    const handleRemarkChange = (e) => {
        const value = e.target.value;
        setRemark(value);
    }

    const canSubmit = () => {
        return remark ? true : false;
    }

    const submitUpdate = (e) => {
        const updateVoucher = {
            id: selectedVoucher,
            data: {
                remark: remark,
                status: status
            }
        }
        //TODO dispatch update voucher
        dispatch(voucherPatchRequest(updateVoucher));
        setUpdateTriggered(true);
        handleDialogClose();
    }

    useEffect(() => {
        if(initValue) {
            setInitValue(false);
            dispatch(voucherListRequest());
        }
    }, [vouchers, initValue, dispatch]);

    useEffect(() => {
        if(updateTriggered && !isUpdateLoading) {
            setUpdateTriggered(false);
            dispatch(voucherListRequest());
        }
    }, [updateTriggered, isUpdateLoading, dispatch]);

    return (
        <React.Fragment>
            <Fade in={isLoading || isUpdateLoading}>
              <LinearProgress />
            </Fade>
            <Box m={3}>
                <Grid container>
                    <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
                    <Typography variant="h5">
                        Voucher List
                    </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.createButton}>
                        <Button color="primary" variant="contained" href="/vouchers/create">Create Voucher</Button>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box m={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Paper elevation={2}>
                            <Box m={2}>
                                <Box>
                                    <Typography variant="subtitle1">
                                        Filter
                                    </Typography>
                                </Box>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="campaignId"
                                                label="Campaign ID"
                                                value={filter.campaign_id}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleCampaignIdChange}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="campaignName"
                                                label="Campaign Name"
                                                value={filter.campaign_name}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleCampaignNameChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="voucherName"
                                                label="Voucher Name"
                                                value={filter.voucher_name}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleVoucherNameChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="voucherCode"
                                                label="Voucher Code"
                                                value={filter.voucher_code}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleVoucherCodeChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box>
                                                <FormControl className={classes.formControl} fullWidth>
                                                    <InputLabel id="voucherStatus">Voucher Status</InputLabel>
                                                    <Select
                                                        id="voucherStatus"
                                                        value={filter.voucher_status}
                                                        onChange={handleVoucherStatusChange}
                                                    >
                                                        {statusMap.map(status => (
                                                            <MenuItem key={status.id} value={status.id}>{status.value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="minAge"
                                                label="Min Age"
                                                value={filter.min_age}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleMinAgeChange}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="maxAge"
                                                label="Max Age"
                                                value={filter.max_age}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleMaxAgeChange}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                id="serviceActivationStartDate"
                                                label="Service Activation Start"
                                                value={filter.activation_start_date}
                                                format="YYYY-MM-DD"
                                                ampm={false}
                                                fullWidth
                                                onChange={handleServiceActivationStartChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DatePicker
                                                id="serviceActivationEndDate"
                                                label="Service Activation End"
                                                value={filter.activation_end_date}
                                                format="YYYY-MM-DD"
                                                ampm={false}
                                                fullWidth
                                                onChange={handleServiceActivationEndChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box>
                                                <FormControl className={classes.formControl} fullWidth>
                                                    <InputLabel id="autoDebit">Auto Debit</InputLabel>
                                                    <Select
                                                        id="autoDebit"
                                                        value={filter.auto_debit_flag}
                                                        onChange={handleAutoDebitFlagChange}
                                                    >
                                                        {autoDebitMap.map(autoDebit => (
                                                            <MenuItem key={autoDebit.id} value={autoDebit.id}>{autoDebit.value}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="contractPeriod"
                                                label="Contract Period"
                                                value={filter.contract_period}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleContractPeriodChange}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box>
                                                <Typography variant="body1">
                                                    Region
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormGroup>
                                                        <Grid container>
                                                            {regionMap.map(region => (
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={region.id}>
                                                                    <FormControlLabel
                                                                    control={<Checkbox checked={regionState[region.id]} onChange={handleRegionChange} name={region.id} />}
                                                                    label={region.value}
                                                                    />
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </FormGroup>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button color="primary" variant="contained" fullWidth onClick={handleApplyFilterButtonClicked}>Apply Filter</Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" fullWidth onClick={handleClearFilterButtonClicked}>Clear Filter</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                        <VoucherListTable 
                            vouchers={vouchers} 
                            setSelectedVoucher={setSelectedVoucher}
                            handleApproveButtonClicked={handleApproveButtonClicked}
                            handleRejectButtonClicked={handleRejectButtonClicked}
                            role={role}
                        >
                        </VoucherListTable>
                    </Grid>
                </Grid>
            </Box>
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{dialogContent.title}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {dialogContent.message}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="remarks"
                    label="Remarks"
                    value={remark}
                    fullWidth
                    onChange={handleRemarkChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={submitUpdate} disabled={!canSubmit()} color="primary">
                    Submit
                  </Button>
                  <Button onClick={handleDialogClose} color="secondary">
                    Cancel
                  </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}