import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    close: {
      padding: theme.spacing(0.5),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    margin: {
      margin: theme.spacing(1),
    }
}));

export default function ErrorSnackBar(props) {
    const classes = useStyles();
    const {open, setOpen, hideDuration, errorMessage} = props;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    return (
        <Snackbar
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            open={open}
            autoHideDuration={hideDuration}
            onClose={handleClose}
        >
            <SnackbarContent
                aria-describedby="client-snackbar"
                message={
                <span className={classes.message}>
                    {errorMessage}
                </span>
                }
                action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose} className={classes.close}>
                    <CloseIcon />
                </IconButton>,
                ]}
                className={classes.margin}
            />
        </Snackbar>
    );
}