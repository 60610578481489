import React, {useState, useEffect} from 'react';

import {Redirect} from 'react-router-dom';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import theme from 'theme';

import {useDispatch, useSelector} from 'react-redux';

import CreateVoucherInit from './CreateVoucherInit';
import CreateVoucherDetail from './CreateVoucherDetail';

import * as moment from 'moment';

import {getAvailableCampaignRequest} from '../../../redux/actions/availableCampaignList';
import {getAvailableVoucherCodeRequest} from '../../../redux/actions/availableVoucherCode';
import {createVoucherRequest} from '../../../redux/actions/createVoucher';

import ErrorSnackBar from '../../Utilities/ErrorSnackBar';

const useStyle = makeStyles(themes => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    }
}));


const voucherTypeMap = [
    {
        id: 'SINGLEUSE',
        value: 'Single use'
    },
    {
        id: 'QUANTITY',
        value: 'Quantity limit'
    },
    {
        id: 'AMOUNT',
        value: 'Amount limit'
    },
    {
        id: 'REFERRAL',
        value: 'Referral'
    },
    {
        id: 'RANDOM',
        value: 'Random'
    }
]

const merchantVoucherTypeMap = [
    {
        id: 'SINGLEUSE',
        value: 'Single use'
    },
    {
        id: 'QUANTITY',
        value: 'Quantity limit'
    },
    {
        id: 'AMOUNT',
        value: 'Amount limit'
    },
    {
        id: 'RANDOM',
        value: 'Random'
    }
]
export default function CreateVoucher(props) {
    const classes = useStyle();

    const initRuleState = {
        'AGE': false,
        'ACTIVATION_DATE': false,
        'REGION': false,
        'AUTODEBIT_FLAG': false,
        'CONTRACT_PERIOD': false,
        'NORULES': false,
    };

    const [initValues, setInitValues] = useState(false);
    const [stage, setStage] = useState(1);
    const [open, setOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [ruleState, setRuleState] = useState(initRuleState);
    const [currentVoucherTypeMap, setCurrentVoucherTypeMap] = useState(voucherTypeMap);

    const [regionState, setRegionState] = useState({
        'NORTHERN': false,
        'SOUTHERN': false,
        'CENTRAL': false,
        'EASTCOAST': false
    });

    const [voucher, setVoucher] = useState({
        name: '',
        description: '',
        campaign_id: '',
        rules: [],
        age: {
            min_age: '',
            max_age: ''
        },
        activation_date: {
            start_date: moment().seconds(0).millisecond(0),
            end_date: moment().seconds(0).millisecond(0)
        },
        whitelist: {
            type: 'NONE',
            values: []
        },
        region: [],
        random: true,
        code_template: {
            prefix: '',
            random_type: '',
            suffix: ''
        },
        type: '',
        amount_limit: '',
        quantity_limit: '',
        mechanic: '',
        discount_value: '',
        discount_percentage: '',
        max_discount_value: '',
        lock_period: '',
        codes: [],
        bulk_flag: false,
        bulk_type: '',
        bulk_list: [],
        merchant_id: '',
        whitelist_flag: false,
        auto_debit_flag: false,
        contract_period: '',
        bulk_filename: '',
        whitelist_filename: '',
        voucher_code_type: '',
        fixed_voucher_code: ''
    });

    const isCreateLoading = useSelector(state => state.createVoucher.isLoading);
    const meta = useSelector(state => state.createVoucher.meta);

    const isGetCampaignLoading = useSelector(state => state.availableCampaignList.isLoading);
    const campaigns = useSelector(state => state.availableCampaignList.campaigns);

    const isGetVoucherCodeLoading = useSelector(state => state.availableVoucherCode.isLoading);
    const generatedVoucherCode = useSelector(state => state.availableVoucherCode.voucher_code);

    const dispatch = useDispatch();

    const isLoading = () => {
        return isCreateLoading || isGetCampaignLoading || isGetVoucherCodeLoading;
    }

    const changeVoucherTypeMap = (type) => {
        if(type === 'MERCHANT') {
            setCurrentVoucherTypeMap(merchantVoucherTypeMap);
        } else {
            setCurrentVoucherTypeMap(voucherTypeMap);
        }
    }

    const generateVoucherCode = () => {
        const codeTemplate = {
            random_type: voucher.code_template.random_type,
            prefix: voucher.code_template.prefix,
            suffix: voucher.code_template.suffix
        }
        dispatch(getAvailableVoucherCodeRequest(codeTemplate));
    }

    const submitCreateVoucher = () => {
        const request = generateRequest();
        setSubmitted(true);
        dispatch(createVoucherRequest(request));
    }

    const generateRequest = () => {
        const request = {
            name: voucher.name,
            description: voucher.description,
            rules: voucher.rules,
            campaign: voucher.campaign_id,
            type: voucher.type,
            mechanic: voucher.mechanic,
            whitelist_flag: voucher.whitelist_flag
        }

        if(voucher.random) {
            request.codes = [generatedVoucherCode];
        } else {
            request.codes = [voucher.fixed_voucher_code];
        }

        if(voucher.amount_limit) {
            request.amount_limit = voucher.amount_limit;
        }

        if(voucher.quantity_limit) {
            request.quantity_limit = voucher.quantity_limit;
        }

        if(voucher.discount_value) {
            request.discount_value = voucher.discount_value;
        }

        if(voucher.discount_percentage) {
            request.discount_percentage = voucher.discount_percentage;
        }

        if(voucher.max_discount_value) {
            request.max_discount_value = voucher.max_discount_value;
        }

        if(voucher.lock_period) {
            request.lock_period = voucher.lock_period;
        }

        if(voucher.age) {
            request.age = voucher.age;
        }

        if(voucher.activation_date) {
            request.activation_date = voucher.activation_date;
        }

        if(voucher.whitelist) {
            request.whitelist = voucher.whitelist;
        }

        if(voucher.region) {
            request.region = voucher.region;
        }

        if(voucher.auto_debit_flag) {
            request.auto_debit_flag = voucher.auto_debit_flag;
        }

        if(voucher.contract_period) {
            request.contract_period = voucher.contract_period;
        }

        if(voucher.bulk_flag) {
            request.bulk_flag = voucher.bulk_flag;
            request.bulk_type = voucher.bulk_type;
            request.bulk_list = voucher.bulk_list;

            if(request.bulk_type === 'IC') {
                request.bulk_code_template = {
                    type: voucher.code_template.random_type,
                    prefix: voucher.code_template.prefix,
                    suffix: voucher.code_template.suffix
                };
            } else if(request.bulk_type === 'MERCHANT') {
                for(let i = 0; i < request.bulk_list.length; i++) {
                    request.bulk_list[i].id = voucher.merchant_id;
                }
            }
             
            // if(voucher.bulk_type !== "MERCHANT") {
            //     request.bulk_list = voucher.bulk_list;
            //     request.bulk_code_template = {
            //         type: voucher.code_template.random_type,
            //         prefix: voucher.code_template.prefix,
            //         suffix: voucher.code_template.suffix
            //     };
            // } else {
            //     request.merchant_data = voucher.merchant_data;
            // }
        }

        return request;
    }

    useEffect(() => {
        if(!initValues) {
            setInitValues(true);
            dispatch(getAvailableCampaignRequest());
        }
    }, [initValues, campaigns, dispatch, voucher, isCreateLoading, meta, submitted]);

    useEffect(() => {
        if(submitted && !isCreateLoading) {
            if(!meta.success && meta.errorMsg) {
                setSubmitted(false);
                setOpen(true);
            }
        }
    }, [isCreateLoading, meta, submitted]);

    if(meta.success) return (<Redirect to="/vouchers"></Redirect>);

    switch (stage) {
        case 1:
            return (
                <div>
                    <Fade in={isLoading()}>
                        <LinearProgress />
                    </Fade>
                    <Box m={3}>
                        <Typography variant="h5">
                            Create Voucher (Step 1 of 2)
                        </Typography>
                    </Box>
                    <CreateVoucherInit classes={classes} setStage={setStage} campaigns={campaigns} setVoucher={setVoucher} voucher={voucher} ruleState={ruleState} setRuleState={setRuleState} initRuleState={initRuleState}></CreateVoucherInit>
                    <ErrorSnackBar
                        open={open}
                        setOpen={setOpen}
                        hideDuration={3000}
                        errorMessage={meta.errorMsg}
                    >
                    </ErrorSnackBar>
                </div>
            )
        case 2:
            return (
                <div>
                    <Fade in={isLoading()}>
                        <LinearProgress />
                    </Fade>
                    <Box m={3}>
                        <Typography variant="h5">
                            Create Voucher Detail (Step 2 of 2)
                        </Typography>
                    </Box>
                    <CreateVoucherDetail 
                        classes={classes} 
                        setStage={setStage} 
                        setVoucher={setVoucher} 
                        voucher={voucher} 
                        generateVoucherCode={generateVoucherCode} 
                        generatedVoucherCode={generatedVoucherCode} 
                        submitCreateVoucher={submitCreateVoucher} 
                        regionState={regionState} 
                        setRegionState={setRegionState} 
                        currentVoucherTypeMap={currentVoucherTypeMap} 
                        changeVoucherTypeMap={changeVoucherTypeMap}
                    ></CreateVoucherDetail>
                    <ErrorSnackBar
                        open={open}
                        setOpen={setOpen}
                        hideDuration={3000}
                        errorMessage={meta.errorMsg}
                    >
                    </ErrorSnackBar>
                </div>
            )
        default:
            return (<div></div>)
    }

}