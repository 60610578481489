import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {campaignListSuccess, campaignListFailed} from '../actions/campaignList';
import { CAMPAIGN_LIST_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* campaignList({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.CAMPAIGN, data);
    if(responseData.status === 200){
      yield put(campaignListSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(campaignListFailed(responseData));
    }
  } catch (error) {
    yield put(campaignListFailed(error));
  }
}
  
function* watchCampaignList(){
  yield takeLatest(ACTION.CAMPAIGN_LIST_REQUEST, campaignList);
}

export default function* root() {
  yield fork(watchCampaignList);
}
  