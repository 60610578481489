import { AUTH_ACTION_TYPE as ACTION, INITIAL_STATE } from '../actions/actionTypes';

const initialState = INITIAL_STATE;

const auth = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ACTION.LOGIN_SUCCESS:
      return {
        isLoading: false,
        meta: {
          success: true,
          errorCode: action.response.data.errorCode,
          errorMsg: action.response.data.errorMsg
        },
        user: action.response.data.user
      }
    case ACTION.LOGIN_FAILED:
      return {
        isLoading: false,
        meta: {
          success: false,
          errorCode: action.error.response.data.meta.errorCode,
          errorMsg: action.error.response.data.meta.errorMsg
        }
      }
    case ACTION.LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ACTION.LOGOUT_SUCCESS:
        return {
          isLoading: false,
          meta: {
            success: true,
            errorCode: action.response.data.errorCode,
            errorMsg: action.response.data.errorMsg
          }
        }
    
    case ACTION.LOGOUT_FAILED:
        return {
          isLoading: false,
          meta: {
            success: false,
            errorCode: action.error.response.data.meta.errorCode,
            errorMsg: action.error.response.data.meta.errorMsg
          }
        }
    default:
      return initialState;
  }
}

export default auth;