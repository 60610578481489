import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {getVoucherBulkSuccess, getVoucherBulkFailed} from '../actions/getVoucherBulk';
import { GET_VOUCHER_BULK_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* getVoucherBulk({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.VOUCHER_BULK, data);
    if(responseData.status === 200){
      yield put(getVoucherBulkSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(getVoucherBulkFailed(responseData));
    }
  } catch (error) {
    yield put(getVoucherBulkFailed(error));
  }
}
  
function* watchGetVoucherBulk() {
  yield takeLatest(ACTION.GET_VOUCHER_BULK_REQUEST, getVoucherBulk);
}

export default function* root() {
  yield fork(watchGetVoucherBulk);
}
  