import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";

import VoucherBulkTableItem from "./VoucherBulkTableItem";
import SelectAllSortableTableHead from "components/Table/SelectAllSortableTableHead";

const useStyles = makeStyles((theme) => ({
 root: {
  width: "100%",
 },
 paper: {
  marginTop: theme.spacing(2),
  width: "100%",
  overflowX: "auto",
  marginBottom: theme.spacing(2),
 },
 table: {
  minWidth: 650,
 },
 visuallyHidden: {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: 20,
  width: 1,
 },
}));

function descendingComparator(a, b, orderBy) {
 if (b[orderBy] < a[orderBy]) {
  return -1;
 }
 if (b[orderBy] > a[orderBy]) {
  return 1;
 }
 return 0;
}

function getComparator(order, orderBy) {
 return order === "desc"
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
 if (!array) return [];
 const stabilizedThis = array.map((el, index) => [el, index]);
 stabilizedThis.sort((a, b) => {
  const order = comparator(a[0], b[0]);
  if (order !== 0) return order;
  return a[1] - b[1];
 });
 let result = stabilizedThis.map((el) => el[0]);
 return result;
}

const tableCellHeader = [
 {
  id: "campaign_id",
  label: "Campaign ID",
  sortable: true,
 },
 {
  id: "campaign_name",
  label: "Campaign Name",
  sortable: true,
 },
 {
  id: "campaign_effective_from",
  label: "Campaign Effective From",
  sortable: true,
 },
 {
  id: "campaign_effective_to",
  label: "Campaign Effective To",
  sortable: true,
 },
 {
  id: "voucher_name",
  label: "Voucher Name",
  sortable: true,
 },
 {
  id: "voucher_code",
  label: "Voucher Code",
  sortable: true,
 },
 {
  id: "voucher_status",
  label: "Status",
  sortable: true,
 },
];

export default function VoucherBulkTable(props) {
 const classes = useStyles();
 const {
  vouchers,
  handleSelectChange,
  isChecked,
  handleSelectAll,
  selectedVoucher,
 } = props;
 const [allSelected, setAllSelected] = useState(false);

 const [page, setPage] = React.useState(0);
 const [rowsPerPage, setRowsPerPage] = React.useState(20);

 const [order, setOrder] = React.useState("desc");
 const [orderBy, setOrderBy] = React.useState("campaign_effective_from");

 const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
 };

 const handlePageChange = (event, newPage) => {
  setPage(newPage);
 };

 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === "asc";
  setOrder(isAsc ? "desc" : "asc");
  setOrderBy(property);
 };

 useEffect(() => {
  setPage(0);
 }, [vouchers]);

 useEffect(() => {
  let allSelected =
   vouchers &&
   selectedVoucher &&
   vouchers.length > 0 &&
   vouchers.length === selectedVoucher.length;
  setAllSelected(allSelected);
 }, [vouchers, selectedVoucher]);

 return (
  <div className={classes.root}>
   <TableContainer component={Paper}>
    <Table className={classes.table} size="small" aria-label="a dense table">
     <SelectAllSortableTableHead
      classes={classes}
      order={order}
      orderBy={orderBy}
      headCells={tableCellHeader}
      onRequestSort={handleRequestSort}
      allSelected={allSelected}
      handleSelectAll={handleSelectAll}
     ></SelectAllSortableTableHead>
     <TableBody>
      {stableSort(vouchers, getComparator(order, orderBy))
       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
       .map((row, index) => (
        <VoucherBulkTableItem
         key={row.id}
         voucher={row}
         handleSelectChange={handleSelectChange}
         isChecked={isChecked}
        ></VoucherBulkTableItem>
       ))}
     </TableBody>
     <TableFooter>
      <TableRow>
       <TablePagination
        rowsPerPageOptions={[10, 20]}
        colSpan={tableCellHeader.length}
        count={vouchers ? vouchers.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
         inputProps: { "aria-label": "rows per page" },
         native: true,
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
       />
      </TableRow>
     </TableFooter>
    </Table>
   </TableContainer>

   <TableBody></TableBody>
  </div>
 );
}
