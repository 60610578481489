import React, {useEffect} from 'react';


import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import validator from 'validator';

const ruleMap = [
    {
        id: 'AGE',
        value: 'Age'
    },
    {
        id: 'ACTIVATION_DATE',
        value: 'Activation Date'
    },
    {
        id: 'REGION',
        value: 'Region'
    },
    {
        id: 'AUTODEBIT_FLAG',
        value: 'Auto Debit'
    },
    {
        id: 'CONTRACT_PERIOD',
        value: 'Contract Period'
    },
    {
        id: 'NORULES',
        value: 'No Rules'
    }
]

const whitelistMap = [
    {
        id: 'NONE',
        value: 'None'
    },
    {
        id: 'ACCOUNTNUMBER',
        value: 'Account Number'
    },
    {
        id: 'IDNUMBER',
        value: 'ID Number'
    },
    {
        id: 'SERVICEID',
        value: 'Service ID'
    }
]

export default function CreateVoucherInit(props) {
    const {setStage, classes, campaigns, setVoucher, voucher, ruleState, setRuleState, initRuleState} = props;

    const handleNextClicked = (e) => {
        setStage(2);
    }

    const disableButton = () => {
        if(
            validator.isEmpty(voucher.name) || 
            validator.isEmpty(voucher.description) || 
            validator.isEmpty(voucher.campaign_id + '') || 
            (voucher.lock_period && !validator.isInt(voucher.lock_period + '')) || 
            voucher.rules.length === 0) {
                return true;
        } 
        return false;
    }

    const handleNameChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState, 
                name: value
            }
        });
    }

    const handleLockPeriodChange = (e) => {
        const value = e.target.value;
        const error = !validator.isInt(value);

        setVoucher(prevState => {
            return {
                ...prevState, 
                lock_period: value,
                lock_period_error: error
            }
        });
    }

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState, 
                description: value
            }
        });
    }
    const handleChange = (event) => {
        let targetName = event.target.name;
        let checked = event.target.checked;

        if(targetName === 'NORULES') {
            setRuleState({...initRuleState, NORULES: checked});
        } else {
            setRuleState(prevState => ({ ...prevState, NORULES: false, [targetName]: checked }));
        }
    };

    const handleCampaignChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                campaign_id: value
            }
        });
    }

    const handleBulkFlagChange = (e) => {
        setVoucher(prevState => {
            return {
                ...prevState,
                bulk_flag: !prevState.bulk_flag
            }
        });
    }

    const disableRules = (ruleId) => {
        return voucher.whitelist_flag || (voucher.rules.length === 3 && !ruleState[ruleId]);
    }

    const handleWhitelistChange = (e) => {
        const value = e.target.value;
        let whitelistFlag = true;
        let rules = []
        if(value === 'NONE') {
            whitelistFlag = false;
        } else {
            setRuleState(initRuleState);
            rules = ['WL_' + value];
        }

        setVoucher(prevState => {
            return {
                ...prevState,
                rules: rules,
                whitelist: {
                    ...prevState.whitelist,
                    type: value
                },
                whitelist_flag: whitelistFlag
            }
        });
    }

    useEffect(() => {
        if(!voucher.whitelist_flag) {
            const rulesResult = [];
            for(var key in ruleState) {
                const value = ruleState[key];
                if(value) {
                    rulesResult.push(key);
                }
            }
            setVoucher(prevState => ({
                ...prevState,
                rules: rulesResult
            }))
        }
    }, [ruleState, voucher.whitelist_flag, setVoucher]);

    return (
        <div>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            id="name"
                            label="Voucher Name"
                            value={voucher.name}
                            fullWidth
                            required
                            className={classes.textField}
                            onChange={handleNameChange}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="campaignsLabel">Campaigns</InputLabel>
                            <Select
                                id="campaigns"
                                value={voucher.campaign_id}
                                onChange={handleCampaignChange}
                            >
                                {campaigns.map(campaign => (
                                    <MenuItem key={campaign.id} value={campaign.id}>{campaign.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="whitelistType">Whitelist Type</InputLabel>
                            <Select
                                id="whitelistType"
                                value={voucher.whitelist.type}
                                onChange={handleWhitelistChange}
                            >
                                {whitelistMap.map(whitelist => (
                                    <MenuItem key={whitelist.id} value={whitelist.id}>{whitelist.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="lockPeriod"
                            label="Lock period (minute)"
                            value={voucher.lock_period}
                            fullWidth
                            className={classes.textField}
                            onChange={handleLockPeriodChange}
                            onInput = {(e) =>{
                                let re = new RegExp("^[0-9]{0,7}$");
                                let value = e.target.value;
                                if(!re.test(value)) {
                                    e.target.value = voucher.lock_period;
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                <FormLabel>Rule</FormLabel>
                                <Grid container>
                                    {ruleMap.map(rule => (
                                        <Grid item xs={4} key={rule.id}> 
                                            <FormControlLabel
                                            control={<Checkbox checked={ruleState[rule.id]}  name={rule.id} disabled={disableRules(rule.id)} onClick={handleChange}/>}
                                            label={rule.value}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box>
                    <TextField
                        id="description"
                        label="Voucher Description"
                        fullWidth
                        required
                        value={voucher.description}
                        className={classes.textField}
                        onChange={handleDescriptionChange}
                    />
                </Box>
                <Box>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox checked={voucher.bulk_flag} onChange={handleBulkFlagChange} value={voucher.bulk_vlag} />
                            }
                            label="Bulk Upload"
                        />
                    </FormGroup>
                </Box>
                <Button color="primary" variant="contained" fullWidth={true} disabled={disableButton()} onClick={handleNextClicked}>Next</Button>
            </Box>
        </div>
    );
}