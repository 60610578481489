import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useDispatch, useSelector } from "react-redux";
import { getVoucherBulkRequest } from "../../redux/actions/getVoucherBulk";
import { updateVoucherBulkRequest } from "../../redux/actions/updateBulkVoucher";

import VoucherBulkTable from "./VoucherBulkTable";
import { Redirect } from "react-router-dom";
import ErrorSnackBar from "../Utilities/ErrorSnackBar";

const cleanFilter = {
 campaign_id: "",
 campaign_name: "",
 status: "NONE",
 voucher_name: "",
};

const statusMap = [
 {
  id: "NONE",
  name: "None",
 },
 {
  id: "PENDING",
  name: "Pending",
 },
 {
  id: "REJECTED",
  name: "Rejected",
 },
];

const useStyle = makeStyles((theme) => ({
 createButton: {
  float: "right",
  marginRight: theme.spacing(1),
 },
 textField: {
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
 },
 formControl: {
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  minWidth: 120,
 },
}));
export default function VoucherBulk(props) {
 const classes = useStyle();

 const [initValue, setInitValue] = useState(true);
 const [filter, setFilter] = useState(cleanFilter);
 const [selectedVoucher, setSelectedVoucher] = useState([]);
 const [voucherCopy, setVoucherCopy] = useState([]);

 const [errorMessage, setErrorMessage] = useState("");
 const [errorOpen, setErrorOpen] = useState(false);

 const [dialogContent, setDialogContent] = useState({
  title: "",
  message: "",
 });
 const [remark, setRemark] = useState("");
 const [status, setStatus] = useState("");
 const [disableApproveReject, setDisableApproveReject] = useState(true);

 const [dialogOpen, setDialogOpen] = useState(false);

 const role = useSelector((state) => state.user.role);
 const isLoading = useSelector((state) => state.getBulkVoucher.isLoading);
 const loadSuccess = useSelector((state) => state.getBulkVoucher.meta.success);
 const loadMessage = useSelector((state) => state.getBulkVoucher.meta.errorMsg);

 const isUpdating = useSelector((state) => state.updateBulkVoucher.isLoading);
 const updateSuccess = useSelector(
  (state) => state.updateBulkVoucher.meta.success
 );
 const updateMessage = useSelector(
  (state) => state.updateBulkVoucher.meta.errorMsg
 );

 const vouchers = useSelector((state) => state.getBulkVoucher.vouchers);

 const dispatch = useDispatch();

 const handleCampaignIdChange = (e) => {
  const value = e.target.value;
  setFilter((prevState) => {
   return {
    ...prevState,
    campaign_id: value,
   };
  });
 };

 const handleCampaignNameChange = (e) => {
  const value = e.target.value;
  setFilter((prevState) => {
   return {
    ...prevState,
    campaign_name: value,
   };
  });
 };

 const handleVoucherNameChange = (e) => {
  const value = e.target.value;
  setFilter((prevState) => {
   return {
    ...prevState,
    voucher_name: value,
   };
  });
 };

 const handleStatusChange = (e) => {
  const value = e.target.value;
  setFilter((prevState) => ({ ...prevState, status: value }));
 };

 const handleRemarkChange = (e) => {
  const value = e.target.value;
  setRemark(value);
 };

 const handleApplyFilterButtonClicked = () => {
  setSelectedVoucher([]);
  setVoucherCopy([]);
  let filterRequest = {};

  if (filter.campaign_id) {
   filterRequest.campaign_id = filter.campaign_id;
  }

  if (filter.campaign_name) {
   filterRequest.campaign_name = filter.campaign_name;
  }

  if (filter.voucher_name) {
   filterRequest.voucher_name = filter.voucher_name;
  }

  if (filter.status !== "NONE") {
   filterRequest.status = filter.status;
  }
  dispatch(getVoucherBulkRequest(filterRequest));
 };

 const handleClearFilterButtonClicked = () => {
  setFilter(cleanFilter);
 };

 const handleDialogClose = () => {
  setDialogOpen(false);
  setDialogContent({
   title: "",
   message: "",
  });
  setStatus("");
  setRemark("");
 };

 const handleApproveButtonClicked = () => {
  setDialogContent({
   title: "Approve Confirmation",
   message: "Are you sure want to bulk appove voucher?",
  });
  setStatus("APPROVED");
  setDialogOpen(true);
 };

 const handleRejectButtonClicked = () => {
  setDialogContent({
   title: "Reject Confirmation",
   message: "Are you sure want to bulk reject voucher?",
  });
  setStatus("REJECTED");
  setDialogOpen(true);
 };

 const handleSelectChange = (selected, checked) => {
  if (checked) {
   setSelectedVoucher((prevState) => {
    return [...prevState, selected];
   });
  } else {
   let newSelectedVoucher = selectedVoucher.filter((voucher) => {
    return voucher.id !== selected.id;
   });
   setSelectedVoucher(newSelectedVoucher);
  }
 };

 const handleSelectAll = (e) => {
  const checked = e.target.checked;
  if (checked) {
   setSelectedVoucher(voucherCopy);
  } else {
   setSelectedVoucher([]);
  }
 };

 const isChecked = (voucher) => {
  let checkedVoucher = selectedVoucher.filter((selected) => {
   return voucher.id === selected.id;
  });
  return checkedVoucher.length > 0;
 };

 const createUpdateRequest = () => {
  let request = {
   vouchers: [],
   status: status,
   remark: remark,
  };

  for (let i = 0; i < selectedVoucher.length; i++) {
   request.vouchers.push(selectedVoucher[i].id);
  }

  return request;
 };

 const submitUpdate = () => {
  let request = createUpdateRequest();
  dispatch(updateVoucherBulkRequest(request));
 };

 const disableSubmit = () => {
  if (remark) {
   return false;
  }
  return true;
 };

 useEffect(() => {
  dispatch(getVoucherBulkRequest());
  setInitValue(false);
 }, [initValue, dispatch]);

 useEffect(() => {
  setVoucherCopy(vouchers);
 }, [vouchers]);

 useEffect(() => {
  if (selectedVoucher == null) {
   setDisableApproveReject(true);
   return;
  }
  setDisableApproveReject(selectedVoucher.length === 0);
 }, [selectedVoucher]);

 useEffect(() => {
  if (loadMessage !== "Success") {
   setErrorMessage(loadMessage);
  }
 }, [loadMessage]);

 useEffect(() => {
  if (updateMessage !== "Success") {
   setErrorMessage(updateMessage);
  }
 }, [updateMessage]);

 useEffect(() => {
  if (!isLoading && !loadSuccess && errorMessage) {
   setErrorOpen(true);
  }
 }, [loadSuccess, isLoading, errorMessage]);

 useEffect(() => {
  if (!isUpdating && !updateSuccess && errorMessage) {
   setErrorOpen(true);
  }
 }, [updateSuccess, isUpdating, errorMessage]);

 useEffect(() => {
  if (!isUpdating && updateSuccess) {
   setSelectedVoucher([]);
   setVoucherCopy([]);
   let filterRequest = {};

   if (filter.campaign_id) {
    filterRequest.campaign_id = filter.campaign_id;
   }

   if (filter.campaign_name) {
    filterRequest.campaign_name = filter.campaign_name;
   }

   if (filter.voucher_name) {
    filterRequest.voucher_name = filter.voucher_name;
   }

   if (filter.status !== "NONE") {
    filterRequest.status = filter.status;
   }
   dispatch(getVoucherBulkRequest(filterRequest));
   setDialogOpen(false);
  }
 }, [updateSuccess, isUpdating, dispatch, filter]);

 if (role === "CREATOR") {
  return <Redirect to="/"></Redirect>;
 }

 return (
  <React.Fragment>
   <Fade in={isLoading || isUpdating}>
    <LinearProgress />
   </Fade>
   <Box m={3}>
    <Grid container>
     <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
      <Typography variant="h5">Voucher List</Typography>
     </Grid>
     <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
      <Button
       className={classes.createButton}
       variant="contained"
       onClick={handleRejectButtonClicked}
       disabled={disableApproveReject}
      >
       Bulk Reject
      </Button>
      <Button
       className={classes.createButton}
       color="primary"
       variant="contained"
       onClick={handleApproveButtonClicked}
       disabled={disableApproveReject}
      >
       Bulk Approve
      </Button>
     </Grid>
    </Grid>
   </Box>
   <Box m={2}>
    <Grid container spacing={2}>
     <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
      <Paper elevation={2}>
       <Box m={2}>
        <Box>
         <Typography variant="subtitle1">Filter</Typography>
        </Box>
        <Box>
         <Grid container spacing={2}>
          <Grid item xs={12}>
           <TextField
            id="campaignId"
            label="Campaign ID"
            value={filter.campaign_id}
            fullWidth
            className={classes.textField}
            onChange={handleCampaignIdChange}
            type="number"
           />
          </Grid>
          <Grid item xs={12}>
           <TextField
            id="campaignName"
            label="Campaign Name"
            value={filter.campaign_name}
            fullWidth
            className={classes.textField}
            onChange={handleCampaignNameChange}
           />
          </Grid>
          <Grid item xs={12}>
           <TextField
            id="voucherName"
            label="Voucher Name"
            value={filter.voucher_name}
            fullWidth
            className={classes.textField}
            onChange={handleVoucherNameChange}
           />
          </Grid>
          <Grid item xs={12}>
           <Select
            id="campaigns"
            value={filter.status}
            onChange={handleStatusChange}
            fullWidth
           >
            {statusMap.map((status) => (
             <MenuItem key={status.id} value={status.id}>
              {status.name}
             </MenuItem>
            ))}
           </Select>
          </Grid>
          <Grid item xs={12}>
           <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleApplyFilterButtonClicked}
           >
            Apply Filter
           </Button>
          </Grid>
          <Grid item xs={12}>
           <Button
            variant="contained"
            fullWidth
            onClick={handleClearFilterButtonClicked}
           >
            Clear Filter
           </Button>
          </Grid>
         </Grid>
        </Box>
       </Box>
      </Paper>
     </Grid>

     <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
      <VoucherBulkTable
       handleSelectChange={handleSelectChange}
       vouchers={voucherCopy}
       isChecked={isChecked}
       handleSelectAll={handleSelectAll}
       selectedVoucher={selectedVoucher}
      ></VoucherBulkTable>
     </Grid>
    </Grid>
   </Box>
   <Dialog
    open={dialogOpen}
    onClose={handleDialogClose}
    aria-labelledby="form-dialog-title"
   >
    <DialogTitle id="form-dialog-title">{dialogContent.title}</DialogTitle>
    <DialogContent>
     <DialogContentText>{dialogContent.message}</DialogContentText>
     <TextField
      autoFocus
      margin="dense"
      id="remarks"
      label="Remarks"
      value={remark}
      fullWidth
      onChange={handleRemarkChange}
     />
    </DialogContent>
    <DialogActions>
     <Button onClick={submitUpdate} disabled={disableSubmit()} color="primary">
      Submit
     </Button>
     <Button onClick={handleDialogClose} color="secondary">
      Cancel
     </Button>
    </DialogActions>
   </Dialog>

   <ErrorSnackBar
    open={errorOpen}
    setOpen={setErrorOpen}
    hideDuration={3000}
    errorMessage={errorMessage}
   ></ErrorSnackBar>
  </React.Fragment>
 );
}
