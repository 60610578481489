import React, {useState, useEffect} from 'react';

import {useSelector, useDispatch} from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';

import ConsumptionCount from './Widget/ConsumptionCount';
import StatusCodeCountChart from './Widget/StatusCodeCountChart';
// import PieStatusCount from './Widget/PieStatusCount';

import {widgetGetUserWidgetListRequest} from '../../redux/actions/widgetGetUserWidgetList';

import {dashboardActiveVoucherCountRequest} from '../../redux/actions/dashboardActiveVoucherCount';
import {dashboardActiveCampaignCountRequest} from '../../redux/actions/dashboardActiveCampaignCount';
// import {dashboardCampaignStatusCountRequest} from '../../redux/actions/dashboardCampaignStatusCount';
// import {dashboardVoucherStatusCountRequest} from '../../redux/actions/dashboardVoucherStatusCount';

const statusDisplayMap = new Map();
statusDisplayMap.set('PENDING', {
  displayLabel: 'Pending',
  color: '#78909c',
  hoverColor: '#546e7a' 
});
statusDisplayMap.set('APPROVED', {
  displayLabel: 'Approved',
  color: '#43a047',
  hoverColor: '#66bb6a' 
});
statusDisplayMap.set('REJECTED', {
  displayLabel: 'Rejected',
  color: '#e53935',
  hoverColor: '#ef5350' 
});

const useStyle = makeStyles(theme => ({
  createButton: {
      float: 'right'
  }
}));
export default function Dashboard(props) {
  const classes = useStyle();

  const [initValue, setInitValue] = useState(true);
  const [widgetDisplayFlag, setWidgetDisplayFlag] = useState({
    active_campaign_count: false,
    active_voucher_count: false,
    campaign_status_count: false,
    voucher_status_count: false,
    consumption_count: false
  });

  const dispatch = useDispatch();

  const getUserWidgetListLoading = useSelector(state => state.widgetGetUserWidgetList.meta.isLoading);
  const userWidgetList = useSelector(state => state.widgetGetUserWidgetList.user_widgets);

  const activeCampaignCountLoading = useSelector(state => state.dashboardActiveCampaignCount.meta.isLoading);
  const activeCampaignCount = useSelector(state => state.dashboardActiveCampaignCount.count);

  const activeVoucherCountLoading = useSelector(state => state.dashboardActiveVoucherCount.meta.isLoading);
  const activeVoucherCount = useSelector(state => state.dashboardActiveVoucherCount.count);

  // const campaignStatusCountLoading = useSelector(state => state.dashboardCampaignStatusCount.meta.isLoading);
  // const campaignStatusCountData = useSelector(state => state.dashboardCampaignStatusCount.data);

  // const voucherStatusCountLoading = useSelector(state => state.dashboardVoucherStatusCount.meta.isLoading);
  // const voucherStatusCountData = useSelector(state => state.dashboardVoucherStatusCount.data);

  const fetchActiveVoucherCount = () => {
    dispatch(dashboardActiveVoucherCountRequest({}));
  }
  const fetchActiveCampaignCount = () => {
    dispatch(dashboardActiveCampaignCountRequest({}));
  }

  // const fetchCampaignStatusCount = () => {
  //   dispatch(dashboardCampaignStatusCountRequest({}));
  // }

  // const fetchVoucherStatusCount = () => {
  //   dispatch(dashboardVoucherStatusCountRequest({}));
  // }
  
  // const convertStatusData = (data, statusLabelColorMap) => {
  //   const resultData = {labels:[], datasets:[]};
  //   if(data && data.length > 0) {
  //     let dataSet = {
  //       data: [],
  //       backgroundColor: [],
  //       hoverBackgroundColor: []
  //     }
  //     for(let i = 0; i < data.length; i++) {
  //       const currentData = data[i];
  //       const code = currentData.code;
  //       const displayConfig = statusLabelColorMap.get(code);
  //       if(!displayConfig) continue;
  //       resultData.labels.push(displayConfig.displayLabel);
  //       dataSet.data.push(currentData.count);
  //       dataSet.backgroundColor.push(displayConfig.color);
  //       dataSet.hoverBackgroundColor.push(displayConfig.hoverColor);
  //     }
  //     resultData.datasets.push(dataSet);
  //   }
  //   return resultData;
  // }

  useEffect(() => {
    if(initValue) {
      setInitValue(false);
      dispatch(widgetGetUserWidgetListRequest({}));
    }
  }, [initValue, dispatch]);

  useEffect(() => {
    if(!getUserWidgetListLoading && userWidgetList && userWidgetList.length > 0) {
      let updatedFlag = {
        active_campaign_count: false,
        active_voucher_count: false,
        consumption_count: false,
        campaign_status_count: false,
        voucher_status_count: false
      }
      for(let i = 0; i < userWidgetList.length; i++) {
        let userWidget = userWidgetList[i];
        if(userWidget.widget_id === 1) {
          updatedFlag.active_campaign_count = true;
        } else if(userWidget.widget_id === 2) {
          updatedFlag.active_voucher_count = true;
        } else if(userWidget.widget_id === 3) {
          updatedFlag.consumption_count = true;
        }
        // } else if(userWidget.widget_id === 4) {
        //   updatedFlag.campaign_status_count = true;
        // } else if(userWidget.widget_id === 5) {
        //   updatedFlag.voucher_status_count = true;
        // }
      }
      setWidgetDisplayFlag(updatedFlag);
    }
  }, [userWidgetList, getUserWidgetListLoading]);

  return (
    <React.Fragment>
      <Fade in={getUserWidgetListLoading}>
        <LinearProgress />
      </Fade>
      <Box m={3}>
        <Grid container >
            <Grid item xs={8}>
            <Typography variant="h5">
                Dashboard
            </Typography>
            </Grid>
            <Grid item xs={4}>
                <div className={classes.createButton}>
                  <Button color="primary" variant="contained" href="/dashboard/edit">Manage Widget</Button>
                </div>
            </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container spacing={2}>
          {widgetDisplayFlag.active_campaign_count ? 
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <StatusCodeCountChart isFetchLoading={activeCampaignCountLoading}
              dataName='Active Campaign Count'
              dataCount={activeCampaignCount}
              fetchData={fetchActiveCampaignCount}
              ></StatusCodeCountChart>
          </Grid> : null}
          {widgetDisplayFlag.active_voucher_count ? 
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <StatusCodeCountChart isFetchLoading={activeVoucherCountLoading}
              dataName='Active Voucher Count'
              dataCount={activeVoucherCount}
              fetchData={fetchActiveVoucherCount}
              ></StatusCodeCountChart>
          </Grid> : null}
          {widgetDisplayFlag.consumption_count ? 
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <ConsumptionCount></ConsumptionCount>
          </Grid> : null}
          {/* {widgetDisplayFlag.campaign_status_count ? 
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <PieStatusCount 
              isFetchLoading={campaignStatusCountLoading}
              title='Campaign Status Count'
              data={campaignStatusCountData}
              fetchData={fetchCampaignStatusCount}
              convertData={convertStatusData}
              statusLabelColorMap={statusDisplayMap} ></PieStatusCount>
          </Grid> : null}
          {widgetDisplayFlag.voucher_status_count ? 
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <PieStatusCount 
              isFetchLoading={voucherStatusCountLoading}
              title='Voucher Status Count'
              data={voucherStatusCountData}
              fetchData={fetchVoucherStatusCount}
              convertData={convertStatusData}
              statusLabelColorMap={statusDisplayMap} ></PieStatusCount>
          </Grid> : null} */}
        </Grid>
      </Box>
    </React.Fragment>
    
  );
}