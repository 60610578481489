import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import dateFormat from 'dateformat';

export default function VoucherBulkTableItem(props) {
    const {voucher, handleSelectChange, isChecked} = props;

    const handleSelect = (e) => {
        const checked = e.target.checked;
        handleSelectChange(voucher, checked);
    }

    return (<TableRow>
        <TableCell><Checkbox
        checked={isChecked(voucher)}
        onChange={handleSelect}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      /></TableCell>
        <TableCell>{voucher.campaign_id}</TableCell>
        <TableCell>{voucher.campaign_name}</TableCell>
        <TableCell>{dateFormat(voucher.campaign_effective_from, 'yyyy-mm-dd HH:MM:ss')}</TableCell>
        <TableCell>{dateFormat(voucher.campaign_effective_to, 'yyyy-mm-dd HH:MM:ss')}</TableCell>
        <TableCell>{voucher.voucher_name}</TableCell>
        <TableCell>{voucher.voucher_code}</TableCell>
        <TableCell>{voucher.voucher_status}</TableCell>
      </TableRow>);
}