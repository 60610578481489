import {USER_ACTION_TYPE as ACTION} from './actionTypes';

export const userLoginSuccess = (data) =>  {
  return {
    type: ACTION.USER_LOGIN_SUCCESS,
    data
  }
}

export const userLogoutSuccess = (data) =>  {
  return {
    type: ACTION.USER_LOGOUT_SUCCESS,
    data
  }
}