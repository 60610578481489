import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Age from './RuleComponent/Age';
import DateRange from './RuleComponent/DateRange';
import Region from './RuleComponent/Region';
import AutoDebit from './RuleComponent/AutoDebit';
import ContractPeriod from './RuleComponent/ContractPeriod';

import VoucherCode from './VoucherDetailComponent/VoucherCode';
import VoucherCodeWithoutGenerate from './VoucherDetailComponent/VoucherCodeWithoutGenerate';
import VoucherType from './VoucherDetailComponent/VoucherType';
import VoucherMechanic from './VoucherDetailComponent/VoucherMechanic';
import VoucherCsvUpload from './VoucherDetailComponent/VoucherCsvUpload';
import VoucherWhitelistUpload from './VoucherDetailComponent/VoucherWhitelistUpload';

import validator from 'validator';

export default function CreateVoucherDetail(props) {
    const {setStage, classes, setVoucher, voucher, generateVoucherCode, generatedVoucherCode, submitCreateVoucher, regionState, setRegionState, currentVoucherTypeMap, changeVoucherTypeMap} = props;
    const handleBackClicked = (e) => {
        setStage(1);
    }

    const handleActivationStartDateChanged = (value) => {
        setVoucher(prevState => {
            return {
                ...prevState,
                activation_date: {
                    ...prevState.activation_date,
                    start_date: value
                }
            }
        });
    }

    const handleActivationEndDateChanged = (value) => {
        setVoucher(prevState => {
            return {
                ...prevState,
                activation_date: {
                    ...prevState.activation_date,
                    end_date: value
                }
            }
        });
    }

    const handleBulkUpload = (dataArray, fileInfo) => {
        const uploadIdList = [];
        if(voucher.bulk_type === 'MERCHANT') {
            if(dataArray) {
                dataArray.forEach(data => {
                    if(data.length > 0 && data[0]) {
                        let col1 = data[0].trim();
                        if(col1) {
                            let row = {
                                code: col1,
                                name: null
                            }
                            if(data.length > 1 && data[1]) {
                                let col2 = data[1].trim();
                                if(col2) {
                                    row.name = col2;
                                }
                            }
                            uploadIdList.push(row);
                        }
                    }
                })
            }
        } else {
            if(dataArray) {
                dataArray.forEach(data => {
                    if(data.length > 0 && data[0]) {
                        let col1 = data[0].trim();
                        if(col1) {
                            let row = {
                                id: col1,
                                name: null
                            }
                            if(data.length > 1 && data[1]) {
                                let col2 = data[1].trim();
                                if(col2) {
                                    row.name = col2;
                                }
                            }
                            uploadIdList.push(row);
                        }
                    }
                })
            }
        }

        setVoucher(prevState => {
            return {
                ...prevState, 
                merchant_data: [],
                bulk_list: uploadIdList,
                bulk_filename: fileInfo.name
            }
        })
    }

    const handleMerchantIDChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => ({...prevState, merchant_id: value}))
    }

    const handleWhitelistUpload = (dataArray, fileInfo) => {
        const whitelistValues = []
        if(dataArray) {
            dataArray.forEach(data => {
                if(data.length > 0 && data[0]) {
                    let val = data[0].trim();
                    if(val) {
                        whitelistValues.push(data[0]);
                    }
                }
            });
        }

        setVoucher(prevState => {
            return {
                ...prevState,
                whitelist: {
                    ...prevState.whitelist,
                    values: whitelistValues
                },
                whitelist_filename: fileInfo.name
            }
        })
    }

    const validateAge = () => {
        if(
            !validator.isEmpty(voucher.age.min_age) && 
            !validator.isEmpty(voucher.age.max_age) &&
            validator.isNumeric(voucher.age.min_age) &&
            validator.isNumeric(voucher.age.max_age)
        ) {
            const minAge = parseInt(voucher.age.min_age);
            const maxAge = parseInt(voucher.age.max_age);
            return minAge <= maxAge;
        }
        return false;
    }

    const validateActivationDate = () => {
        return voucher.activation_date.start_date < voucher.activation_date.end_date;
    }

    const validateVoucherCode = () => {
        if(voucher.bulk_type && (voucher.bulk_type === 'MERCHANT' || voucher.bulk_type === 'STAFFID')) {
            return true;
        } else if(voucher.random) {
            return (
                (!validator.isEmpty(voucher.code_template.prefix) || !validator.isEmpty(voucher.code_template.suffix)) &&
                !validator.isEmpty(voucher.code_template.random_type)
            )
        } else {
            return !validator.isEmpty(voucher.fixed_voucher_code);
        }
    }

    const validateBulkSubmit = () => {
        if(!voucher.bulk_flag) return true;
        if(voucher.bulk_type) {
            return voucher.bulk_list.length > 0;
        } else {
            return true;
        }
    }

    const validateVoucherType = () => {
        if(!validator.isEmpty(voucher.type)) {
            if(voucher.type === 'SINGLEUSE' || voucher.type === 'RANDOM') {
                return true;
            } else if(voucher.type === 'QUANTITY') {
                return (!validator.isEmpty(voucher.quantity_limit) && validator.isNumeric(voucher.quantity_limit));
            } else if(voucher.type === 'AMOUNT') {
                return (!validator.isEmpty(voucher.amount_limit) && validator.isNumeric(voucher.amount_limit));
            } else if(voucher.type === 'REFERRAL') {
                return true;
            }
        }
        return false;
    }

    const validateVoucherMechanic = () => {
        if(!validator.isEmpty(voucher.mechanic)) {
            if(voucher.mechanic === 'PERCENTAGE') {
                return (!validator.isEmpty(voucher.discount_percentage) && validator.isNumeric(voucher.discount_percentage)) &&
                (!validator.isEmpty(voucher.max_discount_value) && validator.isNumeric(voucher.max_discount_value));
            } else if(voucher.mechanic === 'AMOUNT') {
                return (!validator.isEmpty(voucher.discount_value) && validator.isNumeric(voucher.discount_value));
            } else if(voucher.mechanic === 'NONE') {
                return true;
            }
        } else if(voucher.type === 'REFERRAL') {
            return true;
        }
        return false;
    }

    const disableSubmitButton = () => {
        const voucherCodeValid = validateVoucherCode();
        const voucherTypeValid = validateVoucherType();
        const voucherMechanicValid = validateVoucherMechanic();
        const bulkSubmitValid = validateBulkSubmit();
        if(voucherCodeValid && bulkSubmitValid && voucherTypeValid && voucherMechanicValid) {
            if(voucher.random && !generatedVoucherCode && !voucher.bulk_flag) {
                return true;
            }
            if(!voucher.random && !voucher.fixed_voucher_code) {
                return true;
            }
            let valid = true;
            voucher.rules.forEach(rule => {
                if(rule === 'NORULES') return false;
                if(rule === 'AGE') {
                    valid = valid && validateAge();
                }
                if(rule === 'ACTIVATION_DATE') {
                    valid = valid && validateActivationDate();
                }
            });
            return !valid;
        }
        return true;
    }

    return (
        <div>
            <Box m={3}>
                {voucher.rules.includes('NORULES') ? null : 
                <div>
                    <Typography variant="h6">Rule Properties</Typography>
                    <Paper> 
                        <Box p={1} m={2}>
                            {voucher.rules.includes('AGE') ? <Age classes={classes} setVoucher={setVoucher} voucher={voucher}></Age> : null}
                            {voucher.rules.includes('ACTIVATION_DATE') ? 
                                <DateRange 
                                    classes={classes} 
                                    title="Activation Date" 
                                    startDate={voucher.activation_date.start_date}
                                    endDate={voucher.activation_date.end_date}
                                    handleStartDateChanged={handleActivationStartDateChanged}
                                    handleEndDateChanged={handleActivationEndDateChanged}
                                ></DateRange> : null
                            }
                            {voucher.rules.includes('REGION') ? 
                                <Region 
                                    classes={classes}  
                                    setVoucher={setVoucher} 
                                    voucher={voucher}
                                    regionState={regionState}
                                    setRegionState={setRegionState}
                                ></Region> : null
                            }
                            {voucher.rules.includes('AUTODEBIT_FLAG') ? 
                                <AutoDebit 
                                    classes={classes}  
                                    setVoucher={setVoucher} 
                                    voucher={voucher}
                                ></AutoDebit> : null
                            }
                            {voucher.rules.includes('CONTRACT_PERIOD') ? 
                                <ContractPeriod 
                                    classes={classes}  
                                    setVoucher={setVoucher} 
                                    voucher={voucher}
                                ></ContractPeriod> : null
                            }
                        </Box>
                    </Paper>
                </div>
                }

                <Typography variant="h6">Voucher Properties</Typography>

                <Paper>
                    <Box p={1} m={2}>
                        {voucher.bulk_flag ? 
                                <VoucherCsvUpload 
                                    classes={classes} 
                                    voucher={voucher} 
                                    setVoucher={setVoucher} 
                                    handleBulkUpload={handleBulkUpload} 
                                    handleMerchantIDChange={handleMerchantIDChange}
                                    changeVoucherTypeMap={changeVoucherTypeMap}
                                >
                                </VoucherCsvUpload> 
                            : 
                            <VoucherCode 
                                classes={classes} 
                                setVoucher={setVoucher} 
                                voucher={voucher} 
                                generateVoucherCode={generateVoucherCode} 
                                validateVoucherCode={validateVoucherCode} 
                                generatedVoucherCode={generatedVoucherCode}>
                            </VoucherCode>
                        }
                        {voucher.bulk_flag && voucher.bulk_type !== 'MERCHANT' && voucher.bulk_type !== 'STAFFID' ? 
                            <VoucherCodeWithoutGenerate  
                                classes={classes} 
                                setVoucher={setVoucher} 
                                voucher={voucher} 
                            /> : null
                        }
                        {
                            voucher.whitelist_flag ?
                            <VoucherWhitelistUpload
                                classes={classes} 
                                voucher={voucher} 
                                setVoucher={setVoucher} 
                                handleWhitelistUpload={handleWhitelistUpload} 
                            >
                            </VoucherWhitelistUpload> : null
                        }
                        <VoucherType classes={classes} setVoucher={setVoucher} voucher={voucher} currentVoucherTypeMap={currentVoucherTypeMap}></VoucherType>
                        {voucher.type === 'REFERRAL' ? <div></div> : <VoucherMechanic classes={classes} setVoucher={setVoucher} voucher={voucher}></VoucherMechanic>}
                    </Box>
                </Paper>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button color="primary" variant="contained" fullWidth={true} disabled={disableSubmitButton()} onClick={submitCreateVoucher}>Submit</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color="secondary" variant="contained" fullWidth={true} onClick={handleBackClicked}>Back</Button>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}