import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import CreateCommonUser from './CreateCommonUser';
import CreateAdminUser from './CreateAdminUser';

import ErrorSnackBar from '../../Utilities/ErrorSnackBar';

import {useDispatch, useSelector} from 'react-redux';
import {userCreateRequest} from '../../../redux/actions/userCreate';

import validator from 'validator';

const roleMap = [
    {
        id: 'ADMIN',
        value: 'Admin'
    },
    {
        id: 'APPROVER',
        value: 'Approver'
    },
    {
        id: 'CREATOR',
        value: 'Creator'
    }
]

const useStyle = makeStyles(themes => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    }
}));
export default function CreateUser(props) {
    const classes = useStyle();
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState({
        name: '',
        ad_username: '',
        description: '',
        role: ''
    });

    const isLoading = useSelector(state => state.userCreate.isLoading);
    const meta = useSelector(state => state.userCreate.meta);
    const currentUser = useSelector(state => state.user);

    const dispatch = useDispatch();

    const handleNameChange = (e) => {
        const value = e.target.value;
        setUser(prevState => ({
            ...prevState, 
            name: value
        }));
    }

    const handleUsernameChange = (e) => {
        const value = e.target.value;
        setUser(prevState => ({
            ...prevState, 
            ad_username: value
        }));
    }

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setUser(prevState => ({
            ...prevState, 
            description: value
        }));
    }

    const handleRoleChange = (e) => {
        const value = e.target.value;
        setUser({
            name: '',
            ad_username: '',
            password: '',
            description: '',
            role: value
        });
    }

    const disableSubmit = () => {
        let notValid = validator.isEmpty(user.role) || validator.isEmpty(user.ad_username);
        if(!notValid) {
            if(user.role && user.role === 'ADMIN') {
                return validator.isEmpty(user.name);
            } else {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        if(meta && !meta.success && meta.errorMsg) {
            setOpen(true);
        }
    }, [meta]);

    const submitRequest = () => {
        let request = {
            name: user.name,
            ad_username: user.ad_username,
            role: user.role
        };

        if(user.description) {
            request.description = user.description;
        }

        dispatch(userCreateRequest(request));
    }

    if(meta.success) {
        return (<Redirect to="/users"></Redirect>);
    }

    return currentUser.role !=='ADMIN' ? <Redirect to="/dashboard"></Redirect> : (
        <div>
            <Fade in={isLoading}>
                <LinearProgress />
            </Fade>
            <Box m={3}>
                <Typography variant="h5">
                    Create User
                </Typography>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="roleLabel">Role</InputLabel>
                            <Select
                                id="role"
                                value={user.role}
                                onChange={handleRoleChange}
                            >
                                {roleMap.map(role => (
                                    <MenuItem key={role.id} value={role.id}>{role.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            {user.role && user.role !== 'ADMIN' ? <CreateCommonUser
                classes={classes}
                user={user}
                handleNameChange={handleNameChange}
                handleUsernameChange={handleUsernameChange}
                handleDescriptionChange={handleDescriptionChange}
            ></CreateCommonUser> : null}
            {user.role && user.role === 'ADMIN' ? 
            <CreateAdminUser
                classes={classes}
                user={user}
                handleNameChange={handleNameChange}
                handleUsernameChange={handleUsernameChange}
                handleDescriptionChange={handleDescriptionChange}>

            </CreateAdminUser> : null}
            {user.role ? 
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button color="primary" variant="contained" fullWidth={true} disabled={disableSubmit()} onClick={submitRequest}>Create</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color="secondary" variant="contained" fullWidth={true} href="/users">Back</Button>
                    </Grid>
                </Grid>
            </Box> : null}
            <ErrorSnackBar
                open={open}
                setOpen={setOpen}
                hideDuration={3000}
                errorMessage={meta.errorMsg}
            >
            </ErrorSnackBar>
        </div>
    );
}