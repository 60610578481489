import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {voucherListSuccess, voucherListFailed} from '../actions/voucherList';
import { VOUCHER_LIST_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* voucherList({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.VOUCHER, data);
    if(responseData.status === 200){
      yield put(voucherListSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(voucherListFailed(responseData));
    }
  } catch (error) {
    yield put(voucherListFailed(error));
  }
}
  
function* watchVoucherList() {
  yield takeLatest(ACTION.VOUCHER_LIST_REQUEST, voucherList);
}

export default function* root() {
  yield fork(watchVoucherList);
}
  