import {AUTH_ACTION_TYPE as ACTION} from './actionTypes';

export const loginRequest = (data) =>  {
  return {
    type: ACTION.LOGIN_REQUEST,
    data
  }
}

export const loginSuccess = (response) =>  {
  return {
    type: ACTION.LOGIN_SUCCESS,
    response
  }
}

export const loginFailed = (error) =>  {
  return {
    type: ACTION.LOGIN_FAILED,
    error
  }
}

export const logoutRequest = (data) =>  {
  return {
    type: ACTION.LOGOUT_REQUEST,
    data
  }
}

export const logoutSuccess = (response) =>  {
  return {
    type: ACTION.LOGOUT_SUCCESS,
    response
  }
}

export const logoutFailed = (error) =>  {
  return {
    type: ACTION.LOGOUT_FAILED,
    error
  }
}