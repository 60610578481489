import React from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const mechanicMap = [
    {
        id: 'PERCENTAGE',
        value: 'Percentage'
    },
    {
        id: 'AMOUNT',
        value: 'Amount'
    },
    {
        id: 'NONE',
        value: 'None'
    }
]

export default function VoucherMechanic(props) {

    const {classes, voucher, setVoucher} = props;

    const handleVoucherMechanicChange = (e) => {
        const value = e.target.value;
        const prevValue = voucher.mechanic;

        if(value !== prevValue) {
            setVoucher(prevState => {
                return {
                    ...prevState,
                    mechanic: value,
                    discount_value: '',
                    discount_percentage: '',
                    max_discount_value: ''
                }
            });
        }
    }

    const handleDiscountValueChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                discount_value: value
            }
        });
    }
    
    const handleDiscountPercentageChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                discount_percentage: value
            }
        });
    }

    const handleMaxDiscountValueChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                max_discount_value: value
            }
        });
    }

    const amountMechanic = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box>
                        <TextField
                            id="discountValue"
                            label="Discount value"
                            value={voucher.discount_value}
                            fullWidth
                            required
                            className={classes.textField}
                            onChange={handleDiscountValueChange}
                        />
                    </Box>
                </Grid>
            </Grid>
        );
    }

    const percentageMechanic = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box>
                        <TextField
                            id="discountPercentage"
                            label="Discount percentage"
                            value={voucher.discount_percentage}
                            fullWidth
                            required
                            className={classes.textField}
                            onChange={handleDiscountPercentageChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <TextField
                            id="maxDiscountValue"
                            label="Max discount value"
                            value={voucher.max_discount_value}
                            fullWidth
                            required
                            className={classes.textField}
                            onChange={handleMaxDiscountValueChange}
                        />
                    </Box>
                </Grid>
            </Grid>
        );
    }

    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Voucher Mechanic
                </Typography>
            </Box>
            <Box m={3}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="voucherMechanic">Voucher Mechanic</InputLabel>
                                <Select
                                    id="voucherMechanic"
                                    value={voucher.mechanic}
                                    onChange={handleVoucherMechanicChange}
                                >
                                    {mechanicMap.map(mechanic => (
                                        <MenuItem key={mechanic.id} value={mechanic.id}>{mechanic.value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {voucher.mechanic === 'AMOUNT' ? amountMechanic() : null}
                    {voucher.mechanic === 'PERCENTAGE' ? percentageMechanic() : null}
                </Box>
            </Box>
        </div>
    );
}