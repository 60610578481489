import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {userUpdateSuccess, userUpdateFailed} from '../actions/userUpdate';
import { USER_UPDATE_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doPut } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* updateUser({data}) {
  try {
    const responseData = yield call(doPut, ENDPOINT.USER + '/' + data.id, data);
    if(responseData.status === 200){
      yield put(userUpdateSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(userUpdateFailed(responseData));
    }
  } catch (error) {
    yield put(userUpdateFailed(error));
  }
}
  
function* watchUpdateUser(){
  yield takeLatest(ACTION.USER_UPDATE_REQUEST, updateUser);
}

export default function* root() {
  yield fork(watchUpdateUser);
}