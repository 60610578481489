import {WIDGET_GET_USER_WIDGET_LIST_ACTION_TYPE as ACTION} from './actionTypes';

export const widgetGetUserWidgetListRequest = (data) =>  {
    return {
        type: ACTION.WIDGET_GET_USER_WIDGET_LIST_REQUEST,
        data
    }
}

export const widgetGetUserWidgetListSuccess = (response) =>  {
    return {
        type: ACTION.WIDGET_GET_USER_WIDGET_LIST_SUCCESS,
        response
    }
  }

export const widgetGetUserWidgetListFailed = (error) =>  {
    return {
        type: ACTION.WIDGET_GET_USER_WIDGET_LIST_FAILED,
        error
    }
}