import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {dashboardActiveCampaignCountSuccess, dashboardActiveCampaignCountFailed} from '../actions/dashboardActiveCampaignCount';
import { DASHBOARD_ACTIVE_CAMPAIGN_COUNT_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* dashboardActiveCampaignCount({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.DASHBOARD + '/active-campaign/count', null);
    if(responseData.status === 200){
      yield put(dashboardActiveCampaignCountSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(dashboardActiveCampaignCountFailed(responseData));
    }
  } catch (error) {
    yield put(dashboardActiveCampaignCountFailed(error));
  }
}
  
function* watchDashboardActiveCampaignCount() {
  yield takeLatest(ACTION.DASHBOARD_ACTIVE_CAMPAIGN_COUNT_REQUEST, dashboardActiveCampaignCount);
}

export default function* root() {
  yield fork(watchDashboardActiveCampaignCount);
}