import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {getAvailableVoucherCodeSuccess, getAvailableVoucherCodeFailed} from '../actions/availableVoucherCode';
import { GET_AVAILABLE_VOUCHER_CODE_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doPost } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* availableVoucherCode({data}) {
  try {
    const responseData = yield call(doPost, ENDPOINT.VOUCHER_CODE_GENERATOR, data);
    if(responseData.status === 200){
      yield put(getAvailableVoucherCodeSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(getAvailableVoucherCodeFailed(responseData));
    }
  } catch (error) {
    yield put(getAvailableVoucherCodeFailed(error));
  }
}
  
function* watchAvailableVoucherCode(){
  yield takeLatest(ACTION.GET_AVAILABLE_VOUCHER_CODE_REQUEST, availableVoucherCode);
}

export default function* root() {
  yield fork(watchAvailableVoucherCode);
}