import {compose, applyMiddleware, createStore} from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import rootSaga from '../sagas';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

const isDebuggingInChrome = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && !!window.navigator.userAgent;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware();

let store;
let middlewares;

if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    predicate: (getState, action) => isDebuggingInChrome,
    collapsed: true,
    duration: true,
    diff: true,
  });
  middlewares = [ sagaMiddleware, logger ]
}else{
  middlewares = [ sagaMiddleware ]
}

const enhancers = [
  applyMiddleware(...middlewares)
]

const storeConfig = () => {
  store = createStore(persistedReducer, undefined, compose(...enhancers))
  let persistor = persistStore(store, null, ()=>{
    console.log(store.getState());
  });
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}

export const getStore = () => store;
export default storeConfig;