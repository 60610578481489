import {VOUCHER_CREATE_ACTION_TYPE as ACTION} from './actionTypes';

export const createVoucherRequest = (data) =>  {
    return {
        type: ACTION.VOUCHER_CREATE_REQUEST,
        data
    }
}

export const createVoucherSuccess = (response) =>  {
    return {
        type: ACTION.VOUCHER_CREATE_SUCCESS,
        response
    }
  }

export const createVoucherFailed = (error) =>  {
    return {
        type: ACTION.VOUCHER_CREATE_FAILED,
        error
    }
}