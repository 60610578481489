import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {logoutSuccess, logoutFailed} from '../actions/auth';
import {userLogoutSuccess} from '../actions/user';
import { AUTH_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doPost } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';

export function* logout({data}) {
  try {
    const responseData = yield call(doPost, ENDPOINT.LOGOUT, data);
    if(responseData.status === 200){
      yield put(logoutSuccess(responseData));
      yield put(userLogoutSuccess({
        name: '',
        token: '',
        authenticated: false
      }));
    } else {
      yield put(logoutFailed(responseData));
      yield put(userLogoutSuccess({
        name: '',
        token: '',
        authenticated: false
      }));
    }
  } catch (error) {
    yield put(logoutFailed(error));
    yield put(userLogoutSuccess({
      name: '',
      token: '',
      authenticated: false
    }));
  }
}
  
function* watchLogout(){
  yield takeLatest(ACTION.LOGOUT_REQUEST, logout);
}

export default function* root() {
  yield fork(watchLogout);
}
  