import {CAMPAIGN_CREATE_ACTION_TYPE as ACTION} from './actionTypes';

export const createCampaignRequest = (data) =>  {
    return {
        type: ACTION.CAMPAIGN_CREATE_REQUEST,
        data
    }
}

export const createCampaignSuccess = (response) =>  {
    return {
        type: ACTION.CAMPAIGN_CREATE_SUCCESS,
        response
    }
  }

export const createCampaignFailed = (error) =>  {
    return {
        type: ACTION.CAMPAIGN_CREATE_FAILED,
        error
    }
}