import React from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function VoucherType(props) {
    const {classes, voucher, setVoucher, currentVoucherTypeMap} = props;

    const handleVoucherTypeChange = (e) => {
        const value = e.target.value;
        const prevValue = voucher.type;

        if(value !== prevValue) {
            setVoucher(prevState => {
                return {
                    ...prevState,
                    type: value,
                    amount_limit: '',
                    quantity_limit: ''
                }
            });
        }
    }

    const typeChangeDisabled = () => {
        return voucher.bulk_type === 'STAFFID' || voucher.bulk_type === 'IC'
    } 

    const handleAmountLimitChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                amount_limit: value
            }
        });
    }
    
    const handleQuantityLimitChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                quantity_limit: value
            }
        });
    }

    const quantityLimitType = () => {
        return (
            <div>
                <Box>
                    <TextField
                        id="quantityLimit"
                        label="Quantity limit"
                        value={voucher.quantity_limit}
                        fullWidth
                        required
                        className={classes.textField}
                        onChange={handleQuantityLimitChange}
                    />
                </Box>
            </div>
        );
    }

    const amountLimitType = () => {
        return (
            <div>
                <Box>
                    <TextField
                        id="amountLimit"
                        label="Amount limit"
                        value={voucher.amount_limit}
                        fullWidth
                        required
                        className={classes.textField}
                        onChange={handleAmountLimitChange}
                    />
                </Box>
            </div>
        );
    }

    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Voucher Type
                </Typography>
            </Box>
            <Box m={3}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="voucherType">Voucher Type</InputLabel>
                                <Select
                                    id="voucherType"
                                    value={voucher.type}
                                    onChange={handleVoucherTypeChange}
                                    disabled={typeChangeDisabled()}
                                >
                                    {currentVoucherTypeMap.map(voucherType => (
                                        <MenuItem key={voucherType.id} value={voucherType.id}>{voucherType.value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            {voucher.type === 'QUANTITY' ? quantityLimitType() : null}
                            {voucher.type === 'AMOUNT' ? amountLimitType() : null}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}