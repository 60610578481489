import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import theme from 'theme';

import {DateTimePicker}  from '@material-ui/pickers';
import * as moment from 'moment';

import {useDispatch, useSelector} from 'react-redux';


import {getCampaignRequest} from '../../../redux/actions/getCampaign';
import {updateCampaignRequest} from '../../../redux/actions/updateCampaign';

import ErrorSnackBar from '../../Utilities/ErrorSnackBar';
import validator from 'validator';

const useStyle = makeStyles(themes => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));
export default function EditCampaign({match}) {
    const classes = useStyle();
    const {
        params: { campaignId },
      } = match;
    
    const [actionState, setActionState] = useState(0);
    const [campaign, setCampaign] = useState({
        id: campaignId,
        name: 'Campaign Details',
        description: '',
        startDate: moment().seconds(0).millisecond(0),
        endDate: moment().seconds(0).millisecond(0),
        remark: '',
        status: 'PENDING'
    });

    const statusColor = () => {
        if(campaign) {
            if(campaign.status === 'APPROVED') {
                return 'secondary'; 
            } else if (campaign.status === 'REJECTED') {
                return 'error';
            }
        }

        return 'textSecondary';
    }

    const [open, setOpen] = useState(false);
    const [errorShown, setErrorShown] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const isGetCampaignLoading = useSelector(state => state.getCampaign.isLoading);
    const getCampaignMeta = useSelector(state => state.getCampaign.meta);
    const getCampaignResult = useSelector(state => state.getCampaign.campaign);

    const isUpdateCampaignLoading = useSelector(state => state.updateCampaign.isLoading);
    const updateCampaignMeta = useSelector(state => state.updateCampaign.meta);

    const dispatch = useDispatch();

    const remarkDisabled = () => {
        return !campaign || campaign.status !== 'PENDING';
    }

    const updateDisabled = () => {
        if(isGetCampaignLoading || isUpdateCampaignLoading || !campaign || actionState !== 1) {
            return true;
        }
        return validator.isEmpty(campaign.name) || validator.isEmpty(campaign.description) || campaign.endDate <= campaign.startDate || campaign.status !== 'PENDING';
    }

    const fieldDisabled = () => {
        return !campaign || campaign.status !== 'PENDING';
    }

    const isLoading = () => {
        return isGetCampaignLoading || isUpdateCampaignLoading;
    }

    const handleNameChange = (e) => {
        const value = e.target.value;
        setCampaign(prevState => ({
            ...prevState, 
            name: value
        }));
    }

    const handleStartDateChange = (value) => {
        setCampaign(prevState => ({
            ...prevState, 
            startDate: value.toDate()
        }));
    }

    const handleEndDateChange = (value) => {
        setCampaign(prevState => ({
            ...prevState, 
            endDate: value.toDate()
        }));
    }

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setCampaign(prevState => ({
            ...prevState, 
            description: value
        }));
    }

    const handleRemarkChange = (e) => {
        const value = e.target.value;
        setCampaign(prevState => ({
            ...prevState, 
            remark: value
        }));
    }

    const doUpdate = (e) => {
        const updateCampaign = {
            id: campaign.id,
            data: {
                name: campaign.name,
                description: campaign.description,
                startDate: campaign.startDate,
                endDate: campaign.endDate,
                remark: campaign.remark,
                status: campaign.status
            }
        }
        dispatch(updateCampaignRequest(updateCampaign));
    }

    const handleApprove = (e) => {
        const updateCampaign = {
            id: campaign.id,
            data: {
                name: campaign.name,
                description: campaign.description,
                startDate: campaign.startDate,
                endDate: campaign.endDate,
                remark: campaign.remark,
                status: 'APPROVED'
            }
        }
        dispatch(updateCampaignRequest(updateCampaign));
    } 

    const handleReject = (e) => {
        const updateCampaign = {
            id: campaign.id,
            data: {
                name: campaign.name,
                description: campaign.description,
                startDate: campaign.startDate,
                endDate: campaign.endDate,
                remark: campaign.remark,
                status: 'REJECTED'
            }
        }
        dispatch(updateCampaignRequest(updateCampaign));
    } 

    const disableApproval = () => {
        if(actionState !== 0 && campaign) {
            const endDate = new Date(campaign.endDate);
            const currentDate = new Date();
            return campaign.status !== 'PENDING' || endDate < currentDate || !campaign.remark;
        }

        return true;
    }

    const hideApproval = () => {
        if(actionState !== 0 && campaign) {
            return campaign.status !== 'PENDING';
        }
        return true;
    }

    useEffect(() => {
        if(actionState === 0) {
            dispatch(getCampaignRequest({id: campaignId}));
        } 
    }, [actionState, dispatch, campaignId]);

    useEffect(() => {
        if(actionState === 0 && getCampaignResult) {
            setActionState(1);
            setCampaign(getCampaignResult);
        }
    }, [getCampaignMeta, getCampaignResult, actionState]);

    useEffect(() => {
        if(!errorShown && !isGetCampaignLoading && getCampaignMeta) {
            if(actionState === 1 && !getCampaignMeta.success && getCampaignMeta.errorMsg) {
                setErrorMessage(getCampaignMeta.errorMsg);
                setOpen(true);
                setErrorShown(true);
            } 
        }
    }, [errorShown, getCampaignMeta, isGetCampaignLoading, actionState]);

    useEffect(() => {
        if(!errorShown && !isUpdateCampaignLoading && updateCampaignMeta && updateCampaignMeta.errorMsg) {
            if(actionState === 1 && !updateCampaignMeta.success) {
                setErrorMessage(updateCampaignMeta.errorMsg);
                setOpen(true);
                setErrorShown(true);
            }
        }
    }, [errorShown, updateCampaignMeta, isUpdateCampaignLoading, actionState]);

    return updateCampaignMeta && updateCampaignMeta.success ? <Redirect to="/campaigns"></Redirect> : (
        <div>
            <Fade in={isLoading()}>
                <LinearProgress />
            </Fade>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant="h5">
                            {campaign.name}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" color={statusColor()}>
                            {campaign.status}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            id="campaignName"
                            label="Campaign Name"
                            value={campaign.name}
                            fullWidth
                            required
                            className={classes.textField}
                            disabled={fieldDisabled()}
                            onChange={handleNameChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimePicker
                            id="startDate"
                            label="Start Date"
                            value={campaign.startDate}
                            format="YYYY-MM-DD HH:mm"
                            ampm={false}
                            fullWidth
                            className={classes.textField}
                            disabled={fieldDisabled()}
                            onChange={handleStartDateChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimePicker
                            id="endDate"
                            label="End Date"
                            value={campaign.endDate}
                            format="YYYY-MM-DD HH:mm"
                            ampm={false}
                            fullWidth
                            className={classes.textField}
                            disabled={fieldDisabled()}
                            onChange={handleEndDateChange}
                        />
                    </Grid>
                </Grid>
                <Box>
                    <TextField
                        id="campaignDescription"
                        label="Campaign Description"
                        fullWidth
                        required
                        value={campaign.description}
                        className={classes.textField}
                        disabled={fieldDisabled()}
                        onChange={handleDescriptionChange}
                    />
                </Box>
                <Box>
                    {hideApproval() ? 
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    id="campaignRemark"
                                    label="Remarks"
                                    fullWidth
                                    required
                                    value={campaign.remark ? campaign.remark : ''}
                                    className={classes.textField}
                                    disabled={remarkDisabled()}
                                    onChange={handleRemarkChange}
                                />
                            </Grid>
                        </Grid>
                    :
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={8} lg={10} xl={10}>
                                <TextField
                                    id="campaignRemark"
                                    label="Remarks"
                                    fullWidth
                                    required
                                    value={campaign.remark ? campaign.remark : ''}
                                    className={classes.textField}
                                    disabled={remarkDisabled()}
                                    onChange={handleRemarkChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} lg={1} xl={1}>
                                <Button color="primary" variant="contained" fullWidth={true} onClick={handleApprove} className={classes.button} disabled={disableApproval()}>Approve</Button>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} lg={1} xl={1}>
                                <Button color="secondary" variant="contained" fullWidth={true} onClick={handleReject} className={classes.button} disabled={disableApproval()}>Reject</Button>
                            </Grid> 
                        </Grid>
                    }
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button color="primary" variant="contained" fullWidth={true} disabled={updateDisabled()} onClick={doUpdate}>Update</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button color="secondary" variant="contained" fullWidth={true} href="/campaigns">Back</Button>
                    </Grid>
                </Grid>
            </Box>
            <ErrorSnackBar
                open={open}
                setOpen={setOpen}
                hideDuration={3000}
                errorMessage={errorMessage}
            >
            </ErrorSnackBar>
        </div>
    );
}