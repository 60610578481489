import React from 'react';

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export default function ContractPeriod(props) {
    const {setVoucher, voucher} = props;

    const handleChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                contract_period: value 
            }
        });
    }

    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Contract Period Rule
                </Typography>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Input
                            id="contractPeriod"
                            value={voucher.contract_period}
                            onChange={handleChange}
                            type="number"
                            endAdornment={<InputAdornment position="end">Month(s)</InputAdornment>}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}