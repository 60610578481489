import {DASHBOARD_CONSUMPTION_COUNT_ACTION_TYPE as ACTION} from './actionTypes';

export const dashboardConsumptionCountRequest = (data) =>  {
    return {
        type: ACTION.DASHBOARD_CONSUMPTION_COUNT_REQUEST,
        data
    }
}

export const dashboardConsumptionCountSuccess = (response) =>  {
    return {
        type: ACTION.DASHBOARD_CONSUMPTION_COUNT_SUCCESS,
        response
    }
  }

export const dashboardConsumptionCountFailed = (error) =>  {
    return {
        type: ACTION.DASHBOARD_CONSUMPTION_COUNT_FAILED,
        error
    }
}