import {VOUCHER_UPDATE_INFO_ACTION_TYPE as ACTION} from './actionTypes';

export const voucherUpdateInfoRequest = (data) =>  {
    return {
        type: ACTION.VOUCHER_UPDATE_INFO_REQUEST,
        data
    }
}

export const voucherUpdateInfoSuccess = (response) =>  {
    return {
        type: ACTION.VOUCHER_UPDATE_INFO_SUCCESS,
        response
    }
  }

export const voucherUpdateInfoFailed = (error) =>  {
    return {
        type: ACTION.VOUCHER_UPDATE_INFO_FAILED,
        error
    }
}