import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    close: {
      padding: theme.spacing(0.5),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    margin: {
      margin: theme.spacing(1),
    }
  }));

export default function LoginPanel(props) {
    const {onUsernameChange, onPasswordChange, onClick, buttonDisabled, handleClose} = props;
    const classes = useStyles();
    return (
        <Container maxWidth="xs">
          <Box mt={30} border={1}>
            <Fade in={props.isLoading}>
              <LinearProgress />
            </Fade>
            <Box m={2}>
              <TextField id="username" name="username" label="Username" type="text" placeholder="Username" autoFocus={true} fullWidth={true} variant="outlined" onKeyUp={onUsernameChange}></TextField>
            </Box>
            <Box m={2}>
              <TextField id="password" name="password" label="Password" type="password" placeholder="Password" fullWidth={true} variant="outlined" onKeyUp={onPasswordChange}></TextField>
            </Box>
            <Box m={2}>
              <Button color="primary" variant="contained" fullWidth={true} disabled={buttonDisabled()} onClick={onClick}>Login</Button>
            </Box>
          </Box>
          <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={props.open}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <SnackbarContent
                  aria-describedby="client-snackbar"
                  message={
                    <span className={classes.message}>
                      {props.errorMsg}
                    </span>
                  }
                  action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose} className={classes.close}>
                      <CloseIcon />
                    </IconButton>,
                  ]}
                  className={classes.margin}
                />
            </Snackbar>
        </Container>
    );
}