import React, {useState, useEffect} from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {voucherGetIdRequest} from '../../../redux/actions/voucherGetId';
import {voucherUpdateInfoRequest} from '../../../redux/actions/voucherUpdateInfo';
import {voucherUpdateRuleRequest} from '../../../redux/actions/voucherUpdateRule';

import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import ErrorSnackBar from '../../Utilities/ErrorSnackBar'; 

import VoucherMechanic from '../Common/Detail/VoucherMechanic';
import VoucherType from '../Common/Detail/VoucherType';

import Age from '../Common/RuleComponent/Age';
import DateRange from '../Common/RuleComponent/DateRange';
import Region from '../Common/RuleComponent/Region';
import AutoDebit from '../Common/RuleComponent/AutoDebit';
import ContractPeriod from '../Common/RuleComponent/ContractPeriod';

import * as moment from 'moment';
import Whitelist from '../CreateVoucher/RuleComponent/Whitelist';
import { Redirect } from 'react-router-dom';

const useStyle = makeStyles(themes => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
    rightButton: {
        marginRight: theme.spacing(1),
        float: 'right'
    }
}));


const ruleMap = [
    {
        id: 'AGE',
        value: 'Age'
    },
    {
        id: 'ACTIVATION_DATE',
        value: 'Activation Date'
    },
    {
        id: 'REGION',
        value: 'Region'
    },
    {
        id: 'AUTODEBIT_FLAG',
        value: 'Auto Debit'
    },
    {
        id: 'CONTRACT_PERIOD',
        value: 'Contract Period'
    },
    {
        id: 'NORULES',
        value: 'No Rules'
    }
]

const whitelistMap = [
    {
        id: 'NONE',
        value: 'None'
    },
    {
        id: 'ACCOUNTNUMBER',
        value: 'Account Number'
    },
    {
        id: 'IDNUMBER',
        value: 'ID Number'
    },
    {
        id: 'SERVICEID',
        value: 'Service ID'
    }
]

const initRuleState = {
    'AGE': false,
    'ACTIVATION_DATE': false,
    'REGION': false,
    'AUTODEBIT_FLAG': false,
    'CONTRACT_PERIOD': false,
    'NORULES': false,
};

const ruleEnum = ['AGE', 'ACTIVATION_DATE', 'REGION', 'AUTODEBIT_FLAG', 'CONTRACT_PERIOD', 'NORULES'];
const regionEnum = ['NORTHERN', 'SOUTHERN', 'CENTRAL', 'EASTCOAST'];
const defaultVoucherData = {
    id: '',
    code: '',
    name: '',
    description: '',
    remark: '',
    campaign_id: '',
    status_id: '',
    rules: [],
    age: {
        min_age: '',
        max_age: ''
    },
    activation_date: {
        start_date: moment().seconds(0).millisecond(0),
        end_date: moment().seconds(0).millisecond(0)
    },
    whitelist: {
        type: 'NONE',
        values: []
    },
    region: [],
    type: '',
    amount_limit: '',
    quantity_limit: '',
    mechanic: '',
    discount_value: '',
    discount_percentage: '',
    max_discount_value: '',
    lock_period: '',
    auto_debit_flag: false,
    contract_period: ''
}
export default function EditVoucher({match}) {
    const classes = useStyle();
    const {
        params: { voucherId },
      } = match;

    const dispatch = useDispatch();

    const [initLoad, setInitLoad] = useState(true);
    const [voucherLoaded, setVoucherLoaded] = useState(false);
    const [voucher, setVoucher] = useState(defaultVoucherData);
    const [pristineVoucher, setPristineVoucher] = useState(defaultVoucherData);

    const [open, setOpen] = useState(false);
    const [updateSubmitted, setUpdateSubmitted] = useState(false);
    const [updateRuleSubmitted, setUpdateRuleSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [regionState, setRegionState] = useState({
        'NORTHERN': false,
        'SOUTHERN': false,
        'CENTRAL': false,
        'EASTCOAST': false
    });

    const isGetVoucherLoading = useSelector(state => state.voucherGetId.isLoading);
    const isUpdateVoucherInfoLoading = useSelector(state => state.voucherUpdateInfo.isLoading);
    const isUpdateVoucherInfoSuccess = useSelector(state => state.voucherUpdateInfo.meta.success);
    const isUpdateVoucherInfoMessage = useSelector(state => state.voucherUpdateInfo.meta.errorMsg);

    const isUpdateVoucherRuleLoading = useSelector(state => state.voucherUpdateRule.isLoading);
    const isUpdateVoucherRuleSuccess = useSelector(state => state.voucherUpdateRule.meta.success);
    const isUpdateVoucherRuleMessage = useSelector(state => state.voucherUpdateRule.meta.errorMsg);

    const voucherInfo = useSelector(state => state.voucherGetId.voucher);

    const isProgressing = () => {
        return isGetVoucherLoading || isUpdateVoucherInfoLoading || isUpdateVoucherRuleLoading; 
    }

    const [ruleState, setRuleState] = useState(initRuleState);
    const [ruleDisabled, setRuleDisabled] = useState(true);
    const [ruleChanged, setRuleChanged] = useState(false);

    const editDisabled = () => {
        return  (voucher.status_id && voucher.status_id !== 1) || !ruleDisabled;
    }

    const disableRules = (ruleId) => {
        return ruleDisabled || (voucher.whitelist.type && voucher.whitelist.type !== 'NONE') || (voucher.rules.length >= 3 && !ruleState[ruleId] && ruleId !== 'NORULES');
    }

    const handleNameChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState, 
                name: value
            }
        });
    }

    const updateMinAge = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            age: {
                ...prevState.age,
                min_age: value
            }
        }));
    }

    const updateMaxAge = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            age: {
                ...prevState.age,
                max_age: value
            }
        }));
    }

    const handleActivationStartDateChanged = (value) => {
        setVoucher(prevState => ({
                ...prevState,
                activation_date: {
                    ...prevState.activation_date,
                    start_date: value
                }
            }
        ));
    }

    const handleActivationEndDateChanged = (value) => {
        setVoucher(prevState => ({
                ...prevState,
                activation_date: {
                    ...prevState.activation_date,
                    end_date: value
                }
            }
        ));
    }

    const updateRegion = (regionSet) => {
        setVoucher(prevState => ({
                ...prevState,
                region: regionSet
            }
        ));
    }

    const updateAutoDebit = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            auto_debit_flag: value
        }));
    }

    const updateContractPeriod = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            contract_period: value
        }));
    }

    const handleRuleChange = (event) => {
        let targetName = event.target.name;
        let checked = event.target.checked;

        if(targetName === 'NORULES') {
            setRuleState({...initRuleState, NORULES: checked});
        } else {
            setRuleState(prevState => ({ ...prevState, NORULES: false, [targetName]: checked }));
        }

        setRuleChanged(true);
    };

    const handleLockPeriodChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState, 
                lock_period: value
            }
        });
    }

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState, 
                description: value
            }
        });
    }
    
    const handleRemarkChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState, 
                remark: value
            }
        });
    }

    const updateType = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            type: value
        }));
    }

    const updateQuantityLimit = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            quantity_limit: value
        }));
    }

    const updateAmountLimit = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            amount_limit: value
        }));
    }

    const updateMechanic = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            mechanic: value
        }));
    }

    const updateDiscountValue = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            discount_value: value
        }));
    }

    const updateDiscountPercentage = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            discount_percentage: value
        }));
    }

    const updateMaxDiscountValue = (value) => {
        setVoucher(prevState => ({
            ...prevState,
            max_discount_value: value
        }));
    }

    const handleWhitelistChange = (e) => {
        const value = e.target.value;
        let whitelistFlag = true;
        let rules = voucher.rules;
        if(value === 'NONE') {
            whitelistFlag = false;
        } else {
            setRuleState(initRuleState);
            rules = ['WL_' + value];
        }

        setVoucher(prevState => {
            return {
                ...prevState,
                rules: rules,
                whitelist: {
                    ...prevState.whitelist,
                    type: value
                },
                whitelist_flag: whitelistFlag
            }
        });
    }

    const handleWhitelistUpload = (dataArray, fileName) => {
        const whitelistValues = []
        if(dataArray) {
            dataArray.forEach(data => {
                if(data.length > 0) {
                    whitelistValues.push(data[0]);
                }
            })
        }

        setVoucher(prevState => {
            return {
                ...prevState,
                whitelist: {
                    ...prevState.whitelist,
                    values: whitelistValues
                }
            }
        })
    }

    const voucherMechanicValid = () => {
        if(voucher.mechanic === 'PERCENTAGE') {
            return voucher.discount_percentage && voucher.max_discount_value && voucher.discount_percentage > 0 && voucher.max_discount_value > 0;
        } else if(voucher.mechanic === 'AMOUNT') {
            return voucher.discount_value && voucher.discount_value >= 0;
        } else if(voucher.mechanic === 'NONE') {
            return true;
        }
        return false;
    }

    const voucherTypeValid = () => {
        if(voucher.type === 'SINGLEUSE' || voucher.type === 'REFERRAL' || voucher.type === 'RANDOM') {
            return true;
        } else if(voucher.type === 'QUANTITY') {
            return voucher.quantity_limit && voucher.quantity_limit > 0;
        } else if(voucher.type === 'AMOUNT') {
            return voucher.amount_limit && voucher.amount_limit > 0;
        } 

        return false;
    }

    const updateValid = () => {
        return voucher.name && voucher.description && voucherTypeValid() && voucherMechanicValid();
    }

    const constructUpdateInfoRequest = () => {
        return {
            id: voucher.id,
            data: {
                name: voucher.name,
                description: voucher.description,
                type: voucher.type,
                mechanic: voucher.mechanic,
                amount_limit: voucher.amount_limit,
                quantity_limit: voucher.quantity_limit,
                discount_value: voucher.discount_value,
                discount_percentage: voucher.discount_percentage,
                max_discount_value: voucher.max_discount_value,
                lock_period: voucher.lock_period
            }
        }
    }

    const submitUpdate = () => {
        let request = constructUpdateInfoRequest();
        dispatch(voucherUpdateInfoRequest(request));
        setUpdateSubmitted(true);
    }

    const ruleValid = () => {
        if(voucher.rules.length === 0) return false;
        if(voucher.rules.includes('NORULES')) return true;

        if(voucher.rules.includes('WL_ACCOUNTNUMBER') || voucher.rules.includes('WL_SERVICEID') || voucher.rules.includes('WL_IDNUMBER')) {
            if(!voucher.whitelist.type || voucher.whitelist.type === 'NONE' || !voucher.whitelist.values || voucher.whitelist.values.length === 0) return false; 
        }

        if(voucher.rules.includes('AGE')) {
            if(voucher.age.min_age >= voucher.age.max_age) return false;
        }
        if(voucher.rules.includes('ACTIVATION_DATE')) {
            if(!voucher.activation_date.start_date || !voucher.activation_date.end_date) return false;
            let startDate = moment(voucher.activation_date.start_date);
            let endDate = moment(voucher.activation_date.end_date);
            if(startDate >= endDate) return false;
        }
        if(voucher.rules.includes('REGION')) {
            if(!voucher.region || voucher.region.length === 0) return false;
        }
        if(voucher.rules.includes('CONTRACT_PERIOD')) {
            if(!voucher.contract_period || voucher.contract_period <= 0) return false;
        }

        return true;
    }

    const allocateRuleValues = (targetVoucher) => {
        let rulePayload  = {
            rules: targetVoucher.rules
        }

        if(targetVoucher.rules.includes('NORULES')) return rulePayload;

        if(targetVoucher.rules.includes('WL_ACCOUNTNUMBER') || targetVoucher.rules.includes('WL_SERVICEID') || targetVoucher.rules.includes('WL_IDNUMBER')) {
            rulePayload.whitelist = targetVoucher.whitelist;
            return rulePayload;
        }

        if(targetVoucher.rules.includes('AGE')) {
            rulePayload.age = targetVoucher.age
        }
        if(targetVoucher.rules.includes('ACTIVATION_DATE')) {
            let startDate = moment(targetVoucher.activation_date.start_date);
            let endDate = moment(targetVoucher.activation_date.end_date);
            rulePayload.activation_date = {
                start_date: startDate.toDate(),
                end_date: endDate.toDate()
            }
        }
        if(targetVoucher.rules.includes('REGION')) {
            rulePayload.region = targetVoucher.region;
        }
        if(targetVoucher.rules.includes('AUTODEBIT_FLAG')) {
            rulePayload.auto_debit_flag = targetVoucher.auto_debit_flag;
        }
        if(targetVoucher.rules.includes('CONTRACT_PERIOD')) {
            rulePayload.region = voucher.region;
        }

        return rulePayload;
    }

    const submitRuleUpdate = () => {
        let rulePayload = allocateRuleValues(voucher);
        let data = {
            id: voucher.id,
            data: rulePayload
        }

        dispatch(voucherUpdateRuleRequest(data));
        setUpdateRuleSubmitted(true);
    }

    const handleEditRuleClick = (e) => {
        setRuleDisabled(false);
    }

    const handleCancelEditRuleClick = (e) => {
        let newRuleState = {};
        for(let i = 0; i < ruleEnum.length; i++) {
            let key = ruleEnum[i];
            newRuleState[key] = pristineVoucher.rules.includes(key);
        }
        setRuleState(newRuleState);
        if(pristineVoucher.region && pristineVoucher.region.length > 0) {
            let newRegionState = {};
            for(let i = 0; i < regionEnum.length; i++) {
                let key = regionEnum[i];
                newRegionState[key] = pristineVoucher.region.includes(key);
            }
            setRegionState(newRegionState);
        }
        setVoucher(pristineVoucher);
        setRuleDisabled(true);
    }

    const handleSaveEditRuleClick = (e) => {
        submitRuleUpdate();
    }

    const handleUpdateVoucherClick = (e) => {
        submitUpdate();
    }

    useEffect(() => {
        if(initLoad) {
            dispatch(voucherGetIdRequest({id: voucherId}));
            setInitLoad(false);
        } 
    }, [initLoad, dispatch, voucherId]);

    useEffect(() => {
        if(ruleChanged) {
            const rulesResult = [];
            for(var key in ruleState) {
                const value = ruleState[key];
                if(value) {
                    rulesResult.push(key);
                }
            }
            setVoucher(prevState => ({
                ...prevState,
                rules: rulesResult
            }));
            setRuleChanged(false);
        }
    }, [ruleState, setVoucher, ruleChanged, setRuleChanged]);

    useEffect(() => {
        if(updateSubmitted && !isUpdateVoucherInfoLoading && isUpdateVoucherInfoMessage) {
            if(!isUpdateVoucherInfoSuccess) {
                setErrorMessage(isUpdateVoucherInfoMessage);
                setOpen(true);
            }

            setUpdateSubmitted(false);
        }
    }, [updateSubmitted, isUpdateVoucherInfoLoading, isUpdateVoucherInfoSuccess, isUpdateVoucherInfoMessage]);

    useEffect(() => {
        if(updateRuleSubmitted && !isUpdateVoucherRuleLoading && isUpdateVoucherRuleMessage) {
            if(!isUpdateVoucherRuleSuccess) {
                setErrorMessage(isUpdateVoucherRuleMessage);
                setOpen(true);
            } else {
                setInitLoad(true);
                setRuleDisabled(true);
            }

            setUpdateRuleSubmitted(false);
        }
    }, [updateRuleSubmitted, isUpdateVoucherRuleLoading, isUpdateVoucherRuleSuccess, isUpdateVoucherRuleMessage, voucher]);

    useEffect(() => {
        if(!voucherLoaded && voucherInfo) {
            setPristineVoucher(voucherInfo);
            setVoucher(voucherInfo);
            let infoRules = voucherInfo.rules;
            let newRuleState = {};
            for(let i = 0; i < ruleEnum.length; i++) {
                let key = ruleEnum[i];
                newRuleState[key] = infoRules.includes(key);
            }
            setRuleState(newRuleState);
            if(voucherInfo.region && voucherInfo.region.length > 0) {
                let newRegionState = {};
                for(let i = 0; i < regionEnum.length; i++) {
                    let key = regionEnum[i];
                    newRegionState[key] = voucherInfo.region.includes(key);
                }
                setRegionState(newRegionState);
            }
            setVoucherLoaded(true);
        }
    }, [setPristineVoucher, setVoucher, voucherInfo, voucherLoaded, setVoucherLoaded, ruleState, setRuleState]);

    return isUpdateVoucherInfoSuccess ? <Redirect to="/vouchers"></Redirect> : (
        <React.Fragment>
            <Fade in={isProgressing()}>
              <LinearProgress />
            </Fade>
            <Box m={3}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant="h5">
                            {voucher.name ? (voucher.name + '(' + voucher.code + ')') : 'Voucher'}
                        </Typography>
                    </Grid>
                    {(voucher.status_id && voucher.status_id !== 2) ? 
                    <Grid item xs={4}>
                        {voucher.status_id === 1 ?
                            ruleDisabled ? 
                                <div>
                                    <Button color="secondary" variant="contained" className={classes.rightButton} onClick={handleEditRuleClick}>Edit Rule</Button>
                                    <Button color="primary" variant="contained" className={classes.rightButton} onClick={handleUpdateVoucherClick} disabled={!updateValid()}>Update Voucher</Button>
                                </div> 
                                : 
                                <div>
                                    <Button variant="contained" className={classes.rightButton} onClick={handleCancelEditRuleClick}>Cancel</Button>
                                    <Button color="primary" variant="contained" className={classes.rightButton} onClick={handleSaveEditRuleClick} disabled={!ruleValid()}>Update Rule</Button>
                                </div>
                        : null
                    }
                    </Grid>
                    :null}
                </Grid>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                            id="name"
                            label="Voucher Name"
                            value={voucher.name}
                            fullWidth
                            required
                            className={classes.textField}
                            onChange={handleNameChange}
                            disabled={editDisabled()}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                            id="lockPeriod"
                            label="Lock period (minute)"
                            value={voucher.lock_period}
                            fullWidth
                            className={classes.textField}
                            onChange={handleLockPeriodChange}
                            disabled={editDisabled()}
                            onInput = {(e) =>{
                                let re = new RegExp("^[0-9]{0,7}$");
                                let value = e.target.value;
                                if(!re.test(value)) {
                                    e.target.value = voucher.lock_period;
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                            id="description"
                            label="Voucher Description"
                            fullWidth
                            required
                            value={voucher.description}
                            className={classes.textField}
                            onChange={handleDescriptionChange}
                            disabled={editDisabled()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <VoucherType
                            classes={classes}
                            type={voucher.type}
                            quantityLimit={voucher.quantity_limit}
                            amountLimit={voucher.amount_limit}
                            updateType={updateType}
                            updateQuantityLimit={updateQuantityLimit}
                            updateAmountLimit={updateAmountLimit}
                            disabled={editDisabled()}
                        ></VoucherType>
                    </Grid>
                    {voucher.type !== 'REFERRAL' ? <Grid item xs={12}>
                        <VoucherMechanic
                            classes={classes}
                            mechanic={voucher.mechanic}
                            discountValue={voucher.discount_value}
                            discountPercentage={voucher.discount_percentage}
                            maxDiscountValue={voucher.max_discount_value}
                            updateMechanic={updateMechanic}
                            updateDiscountValue={updateDiscountValue}
                            updateDiscountPercentage={updateDiscountPercentage}
                            updateMaxDiscountValue={updateMaxDiscountValue}
                            disabled={editDisabled()}
                        ></VoucherMechanic>
                    </Grid>: null}
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                    id="remark"
                                    label="Remark"
                                    fullWidth
                                    value={voucher.remark}
                                    className={classes.textField}
                                    onChange={handleRemarkChange}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl className={classes.formControl} fullWidth disabled={editDisabled()}>
                            <InputLabel id="whitelistType">Whitelist Type</InputLabel>
                            <Select
                                id="whitelistType"
                                value={voucher.whitelist.type}
                                onChange={handleWhitelistChange}
                                disabled={ruleDisabled}
                            >
                                {whitelistMap.map(whitelist => (
                                    <MenuItem key={whitelist.id} value={whitelist.id}>{whitelist.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                <FormLabel>Rule</FormLabel>
                                <Grid container>
                                    {ruleMap.map(rule => (
                                        <Grid item xs={6} sm={6} md={4} lg={3} key={rule.id}> 
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        checked={ruleState[rule.id]}  
                                                        name={rule.id} 
                                                        disabled={disableRules(rule.id)} 
                                                        onClick={handleRuleChange}/>
                                                }
                                                label={rule.value}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            {voucher.rules.includes('AGE') ? 
                <Age 
                    classes={classes} 
                    age={voucher.age} 
                    updateMinAge={updateMinAge} 
                    updateMaxAge={updateMaxAge} 
                    disabled={ruleDisabled}
                ></Age> : null
            }
            {voucher.rules.includes('ACTIVATION_DATE') ? 
                <DateRange 
                    classes={classes} 
                    title="Activation Date" 
                    startDate={voucher.activation_date.start_date}
                    endDate={voucher.activation_date.end_date}
                    updateStartDate={handleActivationStartDateChanged}
                    updateEndDate={handleActivationEndDateChanged}
                    disabled={ruleDisabled}
                ></DateRange> : null
            }
            {voucher.rules.includes('REGION') ? 
                <Region 
                    classes={classes}  
                    regionState={regionState}
                    setRegionState={setRegionState}
                    updateRegion={updateRegion}
                    disabled={ruleDisabled}
                ></Region> : null
            }
            {voucher.rules.includes('AUTODEBIT_FLAG') ? 
                <AutoDebit 
                    classes={classes}  
                    autoDebit={voucher.auto_debit_flag}
                    updateAutoDebit={updateAutoDebit}
                    disabled={ruleDisabled}
                ></AutoDebit> : null
            }
            {voucher.rules.includes('CONTRACT_PERIOD') ? 
                <ContractPeriod 
                    classes={classes}  
                    contractPeriod={voucher.contract_period}
                    updateContractPeriod={updateContractPeriod}
                    disabled={ruleDisabled}
                ></ContractPeriod> : null
            }
            {voucher.whitelist.type && voucher.whitelist.type !== 'NONE' ? 
                <Whitelist 
                    classes={classes}
                    whitelist={voucher.whitelist.values}
                    handleWhitelistUpload={handleWhitelistUpload}
                    disabled={ruleDisabled}
                ></Whitelist> : null
            }
            <ErrorSnackBar
                open={open}
                setOpen={setOpen}
                hideDuration={3000}
                errorMessage={errorMessage}
            ></ErrorSnackBar>
        </React.Fragment>
    );
    
}