import { USER_UPDATE_PASSWORD_ACTION_TYPE as ACTION } from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    meta: {
        success: false,
        errorCode: '',
        errorMsg: ''
    }
};

const userUpdatePassword = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.USER_UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ACTION.USER_UPDATE_PASSWORD_SUCCESS:
      return {
        isLoading: false,
        meta: {
          success: true,
          errorCode: action.response.data.errorCode,
          errorMsg: action.response.data.errorMsg
        }
      }
    case ACTION.USER_UPDATE_PASSWORD_FAILED:
      return {
        isLoading: false,
        meta: {
          success: false,
          errorCode: action.error.response.data.meta.errorCode,
          errorMsg: action.error.response.data.meta.errorMsg
        }
      }
    default:
      return initialState;
  }
}

export default userUpdatePassword;