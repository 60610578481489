import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';

import CampaignListTableItem from './CampaignListTableItem';
import SortableTableHead from '../../Table/SortableTableHead';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}));

const tableCellHeader = [
  {
    id: 'id',
    label: 'ID',
    sortable: true
  },
  {
    id: 'name',
    label: 'Name',
    sortable: true
  },
  {
    id: 'description',
    label: 'Description',
    sortable: true
  },
  {
    id: 'startDate',
    label: 'Start Date',
    sortable: true
  },
  {
    id: 'endDate',
    label: 'End Date',
    sortable: true
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true
  },
  {
    id: 'action',
    label: 'Action',
    sortable: false
  }
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if(!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  let result = stabilizedThis.map((el) => el[0]);
  return result;
}

export default function CampaignListTable(props) {
  const classes = useStyles();
  const {campaigns, handleApproveButtonClicked, handleRejectButtonClicked, setSelectedCampaign, role} = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('startDate');

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {setPage(0);}, [campaigns]);

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <SortableTableHead classes={classes} order={order} orderBy={orderBy} headCells={tableCellHeader} onRequestSort={handleRequestSort}>
          </SortableTableHead>
          <TableBody>
            {stableSort(campaigns, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <CampaignListTableItem 
                    key={row.id}
                    campaign={row}
                    handleApproveButtonClicked={handleApproveButtonClicked}
                    handleRejectButtonClicked={handleRejectButtonClicked}
                    setSelectedCampaign={setSelectedCampaign}
                    role={role}
                  >
                </CampaignListTableItem>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20]}
                colSpan={tableCellHeader.length}
                count={campaigns? campaigns.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}