import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

import { DatePicker } from "@material-ui/pickers";

import ErrorSnackBar from "../../Utilities/ErrorSnackBar";

import { voucherConsumptionReportListRequest } from "../../../redux/actions/voucherConsumptionReportList";
import GenericConsumptionTable from "./GenericConsumptionTable";
import HUAETable from "./HUAETable";
import StaffAmbassadorTable from "./StaffAmbassadorTable";
import RewardTable from "./RewardTable";

import * as moment from "moment";

import { CSVLink } from "react-csv";

import dateFormat from "dateformat";

const useStyle = makeStyles((theme) => ({
 root: {
  width: "100%",
 },
 paper: {
  marginTop: theme.spacing(3),
  width: "100%",
  overflowX: "auto",
  marginBottom: theme.spacing(2),
 },
 table: {
  minWidth: 650,
 },
 createButton: {
  float: "right",
 },
 textField: {
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
 },
 formControl: {
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  minWidth: 120,
 },
 visuallyHidden: {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: 20,
  width: 1,
 },
}));

const templateMap = [
 {
  id: "GENERIC",
  name: "Generic",
 },
 {
  id: "HUAE",
  name: "Hook Up and Earn",
 },
 {
  id: "STAFF_AMBASSADOR",
  name: "Staff Ambassador",
 },
 {
  id: "REWARD",
  name: "Reward",
 },
];

const genericHeader = ["Name", "Service ID", "Account No", "Channel"];

const huaeHeader = [
 "Name",
 "Service ID",
 "Account No",
 "Channel",
 "Customer SO Number",
 "Referrer Name",
 "Referrer Account Number",
 "Commission Date",
];

const staffAmbassadorHeader = [
 "Name",
 "Service ID",
 "Account No",
 "Channel",
 "Customer SO Number",
 "Customer Sign Up Date",
 "Customer Activation Date",
 "ID Number",
 "Referrer Name",
];

const rewardHeader = [
 "Name",
 "Voucher Code",
 "ID Number",
 "Service ID",
 "Account No",
 "Channel",
 "Merchant Name",
 "Grab Date",
 "Redemption Date",
];

const genericTableCellHeader = [
 {
  id: 0,
  label: "Used By Name",
  sortable: true,
 },
 {
  id: 1,
  label: "Used By Service ID",
  sortable: true,
 },
 {
  id: 2,
  label: "Used By Account No",
  sortable: true,
 },
 {
  id: 3,
  label: "Redeem Channel",
  sortable: true,
 },
];

const HUAETableCellHeader = [
 {
  id: 0,
  label: "Customer Name",
  sortable: true,
 },
 {
  id: 1,
  label: "Customer Service Number",
  sortable: true,
 },
 {
  id: 2,
  label: "Customer Account Number",
  sortable: true,
 },
 {
  id: 3,
  label: "Redeem Channel",
  sortable: true,
 },
 {
  id: 4,
  label: "Customer SO Number",
  sortable: true,
 },
 {
  id: 5,
  label: "Referrer Name",
  sortable: true,
 },
 {
  id: 6,
  label: "Referrer Account Number",
  sortable: true,
 },
 {
  id: 7,
  label: "Commission Date",
  sortable: true,
 },
];

const rewardTableCellHeader = [
 {
  id: 0,
  label: "Used By Name",
  sortable: true,
 },
 {
  id: 1,
  label: "E-Voucher Code",
  sortable: true,
 },
 {
  id: 2,
  label: "Used By ID Number",
  sortable: true,
 },
 {
  id: 3,
  label: "Used By Service ID",
  sortable: true,
 },
 {
  id: 4,
  label: "Used By Account No",
  sortable: true,
 },
 {
  id: 5,
  label: "Redeem Channel",
  sortable: true,
 },
 {
  id: 6,
  label: "Merchant Name",
  sortable: true,
 },
 {
  id: 7,
  label: "Grab Date",
  sortable: true,
 },
 {
  id: 8,
  label: "Redemption Date",
  sortable: true,
 },
];

const staffAmbassadorCellHeader = [
 {
  id: 0,
  label: "Used By Name",
  sortable: true,
 },
 {
  id: 1,
  label: "Used By Service ID",
  sortable: true,
 },
 {
  id: 2,
  label: "Used By Account No",
  sortable: true,
 },
 {
  id: 3,
  label: "Redeem Channel",
  sortable: true,
 },
 {
  id: 4,
  label: "SO Number",
  sortable: true,
 },
 {
  id: 5,
  label: "Sign Up Date",
  sortable: true,
 },
 {
  id: 6,
  label: "Activation Date",
  sortable: true,
 },
 {
  id: 7,
  label: "Staff ID",
  sortable: true,
 },
 {
  id: 8,
  label: "Staff Name",
  sortable: true,
 },
];

const cleanFilter = {
 consumption_start_date: null,
 consumption_end_date: null,
};

function descendingComparator(a, b, orderBy) {
 if (b[orderBy] < a[orderBy]) {
  return -1;
 }
 if (b[orderBy] > a[orderBy]) {
  return 1;
 }
 return 0;
}

function getComparator(order, orderBy) {
 return order === "desc"
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
 if (!array) return [];
 const stabilizedThis = array.map((el, index) => [el, index]);
 stabilizedThis.sort((a, b) => {
  const order = comparator(a[0], b[0]);
  if (order !== 0) return order;
  return a[1] - b[1];
 });
 let result = stabilizedThis.map((el) => el[0]);
 return result;
}

export default function VoucherConsumption({ match }) {
 const classes = useStyle();
 const {
  params: { voucherId },
 } = match;

 const dispatch = useDispatch();
 const [initList, setinitList] = useState(true);
 const [open, setOpen] = useState(false);
 const [errorMessage, setErrorMessage] = useState("");
 const [data, setData] = useState([]);
 const [template, setTemplate] = useState("GENERIC");
 const [lastTemplate, setLastTemplate] = useState("");
 const [filter, setFilter] = useState(cleanFilter);

 const [csvData, setCsvData] = useState([]);
 const [fileName, setFileName] = useState("report.csv");

 const [order, setOrder] = React.useState("asc");
 const [orderBy, setOrderBy] = React.useState("");

 const isLoading = useSelector(
  (state) => state.voucherConsumptionReportList.isLoading
 );
 const meta = useSelector((state) => state.voucherConsumptionReportList.meta);
 const consumptions = useSelector(
  (state) => state.voucherConsumptionReportList.consumptions
 );

 const createFilterRequest = () => {
  let filterResult = {
   id: voucherId,
  };
  if (filter.consumption_start_date) {
   filterResult.consumption_start_date =
    filter.consumption_start_date.format("YYYY-MM-DD");
  }
  if (filter.consumption_end_date) {
   filterResult.consumption_end_date =
    filter.consumption_end_date.format("YYYY-MM-DD");
  }

  return filterResult;
 };

 const handleApplyFilterButtonClicked = (e) => {
  let request = createFilterRequest();
  dispatch(voucherConsumptionReportListRequest(request));
 };

 const handleClearFilterButtonClicked = (e) => {
  setFilter(cleanFilter);
 };

 const handleConsumptionStartDateChange = (value) => {
  setFilter((prevState) => ({ ...prevState, consumption_start_date: value }));
 };

 const handleConsumptionEndDateChange = (value) => {
  setFilter((prevState) => ({ ...prevState, consumption_end_date: value }));
 };

 const handleTemplateChange = (e) => {
  const value = e.target.value;
  setTemplate(value);
 };

 const getDataField = (data, query) => {
  if (data && query) {
   let result = data[query];
   if (result) {
    return result;
   }
  }
  return "-";
 };

 const getDataDateField = (data, query, format) => {
  if (data && query) {
   let result = data[query];
   if (result) {
    return dateFormat(result, format);
   }
  }
  return "-";
 };

 useEffect(() => {
  if (initList) {
   dispatch(voucherConsumptionReportListRequest({ id: voucherId }));
   setinitList(false);
  }
 }, [dispatch, initList, voucherId]);

 useEffect(() => {
  if (meta) {
   if (meta.errorMsg) {
    setErrorMessage(meta.errorMsg);
    setOpen(true);
   }
  }
 }, [meta]);

 useEffect(() => {
  if (!initList) {
   const generatedData = [];
   if (template === "HUAE") {
    generatedData.push(huaeHeader);
   } else if (template === "STAFF_AMBASSADOR") {
    generatedData.push(staffAmbassadorHeader);
   } else if (template === "REWARD") {
    generatedData.push(rewardHeader);
   } else {
    generatedData.push(genericHeader);
   }

   stableSort(data, getComparator(order, orderBy)).forEach((row) => {
    generatedData.push(row);
   });

   setCsvData(generatedData);
  }
 }, [initList, data, template, order, orderBy]);

 useEffect(() => {
  const generateGenericData = () => {
   if (consumptions) {
    const result = [];
    consumptions.forEach((consumption) => {
     let row = [
      getDataField(consumption, "name"),
      getDataField(consumption, "service_id"),
      getDataField(consumption, "account_no"),
      getDataField(consumption, "channel"),
     ];
     result.push(row);
    });
    setData(result);
   }
  };

  const generateHUAEData = () => {
   if (consumptions) {
    console.log("generateHUAEData", consumptions);
    const result = [];
    consumptions.forEach((consumption) => {
     let row = [
      getDataField(consumption, "name"),
      getDataField(consumption, "service_id"),
      getDataField(consumption, "account_no"),
      getDataField(consumption, "channel"),
      getDataField(consumption, "so_number"),
      getDataField(consumption, "consumption_referrer_name"),
      getDataField(consumption, "referrer_acc_no"),
      getDataDateField(consumption, "comm_date", "yyyy-mm-dd"),
     ];
     result.push(row);
    });
    setData(result);
   }
  };

  const generateStaffAmbassadorData = () => {
   if (consumptions) {
    const result = [];
    consumptions.forEach((consumption) => {
     let row = [
      getDataField(consumption, "name"),
      getDataField(consumption, "service_id"),
      getDataField(consumption, "account_no"),
      getDataField(consumption, "channel"),
      getDataField(consumption, "so_number"),
      getDataDateField(consumption, "sign_up_date", "yyyy-mm-dd"),
      getDataDateField(consumption, "activation_date", "yyyy-mm-dd"),
      getDataField(consumption, "id_number"),
      getDataField(consumption, "consumption_staff_name"),
     ];
     result.push(row);
    });
    setData(result);
   }
  };

  const generateRewardData = () => {
   if (consumptions) {
    const result = [];
    consumptions.forEach((consumption) => {
     let formattedCreatedDate = "-";
     if (consumption.created_date) {
      let date = new Date(consumption.created_date);
      formattedCreatedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
     }
     let row = [
      getDataField(consumption, "name"),
      getDataField(consumption, "voucher_code"),
      getDataField(consumption, "used_by_id_number"),
      getDataField(consumption, "service_id"),
      getDataField(consumption, "account_no"),
      getDataField(consumption, "channel"),
      getDataField(consumption, "consumption_merchant"),
      getDataDateField(consumption, "grab_date", "yyyy-mm-dd HH:MM:ss"),
      formattedCreatedDate,
     ];
     result.push(row);
    });
    setData(result);
   }
  };

  if (consumptions) {
   if (template === "HUAE") {
    generateHUAEData();
   } else if (template === "STAFF_AMBASSADOR") {
    generateStaffAmbassadorData();
   } else if (template === "REWARD") {
    generateRewardData();
   } else {
    generateGenericData();
   }
  }
 }, [consumptions, template]);

 useEffect(() => {
  if (!lastTemplate) {
   setLastTemplate("GENERIC");
  } else {
   if (lastTemplate !== template) {
    setLastTemplate(template);
   }
  }
 }, [template, lastTemplate]);

 useEffect(() => {
  let date = dateFormat(new Date(), "yyyymmdd");
  setFileName(date + "_" + template + ".csv");
 }, [template]);

 useEffect(() => {
  setOrderBy("");
  setOrder("asc");
 }, [template]);

 return (
  <React.Fragment>
   <Fade in={isLoading}>
    <LinearProgress />
   </Fade>
   <Box m={3}>
    <Grid container>
     <Grid item xs={10}>
      <Typography variant="h5">Voucher Consumption Report</Typography>
     </Grid>
     <Grid item xs={2}>
      <div className={classes.createButton}>
       <Button
        color="primary"
        variant="contained"
        component={CSVLink}
        data={csvData}
        disabled={initList}
        filename={fileName}
       >
        Export Data
       </Button>
      </div>
     </Grid>
    </Grid>
   </Box>
   <Box m={3}>
    <Grid container>
     <Grid item xs={3}>
      <FormControl className={classes.formControl} fullWidth>
       <InputLabel id="templateLabel">Template</InputLabel>
       <Select id="template" value={template} onChange={handleTemplateChange}>
        {templateMap.map((template) => (
         <MenuItem key={template.id} value={template.id}>
          {template.name}
         </MenuItem>
        ))}
       </Select>
      </FormControl>
     </Grid>
    </Grid>
   </Box>
   <Box m={3}>
    <Grid container spacing={2}>
     <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
      <Paper elevation={2}>
       <Box m={2}>
        <Box>
         <Typography variant="subtitle1">Filter</Typography>
        </Box>
        <Box>
         <Grid container spacing={2}>
          <Grid item xs={12}>
           <DatePicker
            id="consumptionStartDate"
            label="Consumption Start Date"
            value={filter.consumption_start_date}
            format="YYYY-MM-DD"
            ampm={false}
            fullWidth
            onChange={handleConsumptionStartDateChange}
           />
          </Grid>
          <Grid item xs={12}>
           <DatePicker
            id="consumptionEndDate"
            label="Consumption End Date"
            value={filter.consumption_end_date}
            format="YYYY-MM-DD"
            ampm={false}
            fullWidth
            onChange={handleConsumptionEndDateChange}
           />
          </Grid>
          <Grid item xs={12}>
           <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleApplyFilterButtonClicked}
           >
            Apply Filter
           </Button>
          </Grid>
          <Grid item xs={12}>
           <Button
            variant="contained"
            fullWidth
            onClick={handleClearFilterButtonClicked}
           >
            Clear Filter
           </Button>
          </Grid>
         </Grid>
        </Box>
       </Box>
      </Paper>
     </Grid>

     <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
      <Paper>
       {template === "GENERIC" ? (
        <GenericConsumptionTable
         classes={classes}
         data={data}
         tableCellHeader={genericTableCellHeader}
         getComparator={getComparator}
         stableSort={stableSort}
         order={order}
         setOrder={setOrder}
         orderBy={orderBy}
         setOrderBy={setOrderBy}
        ></GenericConsumptionTable>
       ) : null}
       {template === "HUAE" ? (
        <HUAETable
         classes={classes}
         data={data}
         tableCellHeader={HUAETableCellHeader}
         getComparator={getComparator}
         stableSort={stableSort}
         order={order}
         setOrder={setOrder}
         orderBy={orderBy}
         setOrderBy={setOrderBy}
        ></HUAETable>
       ) : null}
       {template === "STAFF_AMBASSADOR" ? (
        <StaffAmbassadorTable
         classes={classes}
         data={data}
         tableCellHeader={staffAmbassadorCellHeader}
         getComparator={getComparator}
         stableSort={stableSort}
         order={order}
         setOrder={setOrder}
         orderBy={orderBy}
         setOrderBy={setOrderBy}
        ></StaffAmbassadorTable>
       ) : null}
       {template === "REWARD" ? (
        <RewardTable
         classes={classes}
         data={data}
         tableCellHeader={rewardTableCellHeader}
         getComparator={getComparator}
         stableSort={stableSort}
         order={order}
         setOrder={setOrder}
         orderBy={orderBy}
         setOrderBy={setOrderBy}
        ></RewardTable>
       ) : null}
      </Paper>
     </Grid>
    </Grid>
   </Box>

   <ErrorSnackBar
    open={open}
    setOpen={setOpen}
    hideDuration={3000}
    errorMessage={errorMessage}
   ></ErrorSnackBar>
  </React.Fragment>
 );
}
