import {VOUCHER_UPDATE_RULE_ACTION_TYPE as ACTION} from './actionTypes';

export const voucherUpdateRuleRequest = (data) =>  {
    return {
        type: ACTION.VOUCHER_UPDATE_RULE_REQUEST,
        data
    }
}

export const voucherUpdateRuleSuccess = (response) =>  {
    return {
        type: ACTION.VOUCHER_UPDATE_RULE_SUCCESS,
        response
    }
  }

export const voucherUpdateRuleFailed = (error) =>  {
    return {
        type: ACTION.VOUCHER_UPDATE_RULE_FAILED,
        error
    }
}