import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {updateCampaignSuccess, updateCampaignFailed} from '../actions/updateCampaign';
import { CAMPAIGN_UPDATE_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doPut } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* updateCampaign({data}) {
  try {
    const responseData = yield call(doPut, ENDPOINT.CAMPAIGN + '/' + data.id, data.data);
    if(responseData.status === 200){
      yield put(updateCampaignSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(updateCampaignFailed(responseData));
    }
  } catch (error) {
    yield put(updateCampaignFailed(error));
  }
}
  
function* watchUpdateCampaign(){
  yield takeLatest(ACTION.CAMPAIGN_UPDATE_REQUEST, updateCampaign);
}

export default function* root() {
  yield fork(watchUpdateCampaign);
}
  