import {VOUCHER_CONSUMPTION_LIST_REPORT_ACTION_TYPE as ACTION} from './actionTypes';

export const voucherConsumptionReportListRequest = (data) =>  {
    return {
        type: ACTION.VOUCHER_CONSUMPTION_LIST_REPORT_REQUEST,
        data
    }
}

export const voucherConsumptionReportListSuccess = (response) =>  {
    return {
        type: ACTION.VOUCHER_CONSUMPTION_LIST_REPORT_SUCCESS,
        response
    }
  }

export const voucherConsumptionReportListFailed = (error) =>  {
    return {
        type: ACTION.VOUCHER_CONSUMPTION_LIST_REPORT_FAILED,
        error
    }
}