import {VOUCHER_GET_BY_ID_ACTION_TYPE as ACTION} from './actionTypes';

export const voucherGetIdRequest = (data) =>  {
    return {
        type: ACTION.VOUCHER_GET_BY_ID_REQUEST,
        data
    }
}

export const voucherGetIdSuccess = (response) =>  {
    return {
        type: ACTION.VOUCHER_GET_BY_ID_SUCCESS,
        response
    }
  }

export const voucherGetIdFailed = (error) =>  {
    return {
        type: ACTION.VOUCHER_GET_BY_ID_FAILED,
        error
    }
}