import {VOUCHER_LIST_ACTION_TYPE as ACTION} from './actionTypes';

export const voucherListRequest = (data) =>  {
    return {
        type: ACTION.VOUCHER_LIST_REQUEST,
        data
    }
}

export const voucherListSuccess = (response) =>  {
    return {
        type: ACTION.VOUCHER_LIST_SUCCESS,
        response
    }
  }

export const voucherListFailed = (error) =>  {
    return {
        type: ACTION.VOUCHER_LIST_FAILED,
        error
    }
}