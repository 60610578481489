import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';

import SortableTableHead from '../../Table/SortableTableHead';

export default function GenericConsumptionTable(props) {
    const {classes, data, tableCellHeader, getComparator, stableSort, order, setOrder, orderBy, setOrderBy} = props;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <div className={classes.root}>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <SortableTableHead classes={classes} order={order} orderBy={orderBy} headCells={tableCellHeader} onRequestSort={handleRequestSort}>
                    </SortableTableHead>
                    <TableBody>
                        {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row[0]}</TableCell>
                                <TableCell>{row[1]}</TableCell>
                                <TableCell>{row[2]}</TableCell>
                                <TableCell>{row[3]}</TableCell>
                                <TableCell>{row[4]}</TableCell>
                                <TableCell>{row[5]}</TableCell>
                                <TableCell>{row[6]}</TableCell>
                                <TableCell>{row[7]}</TableCell>
                                <TableCell>{row[8]}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 20]}
                            colSpan={tableCellHeader.length}
                            count={data? data.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                            }}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );

}