import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {dashboardConsumptionCountSuccess, dashboardConsumptionCountFailed} from '../actions/dashboardConsumptionCount';
import { DASHBOARD_CONSUMPTION_COUNT_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* dashboardConsumptionCount({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.DASHBOARD + '/consumptions', null);
    if(responseData.status === 200){
      yield put(dashboardConsumptionCountSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(dashboardConsumptionCountFailed(responseData));
    }
  } catch (error) {
    yield put(dashboardConsumptionCountFailed(error));
  }
}
  
function* watchDashboardConsumptionCount() {
  yield takeLatest(ACTION.DASHBOARD_CONSUMPTION_COUNT_REQUEST, dashboardConsumptionCount);
}

export default function* root() {
  yield fork(watchDashboardConsumptionCount);
}
  