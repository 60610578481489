import { USER_ACTION_TYPE as ACTION } from '../actions/actionTypes';
// import {getStore} from '../store/storeConfig';
// import {getUser} from '../selectors/user';

const initialState = {
    name: '',
    token: '',
    authenticated: false
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.USER_LOGIN_SUCCESS:
      return {
        ...state,
        name: action.data.name,
        role: action.data.role,
        token: action.data.token,
        authenticated: true
      }
    case ACTION.USER_LOGOUT_SUCCESS:
      return initialState;
    default:
        return state;
  }
}

export default user;