import React, {useEffect, useState} from 'react';

import Typography from '@material-ui/core/Typography';

import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const regionMap = [
    {
        id: 'NORTHERN',
        value: 'Northern'
    },
    {
        id: 'SOUTHERN',
        value: 'Southern'
    },
    {
        id: 'CENTRAL',
        value: 'Central'
    },
    {
        id: 'EASTCOAST',
        value: 'East Coast'
    }
]

export default function Region(props) {
    const {classes, regionState, setRegionState, updateRegion, disabled} = props;

    const [hasChange, setHasChange] = useState(false);

    const handleChange = (event) => {
        setRegionState(prevState => ({ ...prevState, [event.target.name]: event.target.checked }));
        setHasChange(true);
    };

    useEffect(() => {
        if(hasChange) {
            const ruleRegion = []
            for(var key in regionState) {
                const value = regionState[key];
                if(value) {
                    ruleRegion.push(key);
                }
            }
            updateRegion(ruleRegion);
            setHasChange(false);
        }
    }, [regionState, updateRegion, hasChange, setHasChange]);
    
    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Region Rule
                </Typography>
            </Box>
            <Box m={3}>
                <Grid container>
                    <Grid item xs={6}>
                        <FormControl component="fieldset" className={classes.formControl} disabled={disabled}>
                            <FormGroup>
                                <Grid container>
                                    {regionMap.map(region => (
                                        <Grid item xs={6} key={region.id}>
                                            <FormControlLabel
                                            control={<Checkbox checked={regionState[region.id]} onChange={handleChange} name={region.id} />}
                                            label={region.value}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}