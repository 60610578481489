import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

import UserListTableItem from './UserListTableItem';

export default function UserListTable(props) {
    const {classes, users, updateUserStatus} = props;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Enabled</TableCell>
                    <TableCell>Action</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {users ? users.map(user => (
                        <UserListTableItem user={user} classes={classes} updateUserStatus={updateUserStatus} key={user.id}></UserListTableItem>
                    )): null}
                </TableBody>
            </Table>
            </Paper>
        </div>
    )
}