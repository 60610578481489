import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {voucherPatchSuccess, voucherPatchFailed} from '../actions/voucherPatch';
import { VOUCHER_PATCH_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doPatch } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* patchVoucher({data}) {
  try {
    const responseData = yield call(doPatch, ENDPOINT.VOUCHER + '/' + data.id, data.data);
    if(responseData.status === 200){
      yield put(voucherPatchSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(voucherPatchFailed(responseData));
    }
  } catch (error) {
    yield put(voucherPatchFailed(error));
  }
}
  
function* watchPatchVoucher(){
  yield takeLatest(ACTION.VOUCHER_PATCH_REQUEST, patchVoucher);
}

export default function* root() {
  yield fork(watchPatchVoucher);
}
  