import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {dashboardActiveVoucherCountSuccess, dashboardActiveVoucherCountFailed} from '../actions/dashboardActiveVoucherCount';
import { DASHBOARD_ACTIVE_VOUCHER_COUNT_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* dashboardActiveVoucherCount({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.DASHBOARD + '/active-voucher/count', null);
    if(responseData.status === 200){
      yield put(dashboardActiveVoucherCountSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(dashboardActiveVoucherCountFailed(responseData));
    }
  } catch (error) {
    yield put(dashboardActiveVoucherCountFailed(error));
  }
}
  
function* watchDashboardActiveVoucherCount() {
  yield takeLatest(ACTION.DASHBOARD_ACTIVE_VOUCHER_COUNT_REQUEST, dashboardActiveVoucherCount);
}

export default function* root() {
  yield fork(watchDashboardActiveVoucherCount);
}