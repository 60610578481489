import { DASHBOARD_CAMPAIGN_STATUS_COUNT_ACTION_TYPE as ACTION } from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    meta: {
        success: false,
        errorCode: '',
        errorMsg: '',
    },
    data: []
};

const dashboardCampaignStatusCount = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.DASHBOARD_CAMPAIGN_STATUS_COUNT_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ACTION.DASHBOARD_CAMPAIGN_STATUS_COUNT_SUCCESS:
      return {
        isLoading: false,
        meta: {
          success: true,
          errorCode: action.response.data.errorCode,
          errorMsg: action.response.data.errorMsg
        },
        data: action.response.data.data
      }
    case ACTION.DASHBOARD_CAMPAIGN_STATUS_COUNT_FAILED:
      return {
        isLoading: false,
        meta: {
          success: false,
          errorCode: action.error.response.data.meta.errorCode,
          errorMsg: action.error.response.data.meta.errorMsg
        },
        data: []
      }
    default:
      return state;
  }
}

export default dashboardCampaignStatusCount;