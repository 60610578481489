import {CAMPAIGN_LIST_ACTION_TYPE as ACTION} from './actionTypes';

export const campaignListRequest = (data) =>  {
    return {
        type: ACTION.CAMPAIGN_LIST_REQUEST,
        data
    }
}

export const campaignListSuccess = (response) =>  {
    return {
        type: ACTION.CAMPAIGN_LIST_SUCCESS,
        response
    }
  }

export const campaignListFailed = (error) =>  {
    return {
        type: ACTION.CAMPAIGN_LIST_FAILED,
        error
    }
}