import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {widgetGetUserWidgetListSuccess, widgetGetUserWidgetListFailed} from '../actions/widgetGetUserWidgetList';
import { WIDGET_GET_USER_WIDGET_LIST_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* widgetGetUserWidgetList({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.WIDGET, null);
    if(responseData.status === 200){
      yield put(widgetGetUserWidgetListSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(widgetGetUserWidgetListFailed(responseData));
    }
  } catch (error) {
    yield put(widgetGetUserWidgetListFailed(error));
  }
}
  
function* watchWidgetGetUserWidgetList() {
  yield takeLatest(ACTION.WIDGET_GET_USER_WIDGET_LIST_REQUEST, widgetGetUserWidgetList);
}

export default function* root() {
  yield fork(watchWidgetGetUserWidgetList);
}