import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import CSVReader from 'react-csv-reader';

const bulkTypeMap = [
    {
        id: 'STAFFID',
        value: 'Staff ID'
    },
    {
        id: 'IC',
        value: 'IC'
    },
    {
        id: 'MERCHANT',
        value: 'Merchant'
    }
]

export default function VoucherWhitelistUplaod(props) {

    const {classes, voucher, setVoucher, handleBulkUpload, handleMerchantIDChange, changeVoucherTypeMap} = props;

    const handleBulkTypeChange = (e) => {
        const value = e.target.value;
        const prevValue = voucher.bulk_type;

        if(value !== prevValue) {

            changeVoucherTypeMap(value);
            if(value === 'IC' || value === 'STAFFID') {
                setVoucher(prevState => {
                    return {
                        ...prevState,
                        bulk_type: value,
                        type: 'REFERRAL',
                        code_template: {
                            prefix: 'REF',
                            random_type: 'ALPHANUMERIC',
                            suffix: ''
                        },
                    }
                });
            } else {
                setVoucher(prevState => {
                    return {
                        ...prevState,
                        bulk_type: value,
                        type: '',
                        code_template: {
                            prefix: '',
                            random_type: '',
                            suffix: ''
                        },
                    }
                });
            }
        }
    }

    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Upload Data
                </Typography>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="bulkType">ID Type</InputLabel>
                            <Select
                                id="idType"
                                value={voucher.bulk_type}
                                onChange={handleBulkTypeChange}
                            >
                                {bulkTypeMap.map(bulkType => (
                                    <MenuItem key={bulkType.id} value={bulkType.id}>{bulkType.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        {voucher.bulk_flag && voucher.bulk_type === 'MERCHANT' ? <TextField
                            id="merchantId"
                            label="Merchant ID"
                            value={voucher.merchant_id}
                            fullWidth
                            className={classes.textField}
                            onChange={handleMerchantIDChange}></TextField> : null
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box m={3}>
                <FormControl className={classes.formControl} fullWidth>
                    <CSVReader label="Upload file" onFileLoaded={handleBulkUpload} disabled={!voucher.bulk_type} />
                </FormControl>
            </Box>
            <Box m={3}>
                {voucher.bulk_filename ? 
                    <Box>File uploaded: {voucher.bulk_filename}</Box> : null
                }
                <Box>
                    Total records: {voucher.bulk_list.length}
                </Box>
            </Box>
        </div>
    );
}