import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import {DatePicker}  from '@material-ui/pickers';

export default function Age(props) {
    const {title, startDate, endDate, handleStartDateChanged, handleEndDateChanged} = props;

    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    {title}
                </Typography>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DatePicker
                            id="startDate"
                            label="Start Date"
                            value={startDate}
                            format="YYYY-MM-DD"
                            ampm={false}
                            fullWidth
                            onChange={handleStartDateChanged}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            id="endDate"
                            label="End Date"
                            value={endDate}
                            format="YYYY-MM-DD"
                            ampm={false}
                            fullWidth
                            onChange={handleEndDateChanged}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}