import { VOUCHER_PATCH_ACTION_TYPE as ACTION } from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    meta: {
        success: false,
        errorCode: '',
        errorMsg: ''
    }
};

const patchVoucher = (state = initialState, action) => {
    switch(action.type) {
        case ACTION.VOUCHER_PATCH_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case ACTION.VOUCHER_PATCH_SUCCESS:
                return {
                    isLoading: false,
                    meta: {
                        success: true,
                        errorCode: action.response.data.meta.errorCode,
                        errorMsg: action.response.data.meta.errorMsg,
                    }
                }
        case ACTION.VOUCHER_PATCH_FAILED:
                return {
                    isLoading: false,
                    meta: {
                        success: true,
                        errorCode: action.error.response.data.meta.errorCode,
                        errorMsg: action.error.response.data.meta.errorMsg,
                    }
                }
        default:
            return initialState;
    }
}

export default patchVoucher;