import {GET_AVAILABLE_VOUCHER_CODE_ACTION_TYPE as ACTION} from './actionTypes';

export const getAvailableVoucherCodeRequest = (data) =>  {
    return {
        type: ACTION.GET_AVAILABLE_VOUCHER_CODE_REQUEST,
        data
    }
}

export const getAvailableVoucherCodeSuccess = (response) =>  {
    return {
        type: ACTION.GET_AVAILABLE_VOUCHER_CODE_SUCCESS,
        response
    }
  }

export const getAvailableVoucherCodeFailed = (error) =>  {
    return {
        type: ACTION.GET_AVAILABLE_VOUCHER_CODE_FAILED,
        error
    }
}