import {DASHBOARD_ACTIVE_CAMPAIGN_COUNT_ACTION_TYPE as ACTION} from './actionTypes';

export const dashboardActiveCampaignCountRequest = (data) =>  {
    return {
        type: ACTION.DASHBOARD_ACTIVE_CAMPAIGN_COUNT_REQUEST,
        data
    }
}

export const dashboardActiveCampaignCountSuccess = (response) =>  {
    return {
        type: ACTION.DASHBOARD_ACTIVE_CAMPAIGN_COUNT_SUCCESS,
        response
    }
  }

export const dashboardActiveCampaignCountFailed = (error) =>  {
    return {
        type: ACTION.DASHBOARD_ACTIVE_CAMPAIGN_COUNT_FAILED,
        error
    }
}