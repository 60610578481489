import React from 'react';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';


import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

const randomTypeMap = [
    {
        id: 'ALPHANUMERIC',
        value: 'Alphanumeric'
    },
    {
        id: 'NUMERIC',
        value: 'Numeric'
    }
]

const voucherCodeTypeMap = [
    {
        id: 'RANDOM',
        value: 'Random'
    },
    {
        id: 'FIXED',
        value: 'Fixed'
    }
]

export default function VoucherCode(props) {

    const {classes, voucher, setVoucher, generateVoucherCode, validateVoucherCode, generatedVoucherCode} = props;

    const handlePrefixChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                code_template: {
                    ...prevState.code_template,
                    prefix: value
                }
            }
        });
    }

    const handleRandomTypeChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                code_template: {
                    ...prevState.code_template,
                    random_type: value
                }
            }
        });
    }

    const handleVoucherCodeTypeChange = (e) => {
        const value = e.target.value;
        const isRandom = (value === 'RANDOM');
        setVoucher(prevState => {
            return {
                ...prevState,
                voucher_code_type: value,
                random: isRandom
            }
        });
    }

    const handleSuffixChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                code_template: {
                    ...prevState.code_template,
                    suffix: value
                }
            }
        });
    }

    const disableGenerateButton = () => {
        return !validateVoucherCode();
    }

    const handleGenerateClicked = (e) => {
        generateVoucherCode();
    }

    const handleFixedVoucherCodeChange = (e) => {
        const value = e.target.value;
        setVoucher(prevState => {
            return {
                ...prevState,
                fixed_voucher_code: value
            }
        });
    }

    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Voucher Code
                </Typography>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="voucherCodeType">Voucher Code Type</InputLabel>
                            <Select
                                id="voucherCodeType"
                                value={voucher.voucher_code_type}
                                onChange={handleVoucherCodeTypeChange}
                            >
                                {voucherCodeTypeMap.map(type => (
                                    <MenuItem key={type.id} value={type.id}>{type.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {voucher.voucher_code_type === 'FIXED' ?
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            id="fixedVoucherCode"
                            label="Target Voucher Code"
                            value={voucher.fixed_voucher_code.code}
                            fullWidth
                            className={classes.textField}
                            onChange={handleFixedVoucherCodeChange}
                        />
                    </Grid>
                </Grid> : null}
                {voucher.voucher_code_type === 'RANDOM' ?
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <TextField
                            id="voucherCodePrefix"
                            label="Prefix"
                            value={voucher.code_template.prefix}
                            fullWidth
                            className={classes.textField}
                            onChange={handlePrefixChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="voucherCodeRandomType">Random Type</InputLabel>
                            <Select
                                id="voucherCodeRandomType"
                                value={voucher.code_template.random_type}
                                onChange={handleRandomTypeChange}
                            >
                                {randomTypeMap.map(randomType => (
                                    <MenuItem key={randomType.id} value={randomType.id}>{randomType.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id="voucherCodeSuffix"
                            label="Suffix"
                            value={voucher.code_template.suffix}
                            fullWidth
                            className={classes.textField}
                            onChange={handleSuffixChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            id="generatedVoucherCode"
                            label="Generated Voucher Code"
                            value={generatedVoucherCode}
                            fullWidth
                            className={classes.textField}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button color="primary" variant="contained" fullWidth={true} disabled={disableGenerateButton()} onClick={handleGenerateClicked}>Generate</Button>
                    </Grid>
                </Grid>: null}
            </Box>
        </div>
    );
}