import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom';

import {useSelector, useDispatch} from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import ErrorSnackBar from '../Utilities/ErrorSnackBar'; 

import {widgetGetUserWidgetListRequest} from '../../redux/actions/widgetGetUserWidgetList';
import {widgetUpdateUserWidgetListRequest} from '../../redux/actions/widgetUpdateUserWidgetList';

const useStyle = makeStyles(themes => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
    rightButton: {
        marginRight: theme.spacing(1),
        float: 'right'
    }
}));

const widgetMap = [
    {
        id: 'active_campaign_count',
        value: 'Active Campaign Count',
        widget_id: 1
    },
    {
        id: 'active_voucher_count',
        value: 'Active Voucher Count',
        widget_id: 2
    },
    {
        id: 'consumption_count',
        value: 'Consumption Count',
        widget_id: 3
    }
    // {
    //     id: 'campaign_status_count',
    //     value: 'Campaign Status Count',
    //     widget_id: 4
    // },
    // {
    //     id: 'voucher_status_count',
    //     value: 'Voucher Status Count',
    //     widget_id: 5
    // }
]

const idMap = new Map();
idMap.set('active_campaign_count', 1);
idMap.set('active_voucher_count', 2);
idMap.set('consumption_count', 3);
idMap.set('campaign_status_count', 4);
idMap.set('voucher_status_count', 5);

export default function EditDashboard(props) {
  const classes = useStyle();

  const [initValue, setInitValue] = useState(true);
  const [widgetFlag, setWidgetFlag] = useState({
    active_campaign_count: false,
    active_voucher_count: false,
    consumption_count: false,
    campaign_status_count: false,
    voucher_status_count: false
  });

  const [submitted, setSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getUserWidgetListLoading = useSelector(state => state.widgetGetUserWidgetList.meta.isLoading);
  const userWidgetList = useSelector(state => state.widgetGetUserWidgetList.user_widgets);

  const updateWidgetLoading = useSelector(state => state.widgetUpdateUserWidgetList.isLoading);
  const updateWidgetMeta = useSelector(state => state.widgetUpdateUserWidgetList.meta);

  const generateUpdateRequest = () => {
      let keys = Object.keys(widgetFlag);
      let result = {
          widgets: []
      }
      for(let i = 0; i < keys.length; i++) {
          let key = keys[i];
          let value = widgetFlag[key];
          if(value) {
            let idValue = idMap.get(key);
            let widget = {
                widget_id: idValue
            }
            result.widgets.push(widget);
          }
      }
      return result;
  }

  const handleChange = (e) => {
    setWidgetFlag(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
  };

  const onSubmit = (e) => {
    let request = generateUpdateRequest();
    dispatch(widgetUpdateUserWidgetListRequest(request));
    setSubmitted(true);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if(initValue) {
      setInitValue(false);
      dispatch(widgetGetUserWidgetListRequest({}));
    }
  }, [initValue, dispatch]);

  useEffect(() => {
    if(!getUserWidgetListLoading && userWidgetList && userWidgetList.length > 0) {
      let updatedFlag = {
        active_campaign_count: false,
        active_voucher_count: false,
        consumption_count: false,
        campaign_status_count: false,
        voucher_status_count: false
      }

      for(let i = 0; i < userWidgetList.length; i++) {
        let userWidget = userWidgetList[i];
        if(userWidget.widget_id === 1) {
          updatedFlag.active_campaign_count = true;
        } else if(userWidget.widget_id === 2) {
          updatedFlag.active_voucher_count = true;
        } else if(userWidget.widget_id === 3) {
          updatedFlag.consumption_count = true;
        } else if(userWidget.widget_id === 4) {
          updatedFlag.campaign_status_count = true;
        } else if(userWidget.widget_id === 5) {
          updatedFlag.voucher_status_count = true;
        }
      }

      setWidgetFlag(updatedFlag);
    }
  }, [userWidgetList, getUserWidgetListLoading]);

  useEffect(() => {
    if(submitted && !updateWidgetLoading) {
        setSubmitted(false);
        if(!updateWidgetMeta.success) {
            setErrorMessage(updateWidgetMeta.errorMsg);
            setOpen(true);
        }
    }
  }, [submitted, updateWidgetLoading, updateWidgetMeta]);

  return updateWidgetMeta.success ? <Redirect to="/dashboard"></Redirect> : (
    <React.Fragment>
      <Fade in={updateWidgetLoading || getUserWidgetListLoading}>
        <LinearProgress />
      </Fade>
      <Box m={3}>
        <Grid container >
            <Grid item xs={8}>
            <Typography variant="h5">
                Edit Dashboard
            </Typography>
            </Grid>
            <Grid item xs={4}>
                <div className={classes.rightButton}>
                  <Button color="primary" variant="contained" href="#" onClick={onSubmit}>Save</Button>
                </div>
            </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                        <Grid container>
                        {widgetMap.map(widget => (
                            <Grid item xs={6} key={widget.id}>
                                <FormControlLabel
                                control={<Checkbox checked={widgetFlag[widget.id]} name={widget.id} onChange={handleChange} />}
                                label={widget.value}
                                />
                            </Grid>
                        ))}
                        </Grid>
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
      </Box>
      <ErrorSnackBar
        open={open}
        setOpen={setOpen}
        hideDuration={3000}
        errorMessage={errorMessage}
      ></ErrorSnackBar>
    </React.Fragment>
    
  );
}