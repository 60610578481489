import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

export default function VoucherListTableItem(props) {

    const {voucher, setSelectedVoucher, handleApproveButtonClicked, handleRejectButtonClicked, role} = props;

    const handleApproveClicked = () => {
        setSelectedVoucher(voucher.id);
        handleApproveButtonClicked(voucher);
    }

    const handleRejectClicked = () => {
        setSelectedVoucher(voucher.id);
        handleRejectButtonClicked(voucher);
    }

    const enableApproval = () => {
        return role !== 'CREATOR' && voucher.status === 'PENDING' ;
    }

    const enableEdit = () => {
        return voucher.status === 'PENDING';
    }


    return (
        <TableRow>
            <TableCell>{voucher.campaign_id}</TableCell>
            <TableCell>{voucher.campaign_name}</TableCell>
            <TableCell>{voucher.name}</TableCell>
            <TableCell>{voucher.code}</TableCell>
            <TableCell>{voucher.description}</TableCell>
            <TableCell>{voucher.type}</TableCell>
            <TableCell>{voucher.status}</TableCell>
            <TableCell>
                {enableEdit() ? 
                    <IconButton size="small" href={'/vouchers/details/' + voucher.id}>
                        <EditIcon color="primary"/>
                    </IconButton> : 
                    <IconButton size="small" href={'/vouchers/details/' + voucher.id}>
                        <VisibilityIcon color="primary"/>
                    </IconButton>
                }
                
                {enableApproval() ? (
                    <span>
                        <IconButton size="small" onClick={handleApproveClicked}>
                            <CheckIcon color="primary"/>
                        </IconButton>
                        <IconButton size="small" onClick={handleRejectClicked}>
                            <CloseIcon color="error"/>
                        </IconButton>
                    </span>
                ) : null}
            </TableCell>
        </TableRow>
    );
}