import { CAMPAIGN_UPDATE_ACTION_TYPE as ACTION } from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    meta: {
        success: false,
        errorCode: '',
        errorMsg: '',
    },
    campaign: null
};

const updateCampaign = (state = initialState, action) => {
    switch(action.type) {
        case ACTION.CAMPAIGN_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case ACTION.CAMPAIGN_UPDATE_SUCCESS:
                return {
                    isLoading: false,
                    meta: {
                        success: true,
                        errorCode: action.response.data.meta.errorCode,
                        errorMsg: action.response.data.meta.errorMsg,
                    },
                    campaign: action.response.data.campaign
                }
        case ACTION.CAMPAIGN_UPDATE_FAILED:
                return {
                    isLoading: false,
                    meta: {
                        success: false,
                        errorCode: action.error.response.data.meta.errorCode,
                        errorMsg: action.error.response.data.meta.errorMsg,
                    }
                }
        default:
            return initialState;
    }
}

export default updateCampaign;