import React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import FormControl from '@material-ui/core/FormControl';

import CSVReader from 'react-csv-reader';

export default function Whitelist(props) {

    const {classes, whitelist, handleWhitelistUpload, disabled} = props;

    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Whitelist
                </Typography>
            </Box>
            {!disabled ? 
                <Box m={3}>
                    <FormControl className={classes.formControl} fullWidth>
                        <CSVReader label="Upload file" onFileLoaded={handleWhitelistUpload} />
                    </FormControl>
                </Box> : null}
            <Box m={3}>
                <Box>
                    Total records: {whitelist.length}
                </Box>
            </Box>
        </div>
    );
}