import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {widgetUpdateUserWidgetListSuccess, widgetUpdateUserWidgetListFailed} from '../actions/widgetUpdateUserWidgetList';
import { WIDGET_UPDATE_USER_WIDGET_LIST_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doPut } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* widgetUpdateUserWidgetList({data}) {
  try {
    const responseData = yield call(doPut, ENDPOINT.WIDGET, data);
    if(responseData.status === 200){
      yield put(widgetUpdateUserWidgetListSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(widgetUpdateUserWidgetListFailed(responseData));
    }
  } catch (error) {
    yield put(widgetUpdateUserWidgetListFailed(error));
  }
}
  
function* watchWidgetUpdateUserWidgetList() {
  yield takeLatest(ACTION.WIDGET_UPDATE_USER_WIDGET_LIST_REQUEST, widgetUpdateUserWidgetList);
}

export default function* root() {
  yield fork(watchWidgetUpdateUserWidgetList);
}