import React from 'react';

import Typography from '@material-ui/core/Typography';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export default function AutoDebit(props) {
    const {autoDebit, updateAutoDebit, disabled} = props;

    const handleChange = (e) => {
        updateAutoDebit(!autoDebit);
    };
    
    return (
        <div>
            <Box m={3}>
                <Typography variant="h6">
                    Auto Debit Flag Rule
                </Typography>
            </Box>
            <Box m={3}>
                <Grid container>
                    <Grid item xs={6}>
                        <Box>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={autoDebit} onChange={handleChange} value={autoDebit} />
                                    }
                                    label="Auto debit"
                                    disabled={disabled}
                                />
                            </FormGroup>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}