import React, {useState, useEffect} from 'react';

import {Redirect} from 'react-router-dom';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import theme from 'theme';

import ErrorSnackBar from '../../Utilities/ErrorSnackBar';

import {DateTimePicker}  from '@material-ui/pickers';
import * as moment from 'moment';

import {useDispatch, useSelector} from 'react-redux';

import {createCampaignRequest} from '../../../redux/actions/createCampaign';

import validator from 'validator';

const useStyle = makeStyles(themes => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export default function CreateCampaign(props) {
    const classes = useStyle();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState(moment().seconds(0).millisecond(0));
    const [endDate, setEndDate] = useState(moment().seconds(0).millisecond(0));

    const isLoading = useSelector(state => state.createCampaign.isLoading);
    const meta = useSelector(state => state.createCampaign.meta);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    const handleCreateCampaignClicked = (e) => {
        const createCampaignData = {
            name: name,
            description: description,
            startDate: startDate.toDate(),
            endDate: endDate.toDate()
        }
        setSubmitted(true);
        dispatch(createCampaignRequest(createCampaignData));
    }

    const disableButton = () => {
        if(
            validator.isEmpty(name) || 
            validator.isEmpty(description)) {
                return true;
        } else {
            return endDate <= startDate;
        }
    }

    useEffect(() => {
        if(submitted && !isLoading) {
            setSubmitted(false);
            if(!meta.success) {
                setErrorMessage(meta.errorMsg);
                setOpen(true);
            }
        }
    }, [submitted, meta, isLoading]);

    return meta.success? <Redirect to="/campaigns"></Redirect> : (            
        <div>
            <Fade in={isLoading}>
                <LinearProgress />
            </Fade>
            <Box m={3}>
                <Typography variant="h5">
                    Create Campaign
                </Typography>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            id="campaignName"
                            label="Campaign Name"
                            value={name}
                            fullWidth
                            required
                            className={classes.textField}
                            onChange={handleNameChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimePicker
                            id="startDate"
                            label="Start Date"
                            value={startDate}
                            format="YYYY-MM-DD HH:mm"
                            ampm={false}
                            fullWidth
                            onChange={setStartDate}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DateTimePicker
                            id="endDate"
                            label="End Date"
                            value={endDate}
                            format="YYYY-MM-DD HH:mm"
                            ampm={false}
                            fullWidth
                            onChange={setEndDate}
                        />
                    </Grid>
                </Grid>
                <Box>
                    <TextField
                        id="campaignDescription"
                        label="Campaign Description"
                        fullWidth
                        required
                        value={description}
                        className={classes.textField}
                        onChange={handleDescriptionChange}
                    />
                </Box>
            </Box>
            <Box m={3}>
                <Button color="primary" variant="contained" fullWidth={true} disabled={disableButton()} onClick={handleCreateCampaignClicked}>Create Campaign</Button>
            </Box>
            <ErrorSnackBar
                open={open}
                setOpen={setOpen}
                hideDuration={3000}
                errorMessage={errorMessage}
            >
            </ErrorSnackBar>
        </div>
    );

}