import {VOUCHER_LIST_REPORT_ACTION_TYPE as ACTION} from './actionTypes';

export const voucherReportListRequest = (data) =>  {
    return {
        type: ACTION.VOUCHER_LIST_REPORT_REQUEST,
        data
    }
}

export const voucherReportListSuccess = (response) =>  {
    return {
        type: ACTION.VOUCHER_LIST_REPORT_SUCCESS,
        response
    }
  }

export const voucherReportListFailed = (error) =>  {
    return {
        type: ACTION.VOUCHER_LIST_REPORT_FAILED,
        error
    }
}