import React, {useState, useEffect} from 'react';

import Typography from '@material-ui/core/Typography';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export default function StatusCodeCountChart(props) {
    const {isFetchLoading, dataName, dataCount, fetchData} = props;

    const [triggerFetch, setTriggerFetch] = useState(true);

    useEffect(() => {
        if(triggerFetch) {
            fetchData();
            setTriggerFetch(false);
        }
    }, [triggerFetch, fetchData]);

    return (
        <React.Fragment>
            <Card variant="outlined">
                <Fade in={isFetchLoading}>
                    <LinearProgress />
                </Fade>
                <CardContent>
                    <Typography variant="h5" align="center">
                        {dataName}
                    </Typography>
                    <Typography variant="h6" align="center">
                        {dataCount}
                    </Typography>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}