import { all } from 'redux-saga/effects';
import login from './login';
import logout from './logout';
import campaignList from './campaignList';
import createCampaign from './createCampaign';
import availableCampaignList from './availableCampaignList';
import availableVoucherCode from './availableVoucherCode';
import createVoucher from './createVoucher';
import voucherList from './voucherList';
import updateCampaign from './updateCampaign';
import getCampaign from './getCampaign';
import userList from './userList';
import createUser from './createUser';
import voucherReportList from './voucherReportList';
import voucherConsumptionReportList from './voucherConsumptionReportList';
import voucherPatch from './voucherPatch';
import dashboardConsumptionCount from './dashboardConsumptionCount';
import voucherGetId from './voucherGetId';
import voucherUpdateInfo from './voucherUpdateInfo';
import voucherUpdateRule from './voucherUpdateRule';
import dashboardActiveCampaignCount from './dashboardActiveCampaignCount';
import dashboardActiveVoucherCount from './dashboardActiveVoucherCount';
import dashboardCampaignStatusCount from './dashboardCampaignStatusCount';
import dashboardVoucherStatusCount from './dashboardVoucherStatusCount';
import widgetGetUserWidgetList from './widgetGetUserWidgetList';
import widgetUpdateUserWidgetList from './widgetUpdateUserWidgetList';
import userUpdate from './userUpdate';
import userUpdatePassword from './userUpdatePassword';
import userById from './userById';
import getBulkVoucher from './getBulkVoucher';
import updateBulkVoucher from './updateBulkVoucher';

export default function* rootSaga() {
  yield all([
    login(),
    logout(),
    campaignList(),
    createCampaign(),
    availableCampaignList(),
    availableVoucherCode(),
    createVoucher(),
    voucherList(),
    updateCampaign(),
    getCampaign(),
    userList(),
    createUser(),
    voucherReportList(),
    voucherConsumptionReportList(),
    voucherPatch(),
    dashboardConsumptionCount(),
    voucherGetId(),
    voucherUpdateInfo(),
    voucherUpdateRule(),
    dashboardActiveCampaignCount(),
    dashboardActiveVoucherCount(),
    dashboardCampaignStatusCount(),
    dashboardVoucherStatusCount(),
    widgetGetUserWidgetList(),
    widgetUpdateUserWidgetList(),
    userUpdate(),
    userUpdatePassword(),
    userById(),
    getBulkVoucher(),
    updateBulkVoucher()
  ]);
}
