import {USER_LIST_ACTION_TYPE as ACTION} from './actionTypes';

export const userListRequest = (data) =>  {
    return {
        type: ACTION.USER_LIST_REQUEST,
        data
    }
}

export const userListSuccess = (response) =>  {
    return {
        type: ACTION.USER_LIST_SUCCESS,
        response
    }
  }

export const userListFailed = (error) =>  {
    return {
        type: ACTION.USER_LIST_FAILED,
        error
    }
}