import { combineReducers } from 'redux'
import auth from './auth';
import user from './user';
import campaignList from './campaignList';
import createCampaign from './createCampaign';
import createVoucher from './createVoucher';
import availableCampaignList from './availableCampaignList';
import availableVoucherCode from './availableVoucherCode';
import voucherList from './voucherList';
import updateCampaign from './updateCampaign';
import getCampaign from './getCampaign';
import userList from './userList';
import userCreate from './userCreate';
import voucherReportList from './voucherReportList';
import voucherConsumptionReportList from './voucherConsumptionReportList';
import patchVoucher from './voucherPatch';
import dashboardConsumptionCount from './dashboardConsumptionCount';
import voucherGetId from './voucherGetId';
import voucherUpdateInfo from './voucherUpdateInfo';
import voucherUpdateRule from './voucherUpdateRule';
import dashboardActiveCampaignCount from './dashboardActiveCampaignCount';
import dashboardActiveVoucherCount from './dashboardActiveVoucherCount';
import dashboardCampaignStatusCount from './dashboardCampaignStatusCount';
import dashboardVoucherStatusCount from './dashboardVoucherStatusCount';
import widgetGetUserWidgetList from './widgetGetUserWidgetList';
import widgetUpdateUserWidgetList from './widgetUpdateUserWidgetList';
import userUpdate from './userUpdate';
import userUpdatePassword from './userUpdatePassword';
import userById from './userById';
import getBulkVoucher from './getBulkVoucher';
import updateBulkVoucher from './updateBulkVoucher';

export default combineReducers({
  auth: auth,
  user: user,
  campaignList: campaignList,
  availableCampaignList: availableCampaignList,
  createCampaign: createCampaign,
  createVoucher: createVoucher,
  availableVoucherCode: availableVoucherCode,
  voucherList: voucherList,
  updateCampaign: updateCampaign,
  getCampaign: getCampaign,
  userList: userList,
  userCreate: userCreate,
  voucherReportList: voucherReportList,
  voucherConsumptionReportList: voucherConsumptionReportList,
  patchVoucher: patchVoucher,
  dashboardConsumptionCount: dashboardConsumptionCount,
  voucherGetId: voucherGetId,
  voucherUpdateInfo: voucherUpdateInfo,
  voucherUpdateRule: voucherUpdateRule,
  dashboardActiveCampaignCount: dashboardActiveCampaignCount,
  dashboardActiveVoucherCount: dashboardActiveVoucherCount,
  dashboardCampaignStatusCount: dashboardCampaignStatusCount,
  dashboardVoucherStatusCount: dashboardVoucherStatusCount,
  widgetGetUserWidgetList: widgetGetUserWidgetList,
  widgetUpdateUserWidgetList: widgetUpdateUserWidgetList,
  userUpdate: userUpdate,
  userUpdatePassword: userUpdatePassword,
  userById: userById,
  getBulkVoucher: getBulkVoucher,
  updateBulkVoucher: updateBulkVoucher
});