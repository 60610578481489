import {DASHBOARD_CAMPAIGN_STATUS_COUNT_ACTION_TYPE as ACTION} from './actionTypes';

export const dashboardCampaignStatusCountRequest = (data) =>  {
    return {
        type: ACTION.DASHBOARD_CAMPAIGN_STATUS_COUNT_REQUEST,
        data
    }
}

export const dashboardCampaignStatusCountSuccess = (response) =>  {
    return {
        type: ACTION.DASHBOARD_CAMPAIGN_STATUS_COUNT_SUCCESS,
        response
    }
  }

export const dashboardCampaignStatusCountFailed = (error) =>  {
    return {
        type: ACTION.DASHBOARD_CAMPAIGN_STATUS_COUNT_FAILED,
        error
    }
}