import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {userByIdSuccess, userByIdFailed} from '../actions/userById';
import { USER_GET_BY_ID_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* getUserById({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.USER + '/' + data.id, null);
    if(responseData.status === 200){
      yield put(userByIdSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(userByIdFailed(responseData));
    }
  } catch (error) {
    yield put(userByIdFailed(error));
  }
}
  
function* watchGetUserById() {
  yield takeLatest(ACTION.USER_GET_BY_ID_REQUEST, getUserById);
}

export default function* root() {
  yield fork(watchGetUserById);
}
  