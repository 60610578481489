import React, {useState, useEffect} from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import {Bar} from 'react-chartjs-2';

import {useSelector, useDispatch} from 'react-redux';

import {dashboardConsumptionCountRequest} from '../../../redux/actions/dashboardConsumptionCount';

export default function ConsumptionCount(props) {
    const [data, setData] = useState({
        labels: [],
        datasets: [
          {
            label: 'Count',
            borderWidth: 1,
            backgroundColor: '#556cd680',
            data: []
          }
        ]
      });
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.dashboardConsumptionCount.meta.isLoading);
    const consumptions = useSelector(state => state.dashboardConsumptionCount.consumptions);

    const [initValue, setInitValue] = useState(false);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if(!initValue) {
            dispatch(dashboardConsumptionCountRequest());
            setInitValue(true);
        } 
    }, [initValue, dispatch]);

    useEffect(() => {
        if(consumptions && consumptions.length && !loaded) {
            let labelResult = [];
            let dataResult = [];

            consumptions.forEach(consumption => {
                labelResult.push(consumption.date);
                dataResult.push(consumption.count);
            });

            setData(prevState => ({
                ...prevState,
                labels: labelResult,
                datasets: [
                    {
                        label: 'Count',
                        borderWidth: 1,
                        backgroundColor: '#556cd680',
                        data: dataResult
                    }
                ]
            }));

            setLoaded(true);
        }
    }, [consumptions, data, loaded, setLoaded]);

    const options = {
        responsive: true,
        legend: {
            position: 'bottom',
        },
        title: {
            display: true,
            text: 'Consumption Count'
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Date'
                }
            }],
            yAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Count'
                }
            }]
        }
    }

    return (
        <React.Fragment>
            <Paper elevation={2}>
                <Fade in={isLoading}>
                    <LinearProgress />
                </Fade>
                <Box m={2}>
                    <Bar 
                        data={data} 
                        options={options}/>
                </Box>
            </Paper>
        </React.Fragment>
    );
}

