import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';


import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import ErrorSnackBar from '../../Utilities/ErrorSnackBar';

import {useDispatch, useSelector} from 'react-redux';
import {userByIdRequest} from '../../../redux/actions/userById';
import {userUpdateRequest} from '../../../redux/actions/userUpdate';

const roleMap = [
    {
        id: 'ADMIN',
        value: 'Admin'
    },
    {
        id: 'APPROVER',
        value: 'Approver'
    },
    {
        id: 'CREATOR',
        value: 'Creator'
    }
]

const useStyle = makeStyles(themes => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        minWidth: 120,
    }
}));

export default function UpdateUser({match}) {
    const classes = useStyle();
    const {
        params: { userId },
    } = match;

    const [initValue, setInitValue] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const dispatch = useDispatch();

    const [user, setUser] = useState({
        name: '',
        ad_username: '',
        role: '',
        description: ''
    });

    const getUserLoading = useSelector(state => state.userById.isLoading);
    const updateUser = useSelector(state => state.userById.user);
    const currentUser = useSelector(state => state.user);

    const isUpdating = useSelector(state => state.userUpdate.isLoading);
    const userUpdateMeta = useSelector(state => state.userUpdate.meta);

    const handleRoleChange = (e) => {
        const value = e.target.value;
        setUser(prevState => ({...prevState, role: value}));
    }

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setUser(prevState => ({...prevState, description: value}));
    }

    const handleSubmitClick = (e) => {
        const updateData = {
            id: userId,
            description: user.description,
            role: user.role
        }
        setSubmit(true);
        dispatch(userUpdateRequest(updateData));
    }

    useEffect(() => {
        if(initValue) {
            setInitValue(false);
            dispatch(userByIdRequest({id : userId}))
        }
    }, [initValue, dispatch, userId]);

    useEffect(() => {
        if(!initValue && !getUserLoading && !dataLoaded) {
            setUser({
                name: updateUser.name,
                ad_username: updateUser.ad_username,
                description: (updateUser.description ? updateUser.description : ''),
                role: updateUser.role
            });
            setDataLoaded(true);
        }
    }, [updateUser, getUserLoading, initValue, dataLoaded]);

    useEffect(() => {
        if(submit && !isUpdating) {
            if(!userUpdateMeta.success) {
                setErrorMessage(userUpdateMeta.errorMsg);
                setOpen(true);
            }
            setSubmit(false);
        }
    }, [submit, isUpdating, userUpdateMeta]);

    if(userUpdateMeta.success) { return (<Redirect to="/users"></Redirect>)}

    return currentUser.role !=='ADMIN' ? <Redirect to="/dashboard"></Redirect> : (
        <div>
            <Fade in={getUserLoading || isUpdating}>
                <LinearProgress />
            </Fade>
            <Box m={3}>
                <Typography variant="h5">
                    Edit User
                </Typography>
            </Box>
            <Box m={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                            id="name"
                            label="Name"
                            value={user.name}
                            fullWidth
                            className={classes.textField}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                            id="username"
                            label="Username"
                            value={user.ad_username}
                            fullWidth
                            className={classes.textField}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="roleLabel">Role</InputLabel>
                            <Select
                                id="role"
                                value={user.role}
                                onChange={handleRoleChange}
                                disabled={user.role === 'ADMIN'}
                            >
                                {roleMap.map(role => (
                                    <MenuItem key={role.id} value={role.id}>{role.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            label="Description"
                            value={user.description}
                            fullWidth
                            className={classes.textField}
                            onChange={handleDescriptionChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Button color="primary" variant="contained" fullWidth={true} onClick={handleSubmitClick}>Update</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Button color="secondary" variant="contained" fullWidth={true} href="/users">Back</Button>
                    </Grid>
                </Grid>
            </Box>
            <ErrorSnackBar
                open={open}
                setOpen={setOpen}
                hideDuration={3000}
                errorMessage={errorMessage}
            >
            </ErrorSnackBar>
        </div>);
}