import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {loginSuccess, loginFailed} from '../actions/auth';
import {userLoginSuccess} from '../actions/user';
import { AUTH_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doPost } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';

export function* login({data}) {
  try {
    const responseData = yield call(doPost, ENDPOINT.LOGIN, data);
    if(responseData.status === 200){
      yield put(loginSuccess(responseData));
      yield put(userLoginSuccess({
        name: responseData.data.user.name,
        role: responseData.data.user.role,
        token: responseData.data.user.access_token
      }));
    } else {
      yield put(loginFailed(responseData));
    }
  } catch (error) {
    yield put(loginFailed(error));
  }
}
  
function* watchLogin(){
  yield takeLatest(ACTION.LOGIN_REQUEST, login);
}

export default function* root() {
  yield fork(watchLogin);
}
  