import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import VisibilityIcon from '@material-ui/icons/Visibility';

import ErrorSnackBar from '../../Utilities/ErrorSnackBar';

import {voucherReportListRequest} from '../../../redux/actions/voucherReportList';
import SortableTableHead from '../../Table/SortableTableHead';

const useStyle = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    paper: {
      marginTop: theme.spacing(3),
      width: '100%',
      overflowX: 'auto',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
    createButton: {
        float: 'right'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    }
  }));

const cleanFilter = {
    campaign_id: '',
    campaign_name: '',
    voucher_code: '',
    voucher_name: ''
}

const tableCellHeader = [
    {
      id: 'campaign_id',
      label: 'Campaign ID',
      sortable: true
    },
    {
      id: 'campaign_name',
      label: 'Campaign Name',
      sortable: true
    },
    {
      id: 'name',
      label: 'Name',
      sortable: true
    },
    {
      id: 'code',
      label: 'Code',
      sortable: true
    },
    {
      id: 'description',
      label: 'Description',
      sortable: true
    },
    {
      id: 'consumption_count',
      label: 'Consumption Count',
      sortable: true
    },
    {
      id: 'action',
      label: 'Action',
      sortable: false
    }
  ]
  
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    let result = stabilizedThis.map((el) => el[0]);
    return result;
  }
  
export default function VoucherSummary(props) {
    const classes = useStyle();

    const dispatch = useDispatch();
    const [initList, setinitList] = useState(true);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [filter, setFilter] = useState(cleanFilter);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
  
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handlePageChange = (event, newPage) => {
      setPage(newPage);
    }
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const isLoading = useSelector(state => state.voucherReportList.isLoading);
    const meta = useSelector(state => state.voucherReportList.meta);
    const vouchers = useSelector(state => state.voucherReportList.vouchers);

    useEffect(() => {setPage(0);}, [vouchers]);

    const createFilterRequest = () => {
        let filterResult = {}
        let filter_flag = false;

        if(filter.campaign_id) {
            filter_flag = true;
            filterResult.campaign_id = filter.campaign_id;
        }
        if(filter.campaign_name) {
            filter_flag = true;
            filterResult.campaign_name = filter.campaign_name;
        }
        if(filter.voucher_code) {
            filter_flag = true;
            filterResult.voucher_code = filter.voucher_code;
        }
        if(filter.voucher_name) {
            filter_flag = true;
            filterResult.voucher_name = filter.voucher_name;
        }

        return {
            filter: filterResult,
            filter_flag: filter_flag
        }
    }

    const handleCampaignIdChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, campaign_id: value}));
    }

    const handleCampaignNameChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, campaign_name: value}));
    }

    const handleVoucherNameChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, voucher_name: value}));
    }
    
    const handleVoucherCodeChange = (e) => {
        const value = e.target.value;
        setFilter(prevState => ({...prevState, voucher_code: value}));
    }

    const handleApplyFilterButtonClicked = (e) => {
        let request = createFilterRequest();
        if(request.filter_flag) {
            dispatch(voucherReportListRequest(request.filter));
        } else {
            dispatch(voucherReportListRequest());
        }
    }

    const handleClearFilterButtonClicked = (e) => {
        setFilter(cleanFilter);
    }

    useEffect(() => {
        if(initList) {
            dispatch(voucherReportListRequest());
            setinitList(false);
        }
    }
    , [dispatch, initList]);

    useEffect(() => {
        if(meta) {
            if(meta.errorMsg) {
                setErrorMessage(meta.errorMsg);
                setOpen(true);
            }
        }
    }, [meta]);

    return (
        <div>
            <Fade in={isLoading}>
              <LinearProgress />
            </Fade>
            <Box m={3}>
                <Grid container >
                    <Grid item xs={8}>
                    <Typography variant="h5">
                        Voucher Report
                    </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Paper elevation={2}>
                            <Box m={2}>
                                <Box>
                                    <Typography variant="subtitle1">
                                        Filter
                                    </Typography>
                                </Box>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="campaignId"
                                                label="Campaign ID"
                                                value={filter.campaign_id}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleCampaignIdChange}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="campaignName"
                                                label="Campaign Name"
                                                value={filter.campaign_name}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleCampaignNameChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="voucherName"
                                                label="Voucher Name"
                                                value={filter.voucher_name}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleVoucherNameChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="voucherCode"
                                                label="Voucher Code"
                                                value={filter.voucher_code}
                                                fullWidth
                                                className={classes.textField}
                                                onChange={handleVoucherCodeChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button color="primary" variant="contained" fullWidth onClick={handleApplyFilterButtonClicked}>Apply Filter</Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" fullWidth onClick={handleClearFilterButtonClicked}>Clear Filter</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                        <div className={classes.root}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <SortableTableHead classes={classes} order={order} orderBy={orderBy} headCells={tableCellHeader} onRequestSort={handleRequestSort}>
                                    </SortableTableHead>
                                    <TableBody>
                                        {stableSort(vouchers, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{row.campaign_id}</TableCell>
                                                <TableCell>{row.campaign_name}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.code}</TableCell>
                                                <TableCell>{row.description}</TableCell>
                                                <TableCell>{row.consumption_count}</TableCell>
                                                <TableCell>
                                                    <IconButton size="small" href={'/reports/vouchers/consumptions/' + row.id}>
                                                        <VisibilityIcon color="primary"/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 20]}
                                            colSpan={tableCellHeader.length}
                                            count={vouchers.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                            }}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            
            <ErrorSnackBar
                open={open}
                setOpen={setOpen}
                hideDuration={3000}
                errorMessage={errorMessage}
            >
            </ErrorSnackBar>
        </div>
        
    )
}