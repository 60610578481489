import React, { PureComponent } from 'react';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect
  } from 'react-router-dom';
import { getStore } from './redux/store/storeConfig';
import { getUser } from './redux/selectors/user';

import AppMenuBar from './components/AppBar/MenuAppBar';

import Dashboard from './components/Dashboard/Dashboard';
import EditDashboard from './components/Dashboard/EditDashboard';
import Login from './components/Auth/Login';
import CampaignList from './components/Campaign/CampaignList/CampaignList';
import EditCampaign from './components/Campaign/EditCampaign/EditCampaign';
import CreateCampaign from './components/Campaign/CreateCampaign/CreateCampaign';
import VoucherList from './components/Vouchers/VoucherList/VoucherList';
import CreateVoucher from './components/Vouchers/CreateVoucher/CreateVoucher';
import UserList from './components/Users/UserList/UserList';
import CreateUser from './components/Users/CreateUser/CreateUser';
import VoucherSummary from './components/Reports/VoucherSummary/VoucherSummary';
import VoucherConsumption from './components/Reports/VoucherConsumption/VoucherConsumption';

import EditVoucher from './components/Vouchers/EditVoucher/EditVoucher';

import EditUser from './components/Users/EditUser/EditUser';
import VoucherBulk from './components/VoucherBulk/VoucherBulk';

import ChangePassword from './components/Users/ChangePassword/ChangePassword';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const store = getStore();
  const userData = getUser(store);
  const authenticated = userData.authenticated;
  return (  
    <Route { ...rest } render={props => (
      authenticated ? (
        <Component { ...props } />
      ) : (
        <Redirect to="/"
        />
      )
    )} />
  );
}

const AuthenticatedContainer = () => (
  <div>
    <AppMenuBar />
    <PrivateRoute exact={true} path='/dashboard' component={Dashboard} />
    <PrivateRoute exact={true} path='/dashboard/edit' component={EditDashboard} />

    <PrivateRoute exact={true} path='/campaigns' component={CampaignList} />
    <PrivateRoute exact={true} path='/campaigns/create' component={CreateCampaign} />
    <PrivateRoute path='/campaigns/details/:campaignId' component={EditCampaign} />

    <PrivateRoute exact={true} path='/vouchers' component={VoucherList} />
    <PrivateRoute exact={true} path='/vouchers/create' component={CreateVoucher} />
    <PrivateRoute path='/vouchers/details/:voucherId' component={EditVoucher} />

    <PrivateRoute exact={true} path='/users' component={UserList} />
    <PrivateRoute exact={true} path='/users/create' component={CreateUser} />
    <PrivateRoute exact={true} path='/users/edit/:userId' component={EditUser} />

    <PrivateRoute exact={true} path='/reports/vouchers' component={VoucherSummary} />
    <PrivateRoute path='/reports/vouchers/consumptions/:voucherId' component={VoucherConsumption} />

    <PrivateRoute path='/voucherbulk' component={VoucherBulk} />
    <PrivateRoute path='/changepassword' component={ChangePassword} />
  </div>
)

class App extends PureComponent {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Switch>
            <Route path='/' exact={true} render={() => {return <Redirect to="/login" />; }} />
            <Route path='/login' component={Login} />
            <PrivateRoute component={AuthenticatedContainer}/>
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}
  
  export default App;
