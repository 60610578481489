export const AUTH_ACTION_TYPE = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILED: 'LOGOUT_FAILED'
};

export const USER_ACTION_TYPE = {
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS'
}

export const CAMPAIGN_LIST_ACTION_TYPE = {
  CAMPAIGN_LIST_REQUEST: 'CAMPAIGN_LIST_REQUEST',
  CAMPAIGN_LIST_SUCCESS: 'CAMPAIGN_LIST_SUCCESS',
  CAMPAIGN_LIST_FAILED: 'CAMPAIGN_LIST_FAILED'
}

export const AVAILABLE_CAMPAIGN_LIST_ACTION_TYPE = {
  AVAILABLE_CAMPAIGN_LIST_REQUEST: 'AVAILABLE_CAMPAIGN_LIST_REQUEST',
  AVAILABLE_CAMPAIGN_LIST_SUCCESS: 'AVAILABLE_CAMPAIGN_LIST_SUCCESS',
  AVAILABLE_CAMPAIGN_LIST_FAILED: 'AVAILABLE_CAMPAIGN_LIST_FAILED'
}

export const CAMPAIGN_CREATE_ACTION_TYPE = {
  CAMPAIGN_CREATE_REQUEST: 'CAMPAIGN_CREATE_REQUEST',
  CAMPAIGN_CREATE_SUCCESS: 'CAMPAIGN_CREATE_SUCCESS',
  CAMPAIGN_CREATE_FAILED: 'CAMPAIGN_CREATE_FAILED'
}

export const VOUCHER_CREATE_ACTION_TYPE = {
  VOUCHER_CREATE_REQUEST: 'VOUCHER_CREATE_REQUEST',
  VOUCHER_CREATE_SUCCESS: 'VOUCHER_CREATE_SUCCESS',
  VOUCHER_CREATE_FAILED: 'VOUCHER_CREATE_FAILED'
}

export const GET_AVAILABLE_VOUCHER_CODE_ACTION_TYPE = {
  GET_AVAILABLE_VOUCHER_CODE_REQUEST: 'GET_AVAILABLE_VOUCHER_CODE_REQUEST',
  GET_AVAILABLE_VOUCHER_CODE_SUCCESS: 'GET_AVAILABLE_VOUCHER_CODE_SUCCESS',
  GET_AVAILABLE_VOUCHER_CODE_FAILED: 'GET_AVAILABLE_VOUCHER_CODE_ERROR'
}

export const VOUCHER_LIST_ACTION_TYPE = {
  VOUCHER_LIST_REQUEST: 'VOUCHER_LIST_REQUEST',
  VOUCHER_LIST_SUCCESS: 'VOUCHER_LIST_SUCCESS',
  VOUCHER_LIST_FAILED: 'VOUCHER_LIST_FAILED'
}

export const CAMPAIGN_UPDATE_ACTION_TYPE = {
  CAMPAIGN_UPDATE_REQUEST: 'CAMPAIGN_UPDATE_REQUEST',
  CAMPAIGN_UPDATE_SUCCESS: 'CAMPAIGN_UPDATE_SUCCESS',
  CAMPAIGN_UPDATE_FAILED: 'CAMPAIGN_UPDATE_FAILED'
}

export const CAMPAIGN_GET_ACTION_TYPE = {
  CAMPAIGN_GET_REQUEST: 'CAMPAIGN_GET_REQUEST',
  CAMPAIGN_GET_SUCCESS: 'CAMPAIGN_GET_SUCCESS',
  CAMPAIGN_GET_FAILED: 'CAMPAIGN_GET_FAILED'
}

export const USER_LIST_ACTION_TYPE = {
  USER_LIST_REQUEST: 'USER_LIST_REQUEST',
  USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
  USER_LIST_FAILED: 'USER_LIST_FAILED'
}

export const USER_CREATE_ACTION_TYPE = {
  USER_CREATE_REQUEST: 'USER_CREATE_REQUEST',
  USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  USER_CREATE_FAILED: 'USER_CREATE_FAILED'
}

export const VOUCHER_LIST_REPORT_ACTION_TYPE = {
  VOUCHER_LIST_REPORT_REQUEST: 'VOUCHER_LIST_REPORT_REQUEST',
  VOUCHER_LIST_REPORT_SUCCESS: 'VOUCHER_LIST_REPORT_SUCCESS',
  VOUCHER_LIST_REPORT_FAILED: 'VOUCHER_LIST_REPORT_FAILED'
}

export const VOUCHER_CONSUMPTION_LIST_REPORT_ACTION_TYPE = {
  VOUCHER_CONSUMPTION_LIST_REPORT_REQUEST: 'VOUCHER_CONSUMPTION_LIST_REPORT_REQUEST',
  VOUCHER_CONSUMPTION_LIST_REPORT_SUCCESS: 'VOUCHER_CONSUMPTION_LIST_REPORT_SUCCESS',
  VOUCHER_CONSUMPTION_LIST_REPORT_FAILED: 'VOUCHER_CONSUMPTION_LIST_REPORT_FAILED'
}

export const VOUCHER_PATCH_ACTION_TYPE = {
  VOUCHER_PATCH_REQUEST: 'VOUCHER_PATCH_REQUEST',
  VOUCHER_PATCH_SUCCESS: 'VOUCHER_PATCH_SUCCESS',
  VOUCHER_PATCH_FAILED: 'VOUCHER_PATCH_FAILED'
}

export const DASHBOARD_CONSUMPTION_COUNT_ACTION_TYPE = {
  DASHBOARD_CONSUMPTION_COUNT_REQUEST: 'DASHBOARD_CONSUMPTION_COUNT_REQUEST',
  DASHBOARD_CONSUMPTION_COUNT_SUCCESS: 'DASHBOARD_CONSUMPTION_COUNT_SUCCESS',
  DASHBOARD_CONSUMPTION_COUNT_FAILED: 'DASHBOARD_CONSUMPTION_COUNT_FAILED'
}

export const VOUCHER_GET_BY_ID_ACTION_TYPE = {
  VOUCHER_GET_BY_ID_REQUEST: 'VOUCHER_GET_BY_ID_REQUEST',
  VOUCHER_GET_BY_ID_SUCCESS: 'VOUCHER_GET_BY_ID_SUCCESS',
  VOUCHER_GET_BY_ID_FAILED: 'VOUCHER_GET_BY_ID_FAILED'
}

export const VOUCHER_UPDATE_INFO_ACTION_TYPE = {
  VOUCHER_UPDATE_INFO_REQUEST: 'VOUCHER_UPDATE_INFO_REQUEST',
  VOUCHER_UPDATE_INFO_SUCCESS: 'VOUCHER_UPDATE_INFO_SUCCESS',
  VOUCHER_UPDATE_INFO_FAILED: 'VOUCHER_UPDATE_INFO_FAILED'
}

export const VOUCHER_UPDATE_RULE_ACTION_TYPE = {
  VOUCHER_UPDATE_RULE_REQUEST: 'VOUCHER_UPDATE_RULE_REQUEST',
  VOUCHER_UPDATE_RULE_SUCCESS: 'VOUCHER_UPDATE_RULE_SUCCESS',
  VOUCHER_UPDATE_RULE_FAILED: 'VOUCHER_UPDATE_RULE_FAILED'
}

export const DASHBOARD_ACTIVE_CAMPAIGN_COUNT_ACTION_TYPE = {
  DASHBOARD_ACTIVE_CAMPAIGN_COUNT_REQUEST: 'DASHBOARD_ACTIVE_CAMPAIGN_COUNT_REQUEST',
  DASHBOARD_ACTIVE_CAMPAIGN_COUNT_SUCCESS: 'DASHBOARD_ACTIVE_CAMPAIGN_COUNT_SUCCESS',
  DASHBOARD_ACTIVE_CAMPAIGN_COUNT_FAILED: 'DASHBOARD_ACTIVE_CAMPAIGN_COUNT_FAILED'
}

export const DASHBOARD_ACTIVE_VOUCHER_COUNT_ACTION_TYPE = {
  DASHBOARD_ACTIVE_VOUCHER_COUNT_REQUEST: 'DASHBOARD_ACTIVE_VOUCHER_COUNT_REQUEST',
  DASHBOARD_ACTIVE_VOUCHER_COUNT_SUCCESS: 'DASHBOARD_ACTIVE_VOUCHER_COUNT_SUCCESS',
  DASHBOARD_ACTIVE_VOUCHER_COUNT_FAILED: 'DASHBOARD_ACTIVE_VOUCHER_COUNT_FAILED'
}

export const DASHBOARD_CAMPAIGN_STATUS_COUNT_ACTION_TYPE = {
  DASHBOARD_CAMPAIGN_STATUS_COUNT_REQUEST: 'DASHBOARD_CAMPAIGN_STATUS_COUNT_REQUEST',
  DASHBOARD_CAMPAIGN_STATUS_COUNT_SUCCESS: 'DASHBOARD_CAMPAIGN_STATUS_COUNT_SUCCESS',
  DASHBOARD_CAMPAIGN_STATUS_COUNT_FAILED: 'DASHBOARD_CAMPAIGN_STATUS_COUNT_FAILED'
}

export const DASHBOARD_VOUCHER_STATUS_COUNT_ACTION_TYPE = {
  DASHBOARD_VOUCHER_STATUS_COUNT_REQUEST: 'DASHBOARD_VOUCHER_STATUS_COUNT_REQUEST',
  DASHBOARD_VOUCHER_STATUS_COUNT_SUCCESS: 'DASHBOARD_VOUCHER_STATUS_COUNT_SUCCESS',
  DASHBOARD_VOUCHER_STATUS_COUNT_FAILED: 'DASHBOARD_VOUCHER_STATUS_COUNT_FAILED'
}

export const WIDGET_GET_USER_WIDGET_LIST_ACTION_TYPE = {
  WIDGET_GET_USER_WIDGET_LIST_REQUEST: 'WIDGET_GET_USER_WIDGET_LIST_REQUEST',
  WIDGET_GET_USER_WIDGET_LIST_SUCCESS: 'WIDGET_GET_USER_WIDGET_LIST_SUCCESS',
  WIDGET_GET_USER_WIDGET_LIST_FAILED: 'WIDGET_GET_USER_WIDGET_LIST_FAILED'
}

export const WIDGET_UPDATE_USER_WIDGET_LIST_ACTION_TYPE = {
  WIDGET_UPDATE_USER_WIDGET_LIST_REQUEST: 'WIDGET_UPDATE_USER_WIDGET_LIST_REQUEST',
  WIDGET_UPDATE_USER_WIDGET_LIST_SUCCESS: 'WIDGET_UPDATE_USER_WIDGET_LIST_SUCCESS',
  WIDGET_UPDATE_USER_WIDGET_LIST_FAILED: 'WIDGET_UPDATE_USER_WIDGET_LIST_FAILED'
}

export const USER_UPDATE_ACTION_TYPE = {
  USER_UPDATE_REQUEST:  'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS:  'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILED:  'USER_UPDATE_FAILED'
}

export const USER_UPDATE_PASSWORD_ACTION_TYPE = {
  USER_UPDATE_PASSWORD_REQUEST:  'USER_UPDATE_PASSWORD_REQUEST',
  USER_UPDATE_PASSWORD_SUCCESS:  'USER_UPDATE_PASSWORD_SUCCESS',
  USER_UPDATE_PASSWORD_FAILED:  'USER_UPDATE_PASSWORD_FAILED'
}

export const USER_GET_BY_ID_ACTION_TYPE = {
  USER_GET_BY_ID_REQUEST:  'USER_GET_BY_ID_REQUEST',
  USER_GET_BY_ID_SUCCESS:  'USER_GET_BY_ID_SUCCESS',
  USER_GET_BY_ID_FAILED:  'USER_GET_BY_ID_FAILED'
}

export const GET_VOUCHER_BULK_ACTION_TYPE = {
  GET_VOUCHER_BULK_REQUEST:  'GET_VOUCHER_BULK_REQUEST',
  GET_VOUCHER_BULK_SUCCESS:  'GET_VOUCHER_BULK_SUCCESS',
  GET_VOUCHER_BULK_FAILED:  'GET_VOUCHER_BULK_FAILED'
}

export const UPDATE_VOUCHER_BULK_ACTION_TYPE = {
  UPDATE_VOUCHER_BULK_REQUEST:  'UPDATE_VOUCHER_BULK_REQUEST',
  UPDATE_VOUCHER_BULK_SUCCESS:  'UPDATE_VOUCHER_BULK_SUCCESS',
  UPDATE_VOUCHER_BULK_FAILED:  'UPDATE_VOUCHER_BULK_FAILED'
}

export const INITIAL_STATE = {
  isLoading: false,
  meta: {
    status: false,
    errorCode: '',
    errorMsg: ''
  }
};
