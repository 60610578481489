import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {userListSuccess, userListFailed} from '../actions/userList';
import { USER_LIST_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* userList({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.USER, null);
    if(responseData.status === 200){
      yield put(userListSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(userListFailed(responseData));
    }
  } catch (error) {
    yield put(userListFailed(error));
  }
}
  
function* watchUserList() {
  yield takeLatest(ACTION.USER_LIST_REQUEST, userList);
}

export default function* root() {
  yield fork(watchUserList);
}
  