import {CAMPAIGN_UPDATE_ACTION_TYPE as ACTION} from './actionTypes';

export const updateCampaignRequest = (data) =>  {
    return {
        type: ACTION.CAMPAIGN_UPDATE_REQUEST,
        data
    }
}

export const updateCampaignSuccess = (response) =>  {
    return {
        type: ACTION.CAMPAIGN_UPDATE_SUCCESS,
        response
    }
  }

export const updateCampaignFailed = (error) =>  {
    return {
        type: ACTION.CAMPAIGN_UPDATE_FAILED,
        error
    }
}