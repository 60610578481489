import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

import {useDispatch, useSelector} from 'react-redux';
import {userListRequest} from '../../../redux/actions/userList';
import {userUpdateRequest} from '../../../redux/actions/userUpdate';

import ErrorSnackBar from '../../Utilities/ErrorSnackBar';

import UserListTable from './UserListTable';
import { Redirect } from 'react-router-dom';

const useStyle = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    paper: {
      marginTop: theme.spacing(3),
      width: '100%',
      overflowX: 'auto',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
    createButton: {
        float: 'right'
    }
  }));

export default function UserList(props) {
    const classes = useStyle();

    const [initValue, setInitValue] = useState(true);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrormessage] = useState('');
    const [submit, setSubmit] = useState(false);

    const isLoading = useSelector(state => state.userList.isLoading);
    const currentUser = useSelector(state => state.user);
    const users = useSelector(state => state.userList.users);

    const isUpdating = useSelector(state => state.userUpdate.isLoading);
    const userUpdateMeta = useSelector(state => state.userUpdate.meta);

    const dispatch = useDispatch();

    const updateUserStatus = (userId, enabled) => {
        setSubmit(true);
        dispatch(userUpdateRequest({
            id: userId,
            enabled: enabled
        }));
    }

    useEffect(() => {
        if(initValue) {
            setInitValue(false);
            dispatch(userListRequest());
        }
    }, [users, initValue, dispatch]);

    useEffect(() => {
        if(submit && !isUpdating) {
            if(userUpdateMeta.success) {
                setInitValue(true);
            } else {
                setErrormessage(userUpdateMeta.errorMsg);
                setOpen(true);
            }
            setSubmit(false);
        }
    }, [submit, isUpdating, userUpdateMeta, open]);

    return currentUser.role !=='ADMIN' ? <Redirect to="/dashboard"></Redirect> : (
        <div>
            <Fade in={isLoading || isUpdating}>
              <LinearProgress />
            </Fade>
            <Box m={3}>
                <Grid container >
                    <Grid item xs={8}>
                    <Typography variant="h5">
                        User List
                    </Typography>
                    </Grid>
                    {currentUser.role === 'ADMIN' ? 
                    <Grid item xs={4}>
                        <div className={classes.createButton}>
                        <Button color="primary" variant="contained" href="/users/create">Create User</Button>
                        </div>
                    </Grid> : null}
                </Grid>
            </Box>
            <UserListTable users={users} classes={classes} updateUserStatus={updateUserStatus}></UserListTable>
            <ErrorSnackBar
                open={open}
                setOpen={setOpen}
                hideDuration={3000}
                errorMessage={errorMessage}
            >
            </ErrorSnackBar>
        </div>
    );

}