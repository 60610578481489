import {USER_UPDATE_PASSWORD_ACTION_TYPE as ACTION} from './actionTypes';

export const userUpdatePasswordRequest = (data) =>  {
    return {
        type: ACTION.USER_UPDATE_PASSWORD_REQUEST,
        data
    }
}

export const userUpdatePasswordSuccess = (response) =>  {
    return {
        type: ACTION.USER_UPDATE_PASSWORD_SUCCESS,
        response
    }
  }

export const userUpdatePasswordFailed = (error) =>  {
    return {
        type: ACTION.USER_UPDATE_PASSWORD_FAILED,
        error
    }
}