import {DASHBOARD_VOUCHER_STATUS_COUNT_ACTION_TYPE as ACTION} from './actionTypes';

export const dashboardVoucherStatusCountRequest = (data) =>  {
    return {
        type: ACTION.DASHBOARD_VOUCHER_STATUS_COUNT_REQUEST,
        data
    }
}

export const dashboardVoucherStatusCountSuccess = (response) =>  {
    return {
        type: ACTION.DASHBOARD_VOUCHER_STATUS_COUNT_SUCCESS,
        response
    }
  }

export const dashboardVoucherStatusCountFailed = (error) =>  {
    return {
        type: ACTION.DASHBOARD_VOUCHER_STATUS_COUNT_FAILED,
        error
    }
}