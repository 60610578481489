import React, { useState, useEffect } from 'react';
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loginRequest} from '../../redux/actions/auth';

import validator from 'validator';

import LoginPanel from './LoginPanel';

export default function Login(props) {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const isLoading = useSelector(state => state.auth.isLoading);
  const meta = useSelector(state => state.auth.meta);
  const authenticated = useSelector(state => state.user.authenticated);

  const dispatch = useDispatch();

  const buttonDisabled = () => {
    return validator.isEmpty(username) || validator.isEmpty(password);
  }

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const onClick = (e) => {
    setSubmitted(true);
    const loginData = {
      email: username,
      password: password
    }
    dispatch(loginRequest(loginData));
  }

  useEffect(() => {
    if(submitted && !isLoading) {
      setSubmitted(false);
      if(!meta.success) {
        setOpen(true);
      }
    }
  }, [submitted, isLoading, meta]);

  if(authenticated) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <LoginPanel 
        onUsernameChange={handleUsernameChange}
        onPasswordChange={handlePasswordChange}
        buttonDisabled={buttonDisabled} 
        handleClose={handleClose} 
        open={open}
        isLoading={isLoading}
        errorMsg={meta.errorMsg}
        onClick={onClick} 
      >
      </LoginPanel>
    );
  }
}



