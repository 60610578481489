import {WIDGET_UPDATE_USER_WIDGET_LIST_ACTION_TYPE as ACTION} from './actionTypes';

export const widgetUpdateUserWidgetListRequest = (data) =>  {
    return {
        type: ACTION.WIDGET_UPDATE_USER_WIDGET_LIST_REQUEST,
        data
    }
}

export const widgetUpdateUserWidgetListSuccess = (response) =>  {
    return {
        type: ACTION.WIDGET_UPDATE_USER_WIDGET_LIST_SUCCESS,
        response
    }
  }

export const widgetUpdateUserWidgetListFailed = (error) =>  {
    return {
        type: ACTION.WIDGET_UPDATE_USER_WIDGET_LIST_FAILED,
        error
    }
}