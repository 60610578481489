import {call, put, fork, takeLatest} from 'redux-saga/effects'
import {voucherGetIdSuccess, voucherGetIdFailed} from '../actions/voucherGetId';
import { VOUCHER_GET_BY_ID_ACTION_TYPE as ACTION } from '../actions/actionTypes';

import { doGet } from '../../utils/httpUtility';
import { ENDPOINT } from '../../utils/constants';
import { logoutRequest } from 'redux/actions/auth';

export function* voucherGetId({data}) {
  try {
    const responseData = yield call(doGet, ENDPOINT.VOUCHER + '/' + data.id, null);
    if(responseData.status === 200){
      yield put(voucherGetIdSuccess(responseData));
    } else if(responseData.response.status === 401) {
      yield put(logoutRequest({}));
    } else {
      yield put(voucherGetIdFailed(responseData));
    }
  } catch (error) {
    yield put(voucherGetIdFailed(error));
  }
}
  
function* watchvoucherGetId() {
  yield takeLatest(ACTION.VOUCHER_GET_BY_ID_REQUEST, voucherGetId);
}

export default function* root() {
  yield fork(watchvoucherGetId);
}
  