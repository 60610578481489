export const APP_NAME = "EVMS";
export const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const ENDPOINT = {
 LOGIN: "/auth/login",
 LOGOUT: "/auth/logout",
 USER: "/users",
 CAMPAIGN: "/campaigns",
 AVAILABLE_CAMPAIGN: "/campaigns/available-campaigns",
 VOUCHER_CODE_GENERATOR: "/vouchers/voucher-generators",
 VOUCHER: "/vouchers",
 REPORT_VOUCHER: "/reports/vouchers",
 DASHBOARD: "/dashboard",
 WIDGET: "/widget",
 VOUCHER_BULK: "/voucherbulk",
};
