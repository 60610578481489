import {USER_UPDATE_ACTION_TYPE as ACTION} from './actionTypes';

export const userUpdateRequest = (data) =>  {
    return {
        type: ACTION.USER_UPDATE_REQUEST,
        data
    }
}

export const userUpdateSuccess = (response) =>  {
    return {
        type: ACTION.USER_UPDATE_SUCCESS,
        response
    }
  }

export const userUpdateFailed = (error) =>  {
    return {
        type: ACTION.USER_UPDATE_FAILED,
        error
    }
}