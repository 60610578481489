import { DASHBOARD_ACTIVE_VOUCHER_COUNT_ACTION_TYPE as ACTION } from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    meta: {
        success: false,
        errorCode: '',
        errorMsg: '',
    },
    count: '-'
};

const dashboardActiveVoucherCount = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.DASHBOARD_ACTIVE_VOUCHER_COUNT_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ACTION.DASHBOARD_ACTIVE_VOUCHER_COUNT_SUCCESS:
      return {
        isLoading: false,
        meta: {
          success: true,
          errorCode: action.response.data.errorCode,
          errorMsg: action.response.data.errorMsg
        },
        count: action.response.data.count
      }
    case ACTION.DASHBOARD_ACTIVE_VOUCHER_COUNT_FAILED:
      return {
        isLoading: false,
        meta: {
          success: false,
          errorCode: action.error.response.data.meta.errorCode,
          errorMsg: action.error.response.data.meta.errorMsg
        },
        count: '-'
      }
    default:
      return state;
  }
}

export default dashboardActiveVoucherCount;